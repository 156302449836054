import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { trackEvent } from "@hopper-b2b/api";
import {
  useExperiment,
  useExperiments,
  useGetExperimentVariant,
} from "@hopper-b2b/experiments";
import { I18nMarkup, Trans, useI18nContext } from "@hopper-b2b/i18n";
import { createGeneralSupportConversation } from "@hopper-b2b/self-serve";
import { AIR_ENTRY, ClientName, type WalletOffer } from "@hopper-b2b/types";
import {
  ActionLink,
  ButtonWrap,
  Divider,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";
import type { FeatureFlagsContextProps } from "@hopper-b2b/utilities";
import clsx from "clsx";

import AirplaneIconHome from "../../assets/client/airplane-icon-home.svg";
import { ReactComponent as ArrowBack } from "../../assets/client/arrow-back.svg";
import BellOutlineIcon from "../../assets/client/bell-outline.svg";
import { ReactComponent as BlackFridayWhite } from "../../assets/client/black-friday-white.svg";
import Chevron from "../../assets/client/chevron.svg";
import ChevronWhite from "../../assets/client/darkMode/chevron-white.svg";
import HelpIconWhite from "../../assets/client/darkMode/help-icon-white.svg";
import TripWhiteIcon from "../../assets/client/darkMode/trip-icon-white.svg";
import { ReactComponent as Headset } from "../../assets/client/headset.svg";
import HelpIcon from "../../assets/client/help-icon.svg";
import HotelIconHome from "../../assets/client/hotel-icon-home.svg";
import TripBlackIcon from "../../assets/client/trip-icon.svg";
import InsuranceIcon from "../../assets/client/uv/insurance.svg";
import { ReactComponent as NubankUltravioletaLightLogo } from "../../assets/client/uv/ultravioleta-logo-white.svg";
import { ReactComponent as NubankUltravioletaDarkLogo } from "../../assets/client/uv/ultravioleta-logo.svg";
import CalendarIcon from "../../assets/client/uv/uv-calendar.svg";
import BestPriceGuaranteeIcon from "../../assets/client/uv/uv-dollar.svg";
import WalletIcon from "../../assets/client/uv/uv-wallet.svg";
import {
  isUserTypeCookieUltravioleta,
  useDarkModePreferred,
} from "../../utils/colors";
import { closeNubankWebview, openNubankHelp } from "../../utils/nubankHandoff";
import {
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS_SEARCH,
  PATH_TRIPS,
  PATH_WATCHES,
} from "../../utils/urlPaths";
import { ContactSupportModalContent, HotelVoucherOfferBanner } from "../Slots";
import { TermsAndConditionsContext } from "../TermsAndConditionsWrapper/component";
import style from "./HomeRouteComponent.module.scss";
import "../../nubank.index.scss";
import { getWalletOffers } from "../../api/wallet/fetchWalletOffers";
import { EXPERIMENTS, FEATURE_FLAGS } from "../../App";
import { MaintenanceFlagVariants } from "./index";
import dayjs from "dayjs";
import { FintechBanner } from "../FintechBanner";
import Coin from "../../assets/client/coin.svg";

interface INubankRoute {
  featureFlags: FeatureFlagsContextProps;
}

const HomeRouteComponent = ({ featureFlags }: INubankRoute) => {
  const { t } = useI18nContext();
  const navigate = useNavigate();
  const { setOpenModal } = useContext(TermsAndConditionsContext);
  const [openContactSupportModal, setOpenContactSupportModal] = useState(false);
  const [offer, setOffer] = useState<WalletOffer>(undefined);
  const [offers, setOffers] = useState<WalletOffer[]>([]);
  const [offerLoading, setOfferLoading] = useState<boolean>(true);

  const { experiments } = useExperiments();

  const enableOffers = useExperiment(FEATURE_FLAGS.WALLET_OFFERS);

  useEffect(() => {
    if (enableOffers) {
      getWalletOffers({
        partnerExperiments: experiments.partnerExperiments,
      }).then((res) => {
        const offers =
          res.offers?.filter((offer) => offer.percentage !== undefined) || [];
        if (offers.length === 1) {
          const bestOffer = offers[0];
          setOffer(bestOffer);
          setOffers([bestOffer]);
        } else if (offers.length > 1) {
          const [firstOffer, ...rest] = offers;
          const bestOffer = rest.reduce((max, item) => {
            return item.percentage > max.percentage ? item : max;
          }, firstOffer);
          setOffer(bestOffer);
          setOffers([bestOffer]);
        } else {
          setOffer(undefined);
          setOffers([]);
          setOfferLoading(false);
        }
      });
    }
  }, [enableOffers, experiments.partnerExperiments]);

  // This is needed to make sure the loading UI doesn't go away till after the offer is loaded in state
  useEffect(() => {
    if (offer !== undefined) {
      setOfferLoading(false);
    }
  }, [offer]);

  const appExcludingTripsMaintenance = useExperiment(
    FEATURE_FLAGS.MAINTENANCE,
    MaintenanceFlagVariants["exclude-trips"]
  );
  const flightsMaintenance = useExperiment(
    FEATURE_FLAGS.MAINTENANCE,
    MaintenanceFlagVariants.flights
  );
  const hotelsMaintenance = useExperiment(
    FEATURE_FLAGS.MAINTENANCE,
    MaintenanceFlagVariants.hotels
  );
  const tripsMaintenance = useExperiment(
    FEATURE_FLAGS.MAINTENANCE,
    MaintenanceFlagVariants.trips
  );
  const isFlightsEnabled = !appExcludingTripsMaintenance && !flightsMaintenance;
  const isHotelsEnabled = !appExcludingTripsMaintenance && !hotelsMaintenance;

  const isUltravioleta = isUserTypeCookieUltravioleta();
  const darkModeEnabled = useDarkModePreferred();
  const airPriceWatchEnabled = useExperiment(FEATURE_FLAGS.AIR_PRICE_WATCH);
  const maxInstallments = useGetExperimentVariant(EXPERIMENTS.MAX_INSTALLMENTS);
  const blackFridayEnabled = useExperiment(EXPERIMENTS.NUBANK_BLACK_FRIDAY);
  const installmentsCampaignEnabled = useExperiment(
    EXPERIMENTS.INSTALLMENTS_CAMPAIGN
  );
  const blackFridayEnabledVariant = useGetExperimentVariant(
    EXPERIMENTS.NUBANK_BLACK_FRIDAY
  );
  const travelInsuranceEnabled = useExperiment(
    EXPERIMENTS.NUBANK_TRAVEL_INSURANCE
  );

  const TripIcon = darkModeEnabled ? TripWhiteIcon : TripBlackIcon;
  // TODO: dark mode asset
  const BellIcon = darkModeEnabled ? BellOutlineIcon : BellOutlineIcon;
  const NubankUltravioletaLogo = darkModeEnabled
    ? NubankUltravioletaLightLogo
    : NubankUltravioletaDarkLogo;

  const goBack = useCallback(() => {
    closeNubankWebview();
  }, []);

  const goToFaqs = useCallback(() => {
    openNubankHelp();
  }, []);

  const navigateToLodging = useCallback(() => {
    navigate(PATH_HOTELS_SEARCH);
  }, [navigate]);

  const navigateToFlights = useCallback(() => {
    trackEvent({
      eventName: AIR_ENTRY,
      properties: {},
    });
    navigate(PATH_FLIGHTS_SEARCH);
  }, [navigate]);

  const navigateToTrips = useCallback(() => {
    navigate(PATH_TRIPS);
  }, [navigate]);

  const navigateToWatches = useCallback(() => {
    navigate(PATH_WATCHES);
  }, [navigate]);

  const handleOfferBannerTap = useCallback(() => {
    trackEvent({
      eventName: "viewed_offer",
      properties: {
        screen: "home",
        // properties taken from getWalletTrackingProperties in lodging selectors
        offer_id: offer.id,
        offer_name: offer.offerContent.title,
        offer_applicable: true,
        offer_discount: offer.amount.value,
        // We don't know usd until checkout
        offer_discount_usd: 0,
        offer_expiration_days: dayjs(offer.expiresOn).diff(dayjs(), "days"),
        ...(offer.percentage
          ? {
              offer_discount_percentage_value: offer.percentage * 100,
              offer_discount_type: "percentage",
            }
          : { offer_discount_type: "cash" }),
        ...offer.trackingPropertiesV2,
      },
    });
  }, [offer]);

  const dealsToShow = useMemo(
    () =>
      [
        {
          title: t("homeBestPriceGuaranteeTitle"),
          subtitle: t("homeBestPriceGuaranteeSubtitle"),
          icon: BestPriceGuaranteeIcon,
        },
        featureFlags.enablePriceDropProtection &&
        featureFlags.enablePricePrediction
          ? {
              title: t("homePriceDropProtectionTitle"),
              subtitle: t("homePriceDropProtectionSubtitle"),
              icon: CalendarIcon,
            }
          : null,
        travelInsuranceEnabled
          ? {
              title: t("homeInsuranceTitle"),
              subtitle: t("homeInsuranceSubtitle"),
              icon: InsuranceIcon,
            }
          : null,
        {
          title: t("homePaymentTitle"),
          subtitle: t("homePaymentSubtitle", { installments: maxInstallments }),
          icon: WalletIcon,
        },
      ].filter((deal) => !!deal),
    [
      featureFlags.enablePriceDropProtection,
      featureFlags.enablePricePrediction,
      maxInstallments,
      t,
      travelInsuranceEnabled,
    ]
  );

  const handleOpenContactSupportModal = () => setOpenContactSupportModal(true);

  const handleCloseContactSupportModal = () =>
    setOpenContactSupportModal(false);

  const handleOpenChat = () => {
    handleCloseContactSupportModal();
    createGeneralSupportConversation(ClientName.NUBANK);
  };

  return (
    <div className={style.nubankHome}>
      <div className={clsx("home-top", { "dark-mode": darkModeEnabled })}>
        <div className="home-nav">
          <ButtonWrap onClick={goBack} className="go-back">
            <ArrowBack className="" />
          </ButtonWrap>
          <ButtonWrap onClick={goToFaqs}>
            <img src={darkModeEnabled ? HelpIconWhite : HelpIcon} alt="" />
          </ButtonWrap>
        </div>
        <div className="home-header">
          {isUltravioleta ? (
            <NubankUltravioletaLogo className="uv-logo" />
          ) : null}

          <h2 className="branding" data-cy="branding-title">
            {t("nuTravel")}
          </h2>
          <h1 className="headline">{t("homeScreenTitle")}</h1>
        </div>
        <div className="home-actions">
          {featureFlags.enableAirShopV4 && isFlightsEnabled ? (
            <ButtonWrap
              data-cy="flights-entrypoint"
              className="action-item"
              onClick={navigateToFlights}
            >
              <img src={AirplaneIconHome} alt="" />
              {installmentsCampaignEnabled && maxInstallments ? (
                <span className="home-offer-badge">{maxInstallments}x</span>
              ) : null}
              <p>{t("flightSearchPrompt")}</p>
            </ButtonWrap>
          ) : null}
          {featureFlags.enableLodging && isHotelsEnabled ? (
            <ButtonWrap
              data-cy="hotels-entrypoint"
              className="action-item"
              onClick={navigateToLodging}
            >
              <img src={HotelIconHome} alt="" />
              {offer && !offerLoading ? (
                <span className="home-offer-badge">
                  {t("homeOffers.lodging-badge", {
                    percentage: offer?.percentage * 100,
                  })}
                </span>
              ) : installmentsCampaignEnabled && maxInstallments ? (
                <span className="home-offer-badge">{maxInstallments}x</span>
              ) : null}
              <p>{t("searchHotels")}</p>
            </ButtonWrap>
          ) : null}
        </div>
        {/* Black Friday Banner, Remove after 11/12/2024 */}
        {blackFridayEnabled || blackFridayEnabledVariant === "homepageOnly" ? (
          <div className="black-friday-banner">
            <BlackFridayWhite className="icon" />
            <I18nMarkup
              className="default-text"
              tKey={"blackFriday.description"}
            />
          </div>
        ) : null}
        {installmentsCampaignEnabled ? (
          <FintechBanner
            className="installments-campaign-banner"
            title={t("installmentsCampaign", { installments: maxInstallments })}
            fintechIcon={Coin}
            hideBrand
          />
        ) : null}
        {offer || offerLoading ? (
          <div
            className={clsx("home-offer-container", {
              loading: offerLoading,
            })}
          >
            <HotelVoucherOfferBanner
              offers={offers}
              isEligible={true}
              showMore={true}
              minimal={true}
              title={t("homeOffers.callout", {
                percentage: offer?.percentage * 100,
              })}
              onClick={handleOfferBannerTap}
            />
          </div>
        ) : undefined}
      </div>
      <div className="home-bottom">
        {!tripsMaintenance ? (
          <ButtonWrap onClick={navigateToTrips}>
            <div className="button-entry-with-text">
              <div className="content-left">
                <img src={TripIcon} alt="" />
                <div className="content">
                  <h3>{t("myTrips")}</h3>
                  <p>{t("homeScreenMyTripsSubtitle")}</p>
                </div>
              </div>
              <img src={darkModeEnabled ? ChevronWhite : Chevron} alt="" />
            </div>
          </ButtonWrap>
        ) : null}
        {airPriceWatchEnabled ? (
          <ButtonWrap onClick={navigateToWatches}>
            <div className="button-entry-with-text">
              <div className="content-left">
                <img src={BellIcon} alt="" />
                <div className="content">
                  <h3>{t("priceWatch.title")}</h3>
                  <p>{t("monitorAFlight")}</p>
                </div>
              </div>
              <img src={darkModeEnabled ? ChevronWhite : Chevron} alt="" />
            </div>
          </ButtonWrap>
        ) : null}
        {isUltravioleta ? (
          <>
            <h2 className="find-best-deals">{t("findBestDeals")}</h2>
            {dealsToShow.map((deal, i) => (
              <Fragment key={`deal-to-show-${i}`}>
                <div className="deal-row">
                  <img src={deal?.icon} alt="" className="value-props-icon" />
                  <div>
                    <p className="title">{deal?.title}</p>
                    <p className="subtitle">{deal?.subtitle}</p>
                  </div>
                </div>
                {i < dealsToShow.length - 1 ? <Divider /> : null}
              </Fragment>
            ))}
          </>
        ) : null}
        <Divider />
        <div className="home-contact-support-container">
          <div className="home-tc-link">
            <Trans
              i18nKey={"homeTermsAndConditionsEntry"}
              components={[
                <ButtonWrap
                  className="terms-link"
                  onClick={() => setOpenModal(true)}
                />,
              ]}
            />
          </div>
          <button
            className="home-contact-support"
            onClick={handleOpenContactSupportModal}
          >
            <Headset className="mobile-action-link-icon help" />
            {t("contactSupport")}
          </button>
        </div>
      </div>
      <MobilePopoverCard
        open={openContactSupportModal}
        className="contact-support-modal"
        maxWidth={"sm"}
        scroll="paper"
        fullWidth
        fullScreen
        topRightButton={
          <ActionLink
            className="introduction-modal-close-button"
            data-cy="contact-support-modal-close"
            content={
              <IconComponent
                ariaLabel={t("modalClose.ariaLabel")}
                className={clsx("close-button-icon")}
                name={IconName.Close}
              />
            }
            label={t("modalClose.label")}
            onClick={handleCloseContactSupportModal}
          />
        }
        onClose={handleCloseContactSupportModal}
      >
        <ContactSupportModalContent
          onClose={handleCloseContactSupportModal}
          onContactSupport={handleOpenChat}
        />
      </MobilePopoverCard>
    </div>
  );
};

export default HomeRouteComponent;

import {
  carsApi,
  CarsAvailabilitySelectors,
  type CarSearchQueryParamValues,
  CarsShopActions,
  type CarsState,
  CarsShopSelectors,
  type VehicleAvailabilityWithSupplier,
} from "@apac/components/cars";
import type { ShopResponse } from "@b2bportal/ground-api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCarsShop = createAsyncThunk<
  ShopResponse,
  VehicleAvailabilityWithSupplier | undefined,
  { state: CarsState; rejectValue: { error: string } }
>("carsShop/fetchCarsShop", async (vehicle, thunkAPI) => {
  const state = thunkAPI.getState();
  const opaqueShopRequestContext =
    CarsAvailabilitySelectors.getOpaqueShopRequestContext(state);

  try {
    if (vehicle != null) {
      thunkAPI.dispatch(CarsShopActions.setVehicle(vehicle));
    } else {
      return thunkAPI.rejectWithValue({
        error: "Vehicle not available",
      });
    }

    const { opaqueShopRequest: opaqueShopRequestVehicle } = vehicle;

    if (opaqueShopRequestVehicle == null || opaqueShopRequestContext == null) {
      return thunkAPI.rejectWithValue({
        error: "Opaque shop request not available",
      });
    }

    const res = await carsApi.apiV1GroundShopPost({
      opaqueShopRequestContext,
      opaqueShopRequestVehicle,
    });

    return res.data;
  } catch (error) {
    // Reject the thunk with an error message
    return thunkAPI.rejectWithValue({ error: String(error) });
  }
});

import {
  CORE_AIR_PRICE_WATCH_NAME,
  CORE_DISRUPTION_NAME,
  CORE_FINTECH_UI_NAME,
  CORE_UI_NAME,
  CoreAirPriceWatchComponent,
  CoreDisruptionComponent,
  CoreFintechUiComponents,
  CoreUiComponents,
} from "@b2bportal/core-types";

import { default as airPriceWatchCardStyles } from "./core-air-price-watch/WatchCard/styles.module.scss";
import howItWorksDialogStyles from "./disruption/components/HowItWorksDialog/HowItWorksDialog.module.scss";
import {
  fintechProductStyles,
  disruptionOfferSectionStyles,
  fintechProductOptionsSectionStyles,
  fintechProductInfoLinksSectionStyles,
  fintechProductBulletStyles,
  dialogStyles,
  flightDisruptionAlertBannerStyles,
  disruptionExerciseSelectionStyles,
  disruptionExerciseButtonStyles,
  disruptionExerciseSummaryCardStyles,
  disruptionExerciseOptionsCardStyles,
  disruptionExerciseHeaderStyles,
  disruptionExercisePolicyDetailsStyles,
  disruptionExerciseFooterStyles,
  disruptionExerciseRefundSummaryCardStyles,
  disruptionExerciseInformationCardStyles,
  disruptionExerciseBulletPointStyles,
  disruptionExerciseRefundReviewPageStyles,
  disruptionExerciseRefundConfirmationPageStyles,
  radioItemStyles,
  disruptionExerciseNotEligiblePageStyles,
  disruptionExerciseLearnMoreContentStyles,
  disruptionExpandableContainerStyles,
  disruptionExercisePopupStyles,
  iframeDialogStyles,
  disruptionExercisePageStyles,
  disruptionExerciseLoadingStyles,
  airPriceWatchListPageStyles,
  airPriceWatchListStyles,
} from "./styles";

export const styleOverrides = {
  [CORE_UI_NAME]: {
    [CoreUiComponents.Dialog]: dialogStyles,
    [CoreUiComponents.IframeDialog]: iframeDialogStyles,
    [CoreUiComponents.RadioItem]: radioItemStyles,
  },
  [CORE_FINTECH_UI_NAME]: {
    [CoreFintechUiComponents.FintechProduct]: fintechProductStyles,
    [CoreFintechUiComponents.FintechProductOptionsSection]:
      fintechProductOptionsSectionStyles,
    [CoreFintechUiComponents.FintechProductInfoLinksSection]:
      fintechProductInfoLinksSectionStyles,
    [CoreFintechUiComponents.FintechProductBullet]: fintechProductBulletStyles,
  },
  [CORE_DISRUPTION_NAME]: {
    [CoreDisruptionComponent.DisruptionOfferSection]:
      disruptionOfferSectionStyles,
    [CoreDisruptionComponent.HowItWorksDialog]: howItWorksDialogStyles,
    [CoreDisruptionComponent.FlightDisruptionAlertBanner]:
      flightDisruptionAlertBannerStyles,
    [CoreDisruptionComponent.DisruptionExerciseSelection]:
      disruptionExerciseSelectionStyles,
    [CoreDisruptionComponent.DisruptionExerciseButton]:
      disruptionExerciseButtonStyles,
    [CoreDisruptionComponent.DisruptionExerciseSummaryCard]:
      disruptionExerciseSummaryCardStyles,
    [CoreDisruptionComponent.DisruptionExerciseOptionsCard]:
      disruptionExerciseOptionsCardStyles,
    [CoreDisruptionComponent.DisruptionExerciseHeader]:
      disruptionExerciseHeaderStyles,
    [CoreDisruptionComponent.DisruptionExercisePolicyDetails]:
      disruptionExercisePolicyDetailsStyles,
    [CoreDisruptionComponent.DisruptionExerciseFooter]:
      disruptionExerciseFooterStyles,
    [CoreDisruptionComponent.DisruptionExerciseRefundSummaryCard]:
      disruptionExerciseRefundSummaryCardStyles,
    [CoreDisruptionComponent.DisruptionExerciseInformationCard]:
      disruptionExerciseInformationCardStyles,
    [CoreDisruptionComponent.DisruptionExerciseBulletPoint]:
      disruptionExerciseBulletPointStyles,
    [CoreDisruptionComponent.DisruptionExerciseRefundReviewPage]:
      disruptionExerciseRefundReviewPageStyles,
    [CoreDisruptionComponent.DisruptionExerciseRefundConfirmationPage]:
      disruptionExerciseRefundConfirmationPageStyles,
    [CoreDisruptionComponent.DisruptionExerciseNotEligiblePage]:
      disruptionExerciseNotEligiblePageStyles,
    [CoreDisruptionComponent.DisruptionExerciseLearnMoreContent]:
      disruptionExerciseLearnMoreContentStyles,
    [CoreDisruptionComponent.DisruptionExpandableContainer]:
      disruptionExpandableContainerStyles,
    [CoreDisruptionComponent.DisruptionExercisePopup]:
      disruptionExercisePopupStyles,
    [CoreDisruptionComponent.DisruptionExercisePage]:
      disruptionExercisePageStyles,
    [CoreDisruptionComponent.DisruptionExerciseLoading]:
      disruptionExerciseLoadingStyles,
  },
  [CORE_AIR_PRICE_WATCH_NAME]: {
    [CoreAirPriceWatchComponent.WatchListPage]: airPriceWatchListPageStyles,
    [CoreAirPriceWatchComponent.WatchList]: airPriceWatchListStyles,
  },
};

import { useCallback } from "react";
import { ButtonWrap, ChoiceButton } from "@hopper-b2b/ui";
import styles from "./styles.module.scss";

interface CheckboxRowItemProps {
  onCheckClick: (value: boolean) => void;
  selected: boolean;
  title: string;
  subtitle?: string;
}

const CheckboxRowItem = ({
  onCheckClick,
  selected,
  title,
  subtitle,
}: CheckboxRowItemProps) => {
  const onClickHandler = useCallback(() => {
    onCheckClick(!selected);
  }, [onCheckClick, selected]);

  return (
    <ButtonWrap onClick={onClickHandler} className={styles.CheckboxRowItem}>
      <ChoiceButton
        onClick={onClickHandler}
        isChosen={selected}
        disabled={false}
        message={""}
        className={styles.Checkbox}
      />
      <div className="content">
        <p className="title">{title}</p>
        {subtitle ? <p className="subtitle">{subtitle}</p> : null}
      </div>
    </ButtonWrap>
  );
};

export default CheckboxRowItem;

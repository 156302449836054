import { priceFreezeApi } from "@b2bportal/price-freeze-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPriceFreezeVouchers = createAsyncThunk(
  "flightsPriceFreezePurchase/fetchPriceFreezeVouchers",
  async (_, thunkAPI) => {
    try {
      const res = await priceFreezeApi(axios).apiV3PricefreezeVoucherListPost();

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

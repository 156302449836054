import { CallState } from "@b2bportal/core-types";
import { CarSortOptionEnum, DropoffType } from "../../../types";
import type {
  CarsAvailabilitySearchState,
  CarsAvailabilityState,
} from "./types";

export const initialFiltersState = {};

export const initialCarsAvailabilitySearchState: CarsAvailabilitySearchState = {
  searchFormValues: {
    pickupDateTime: undefined,
    pickupLocation: undefined,
    dropoffDateTime: undefined,
    dropoffLocation: undefined,
    dropoffType: DropoffType.RoundTrip,
    driverAge: undefined,
  },
  sort: CarSortOptionEnum.Recommended,
  filters: initialFiltersState,
  map: {
    zoom: 13,
  },
};

export const initialCarAvailabilityState: CarsAvailabilityState = {
  ...initialCarsAvailabilitySearchState,
  searchResult: {
    state: CallState.NotCalled,
  },
  initialized: CallState.NotCalled,
};

import { asB2BMuiTheme } from "@hopper-b2b/themes";
import type { ConfigTheme, MUIThemeDefinition } from "../../types";
import { defaultThemeConfig } from "./defaultThemeConfig";

export type MUIThemeVariables = {
  primary: string;
  "font-family": string;
  "text-primary": string;
  "text-secondary": string;
};

const defaultMUIThemeVariables: MUIThemeVariables = {
  primary: defaultThemeConfig.primary ?? "",
  "font-family": defaultThemeConfig["font-family"] ?? "",
  "text-primary": defaultThemeConfig["primary-text"],
  "text-secondary": defaultThemeConfig["secondary-text"],
};

/** This function generates the MUI theme from the defined css variables. As we don't have variables to define
 * The font-size and font-weight of the headings, those values are hardcoded into default ones
 * If you need to customize your MUI variables more, please use this function as helper instead and define your own
 * MUI theme **/

export const generateMuiThemeFromVariables = (
  themeVariables: MUIThemeVariables
): MUIThemeDefinition => {
  const white = "#FFF";
  return {
    shape: {
      borderRadius: 20,
    },
    palette: {
      type: "light",
      primary: {
        light: themeVariables.primary,
        main: themeVariables.primary,
        contrastText: white,
      },
      secondary: {
        light: themeVariables["text-secondary"],
        main: themeVariables["text-secondary"],
        contrastText: white,
      },
      text: {
        primary: themeVariables["text-primary"],
        secondary: themeVariables["text-secondary"],
        disabled: white,
        hint: white,
      },
      action: {
        disabled: themeVariables["text-secondary"],
      },
    },
    typography: {
      fontFamily: themeVariables["font-family"],
      fontSize: 16,
      h1: {
        fontSize: 28,
        fontWeight: 700,
        color: themeVariables["text-primary"],
      },
      h2: {
        fontSize: 20,
        fontWeight: 700,
        color: themeVariables["text-primary"],
      },
      h3: {
        fontSize: 18,
        fontWeight: 700,
        color: themeVariables["text-primary"],
      },
      h4: {
        fontSize: 22,
        fontWeight: 700,
        color: themeVariables["text-primary"],
      },
      h5: {
        fontSize: 20,
        fontWeight: 500,
        color: themeVariables["text-primary"],
      },
      h6: {
        fontSize: 16,
        fontWeight: 700,
        color: themeVariables["text-primary"],
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 700,
        color: themeVariables["text-secondary"],
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 700,
        color: themeVariables["text-secondary"],
      },
      body1: {
        fontSize: 14,
        color: themeVariables["text-secondary"],
      },
      body2: {
        fontSize: 16,
        color: themeVariables["text-secondary"],
      },
      caption: {
        fontSize: 14,
        color: themeVariables["text-secondary"],
      },
      button: {
        lineHeight: "1.25rem",
        textTransform: "none" as const,
      },
    },
  };
};

export const buildThemeFromBasicConfig = (theme: ConfigTheme): ConfigTheme => {
  const variables = {
    ...defaultThemeConfig,
    ...theme.variables,
  };

  const muiVariables = {
    ...defaultMUIThemeVariables,
    ...variables,
  };

  return {
    muiTheme:
      theme.muiTheme ||
      asB2BMuiTheme(generateMuiThemeFromVariables(muiVariables)),
    variables,
    icons: theme.icons,
    images: theme.images,
    styleOverrides: theme.styleOverrides,
  };
};

import type {
  BookedFlightItineraryWithDepartureTime,
  FlightItineraryState,
} from "@b2bportal/air-booking-api";
import {
  type CacheableExternalResource,
  CallState,
  type ExternalResource,
  mapExternalResource,
} from "@b2bportal/core-types";
import {
  handleAsyncThunk,
  handleAsyncThunkCacheable,
  type ThunkErrorType,
} from "@b2bportal/core-utilities";
import { createSlice } from "@reduxjs/toolkit";

import type { AirlineMap, AirportMap } from "../../../types";
import { fetchItineraries, fetchItinerary } from "./thunks";

export interface FlightPostBookingState {
  context: {
    airports: AirportMap;
    airlines: AirlineMap;
  };
  itineraries: CacheableExternalResource<
    {
      [key in FlightItineraryState as Lowercase<
        key & string
      >]?: BookedFlightItineraryWithDepartureTime[];
    },
    ThunkErrorType<{ error: string }>
  >;
  selectedItinerary: ExternalResource<
    BookedFlightItineraryWithDepartureTime,
    ThunkErrorType<{ error: string }>
  >;
}

export const initialPostBookingState: FlightPostBookingState = {
  context: { airports: {}, airlines: {} },
  itineraries: { state: CallState.NotCalled },
  selectedItinerary: { state: CallState.NotCalled },
};

export const flightPostBookingSlice = createSlice({
  name: "flightPostBooking",
  initialState: initialPostBookingState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncThunkCacheable(builder, fetchItineraries, {
      get: (state) => state.itineraries.data,
      extract: (response) => response.itineraries,
      set: (state, resource) => {
        state.itineraries = resource;
      },
      update: (state, response) => {
        state.context.airlines = {
          ...state.context.airlines,
          ...response.context.airlines,
        };
        state.context.airports = {
          ...state.context.airports,
          ...response.context.airports,
        };
      },
    });

    handleAsyncThunk(builder, fetchItinerary, (state, resource) => {
      state.selectedItinerary = mapExternalResource(
        resource,
        (r) => r.itinerary
      );
      if (resource.data != null) {
        state.context.airlines = {
          ...state.context.airlines,
          ...resource.data.context.airlines,
        };
        state.context.airports = {
          ...state.context.airports,
          ...resource.data.context.airports,
        };
      }
    });
  },
});

export const FlightPostBookingActions = flightPostBookingSlice.actions;
export default flightPostBookingSlice.reducer;

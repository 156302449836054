import { combineReducers } from "@reduxjs/toolkit";
import tripListReducer, { TripsListState } from "../features/list/store/slice";

export interface TripsState {
  trips: {
    tripList: TripsListState;
  };
}

export const tripsRootReducer = combineReducers({
  tripList: tripListReducer,
});

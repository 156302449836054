import { useEffect } from "react";
import { useNavigate, type NavigateFunction } from "react-router-dom-v5-compat";

export type RelativeRoutingType = "route" | "path";

export interface NavigateOptions {
  replace?: boolean;
  state?: any;
  preventScrollReset?: boolean;
  relative?: RelativeRoutingType;
}

let navigate: NavigateFunction;

export const useInitializeNavigate = () => {
  const navigation = useNavigate();
  useEffect(() => {
    navigate = navigation;
  }, [navigation]);
};

export const navigateTo = (path: string, options: NavigateOptions) => {
  if (navigate) {
    navigate(path, options);
  } else {
    console.error("Navigate function is not initialized");
  }
};

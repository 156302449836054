import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  type AvailabilityApiCall,
  fetchAvailabilityByBoundingBox,
  fetchAvailabilityByPlaceAPI,
} from "./api/fetchAvailability";
import type { AvailabilityResponse } from "@b2bportal/ground-api";
import { type CarsState, DropoffType } from "@apac/cars";
import { getSearchFormDropoffType } from "../selectors";

export const fetchCars = createAsyncThunk<
  AvailabilityResponse,
  AvailabilityApiCall,
  { state: CarsState; rejectValue: { error: string } }
>("carsAvailability/fetchCars", async (req, thunkAPI) => {
  try {
    if (req.GroundSelection === "Place") {
      return fetchAvailabilityByPlaceAPI(req.request);
    } else if (req.GroundSelection === "Location") {
      const dropoffType =
        getSearchFormDropoffType(thunkAPI.getState()) ?? DropoffType.RoundTrip;
      return fetchAvailabilityByBoundingBox({
        ...req.request,
        dropoffType,
      });
    } else {
      return thunkAPI.rejectWithValue({ error: "Request type mismatch" });
    }
  } catch (error) {
    console.error("Failed to fetch cars:", error);
    return thunkAPI.rejectWithValue({ error: "Failed to fetch cars" });
  }
});

import {
  IconNameEnum,
  useModuleBEM,
  useUiStyles,
} from "@b2bportal/core-themes";
import {
  CoreUiComponents,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { Icon } from "@b2bportal/core-ui";
import clsx from "clsx";
import defaultStyles from "./InfoBanner.module.scss";

export enum InfoBannerSeverity {
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
}

export type InfoBannerProps = {
  severity: InfoBannerSeverity;
  icon?: IconNameEnum;
  showIcon?: boolean;
} & PlatformComponentProps;

export const InfoBanner = ({
  severity,
  icon,
  className,
  children,
  showIcon = true,
}: InfoBannerProps) => {
  const COMPONENT_KEY = CoreUiComponents.InfoBanner;
  const styles = useUiStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY, [severity]);

  return (
    <div className={clsx(block, className)}>
      {showIcon ? (
        <Icon
          iconName={icon ? icon : IconNameEnum.error}
          className={cn("icon", [severity])}
        />
      ) : null}
      <div className={cn("content")}>{children}</div>
    </div>
  );
};

export default InfoBanner;

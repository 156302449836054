export const CORE_AIR_PRICE_WATCH_NAME = "core-air-price-watch";

export enum CoreAirPriceWatchComponent {
  WatchList = "WatchList",
  WatchListLoadingScreen = "WatchListLoadingScreen",
  WatchListPage = "WatchListPage",
  WatchCard = "WatchCard",
  WatchActions = "WatchActions",
  CancelWatchModalContent = "CancelWatchModalContent",
  ChangeWatchEmailModalContent = "ChangeWatchEmailModalContent",
  ScheduleChangeTimePreference = "ScheduleChangeTimePreference",
}

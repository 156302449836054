import type {
  CreateOrUpdateTravelerResponse,
  Traveler,
} from "@b2bportal/traveler-api";
import {
  deleteTraveler,
  fetchTravelers,
  updateTraveler,
} from "@hopper-b2b/api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const travelerApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
  }),
  tagTypes: ["Traveler"],
  reducerPath: "traveler",
  endpoints: (build) => ({
    listTravelers: build.query<Array<Traveler>, void>({
      queryFn: async () => {
        const { travelers } = await fetchTravelers();

        return { data: travelers };
      },
      providesTags: ["Traveler"],
    }),
    deleteTraveler: build.mutation<object, { travelerId: string }>({
      queryFn: async ({ travelerId }) => {
        const data = await deleteTraveler({
          travelerId,
        });

        return { data };
      },
    }),
    updateTraveler: build.mutation<
      CreateOrUpdateTravelerResponse,
      { traveler: Traveler }
    >({
      queryFn: async ({ traveler }) => {
        const data = await updateTraveler({
          traveler,
        });

        return { data };
      },
    }),
  }),
});

export const {
  useListTravelersQuery,
  useDeleteTravelerMutation,
  useUpdateTravelerMutation,
} = travelerApi;

export default travelerApi;

import type { ScheduleChangeSeverity } from "@b2bportal/air-booking-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreFlightsComponent } from "@b2bportal/core-types";
import { useFlightScheduleChangeBadge } from "@components/flights";
import clsx from "clsx";

import defaultStyles from "./FlightScheduleChangeBadge.module.scss";

export interface FlightScheduleChangeBadgeProps {
  severity: ScheduleChangeSeverity;
  className?: string;
}

export const FlightScheduleChangeBadge = ({
  severity,
  className,
}: FlightScheduleChangeBadgeProps) => {
  const COMPONENT_KEY = CoreFlightsComponent.FlightScheduleChangeBadge;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { context, text } = useFlightScheduleChangeBadge(severity);

  return (
    <div
      className={clsx(block, className, cn("badge", [context.badgeVariant]))}
    >
      {text.badgeLabel}
    </div>
  );
};

import { merge } from "lodash-es";
import type { BasicConfig, PortalConfig } from "../../types";
import defaultConfig from "./defaultConfig";
import { buildThemeFromBasicConfig } from "./theme";

export const fromBasicConfig = (tenantConfig: BasicConfig): PortalConfig => {
  const config = {
    ...defaultConfig,
    clientName: tenantConfig.clientName,
    bootConfig: merge(defaultConfig.bootConfig, tenantConfig.bootConfig),
    theme: {
      ...buildThemeFromBasicConfig(tenantConfig.theme),
    },
    featureFlags: {
      ...defaultConfig.featureFlags,
      ...tenantConfig.featureFlags,
    },
    branding: {
      ...defaultConfig.branding,
      ...tenantConfig.branding,
    },
    translations: tenantConfig.translations,
  };
  return config;
};

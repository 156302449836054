import { useModuleBEM } from "@b2bportal/core-themes";
import styles from "./styles.module.scss";
import { ReactNode } from "react";
import { Collapse } from "@material-ui/core";
import { Icon } from "@components/ui";
import { IconNameEnum } from "@b2bportal/core-themes";

interface FormErrorTextProps {
  error: ReactNode;
}

export const FormErrorText = ({ error }: FormErrorTextProps): JSX.Element => {
  const [block, cn] = useModuleBEM(styles, "FormErrorText");

  return (
    <Collapse className={block} in={!!error}>
      <div className={cn("content")}>
        <Icon
          className={cn("icon")}
          iconName={IconNameEnum.warning}
          useCurrentColor={false}
        />
        <span className={cn("text")}>{error}</span>
      </div>
    </Collapse>
  );
};

import type { MapCoordinates } from "@b2bportal/core-types";
import dayjs from "dayjs";
import { CarSortOptionEnum, type FilterState } from "../../types";
import { CAR_URL_PARAM_KEYS } from "../queryParams";

export const URL_DATE_FORMAT = "YYYY-MM-DDTHH:mm" as const;
export const DEFAULT_DRIVER_AGE = 25;

type BuildSearchParamsArgs = {
  pickupDateTime: Date;
  dropoffDateTime: Date;
  driverAge: number;
};

function defined<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function formatDateForUrl(date: Date) {
  return dayjs(date).format(URL_DATE_FORMAT);
}

export const buildSearchParams = ({
  pickupDateTime,
  dropoffDateTime,
  driverAge,
}: BuildSearchParamsArgs) => {
  const searchParams = new URLSearchParams(
    [
      [CAR_URL_PARAM_KEYS.PICKUP_DATETIME, formatDateForUrl(pickupDateTime)],
      [CAR_URL_PARAM_KEYS.DROPOFF_DATETIME, formatDateForUrl(dropoffDateTime)],
      [CAR_URL_PARAM_KEYS.DRIVER_AGE, driverAge.toString()],
    ].filter(defined)
  );
  return searchParams;
};

export enum ViewOption {
  LIST = "list",
  MAP = "map",
}

export function isValidSort(sort: unknown): sort is CarSortOptionEnum {
  return Object.values(CarSortOptionEnum).includes(sort as CarSortOptionEnum);
}

export function isValidView(sort: unknown): sort is ViewOption {
  return Object.values(ViewOption).includes(sort as ViewOption);
}

export type CarSearchQueryParamValues = {
  // availability
  pickupDateTime: Date;
  dropoffDateTime: Date;
  driverAge: number;
  map:
    | {
        centroid: MapCoordinates | undefined;
        zoom: number;
      }
    | undefined;
  filters: FilterState;
  sortBy: CarSortOptionEnum;
  view: ViewOption;

  // shop
  skipStore?: boolean;
  storeKey?: string;
  vehicleId?: string;
};

export const parseGroundParams = (
  searchParams: URLSearchParams
): CarSearchQueryParamValues => {
  const latlng =
    searchParams
      .get(CAR_URL_PARAM_KEYS.LAT_LNG)
      ?.split(",")
      .map(Number)
      .filter((_) => !Number.isNaN(_)) || [];
  const zoom = Number(searchParams.get(CAR_URL_PARAM_KEYS.ZOOM)) || 13;

  const filters: FilterState = (() => {
    const encodedFilters = searchParams.get(CAR_URL_PARAM_KEYS.FILTERS);
    if (encodedFilters == null) {
      return {};
    }
    try {
      return JSON.parse(encodedFilters);
    } catch (e) {
      return {};
    }
  })();

  const sortBy = searchParams.get(CAR_URL_PARAM_KEYS.SORT_BY);

  const view = searchParams.get(CAR_URL_PARAM_KEYS.VIEW);

  const skipStore = searchParams.get(CAR_URL_PARAM_KEYS.SKIP_STORE) === "true";
  const storeKey = searchParams.get(CAR_URL_PARAM_KEYS.STORE_KEY) ?? undefined;
  const vehicleId =
    searchParams.get(CAR_URL_PARAM_KEYS.VEHICLE_ID) ?? undefined;

  return {
    pickupDateTime: new Date(
      searchParams.get(CAR_URL_PARAM_KEYS.PICKUP_DATETIME) ?? ""
    ),
    dropoffDateTime: new Date(
      searchParams.get(CAR_URL_PARAM_KEYS.DROPOFF_DATETIME) ?? ""
    ),
    driverAge:
      Number(searchParams.get(CAR_URL_PARAM_KEYS.DRIVER_AGE)) ??
      DEFAULT_DRIVER_AGE,
    map: {
      centroid:
        latlng.length > 1
          ? {
              lat: latlng[0] || 0,
              lng: latlng[1] || 0,
            }
          : undefined,
      zoom: zoom,
    },
    filters,
    sortBy: isValidSort(sortBy) ? sortBy : CarSortOptionEnum.Recommended,
    view: isValidView(view) ? (view as ViewOption) : ViewOption.LIST,
    skipStore,
    storeKey,
    vehicleId,
  };
};

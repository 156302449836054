import { disruptionRootReducer } from "@b2bportal/core-disruption";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { carMiddlewares, carReducers } from "@apac/cars";
import { flightsPriceFreezeRootReducer } from "@apac/components/flights-price-freeze";
import { flightsRootReducer } from "@b2bportal/core-flights";
import { cfarMiddlewares, cfarRootReducer } from "@b2bportal/core-flights-cfar";
import { hotelMiddlewares, hotelReducers } from "@b2bportal/core-hotels";
import { tripsRootReducer } from "@b2bportal/core-trips";
import { authReducer } from "@hopper-b2b/self-serve";
import {
  tenantFlightsMiddleware,
  tenantFlightsReducers,
} from "./tenantFlights";
import { travelerReducers, travelerMiddleware } from "./travelerStore";

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  sagaMiddleware,
  ...cfarMiddlewares,
  ...tenantFlightsMiddleware,
  ...hotelMiddlewares,
  ...carMiddlewares,
  ...travelerMiddleware,
];

const coreStore = configureStore({
  reducer: {
    disruption: disruptionRootReducer,
    auth: authReducer,
    cfar: cfarRootReducer,
    flights: flightsRootReducer,
    trips: tripsRootReducer,
    ...hotelReducers,
    ...tenantFlightsReducers,
    ...travelerReducers,
    ...carReducers,
    flightsPriceFreeze: flightsPriceFreezeRootReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
});

// const rootSaga = {}
// // Run the root saga after creating the store
// sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof coreStore.dispatch;
export type RootState = ReturnType<typeof coreStore.getState>;
export default coreStore;

import { Middleware } from "redux";
import { type HotelsState } from "./slice";
import { HotelsAvailabilityActionTypes, HotelsAvailabilityActions } from "..";
import {
  HOTELS_AVAILABILITY_PATH,
  navigateTo,
  HotelNavigationUtility,
  HOTEL_URL_PARAM_KEYS,
  toStarRating,
} from "@overrides/utilities";
import {
  Amenity,
  HotelStarRating,
  LodgingSelectionEnum,
} from "@b2bportal/lodging-api";
import {
  FilterState,
  HotelSortOptionEnum,
  PriceRange,
  SearchDetails,
  MapState,
} from "@b2bportal/core-types";

// Type for URL Param Handler
type UrlParamHandler<T> = (payload: T) => {
  updatedUrl: URL;
  navigateWithReplace: boolean;
};

type ActionTypes = HotelsAvailabilityActionTypes;

type HandlerMap = {
  [K in ActionTypes["type"]]?: UrlParamHandler<
    (ActionTypes & { type: K })["payload"]
  >;
};

const updateSearchParam = (
  updatedUrl: URL,
  key: string,
  value: string | null
) => {
  if (value !== null) {
    updatedUrl.searchParams.set(key, value);
  } else {
    updatedUrl.searchParams.delete(key);
  }
};

// Factory function to create the middleware with custom handlers
export const createHotelUrlMiddleware =
  (customHandlers: HandlerMap = {}): Middleware =>
  (store) =>
  (next) =>
  (action: ActionTypes) => {
    const {
      hotels: { hotelsAvailability },
    }: HotelsState = store.getState();
    const currentUrl = new URL(window.location.toString());

    // Default handlers for each action
    const defaultHandlers: HandlerMap = {
      [HotelsAvailabilityActions.setRequestedSearch.type]: (
        payload: SearchDetails
      ) => {
        const updatedUrl = new URL(currentUrl);

        const { place, dateRange, guests } = payload;
        updatedUrl.pathname = `${HOTELS_AVAILABILITY_PATH}/${HotelNavigationUtility.placeLabelAndSublabelToUrl(
          place.label,
          place.subLabel
        )}`;

        if (payload.searchType !== LodgingSelectionEnum.Location) {
          updatedUrl.searchParams.delete(HOTEL_URL_PARAM_KEYS.LAT_LNG);
        }

        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.FROM_DATE,
          HotelNavigationUtility.formatDateForUrl(dateRange.from)
        );
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.UNTIL_DATE,
          HotelNavigationUtility.formatDateForUrl(dateRange.until)
        );

        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.ADULTS_COUNT,
          guests.adults.toString()
        );

        if (guests.children.length > 0) {
          updateSearchParam(
            updatedUrl,
            HOTEL_URL_PARAM_KEYS.CHILDREN_COUNT,
            guests.children.length.toString()
          );
          updatedUrl.searchParams.delete(HOTEL_URL_PARAM_KEYS.CHILDREN_AGES);
          guests.children.forEach((age) => {
            updatedUrl.searchParams.append(
              HOTEL_URL_PARAM_KEYS.CHILDREN_AGES,
              age.toString()
            );
          });
        } else {
          updateSearchParam(
            updatedUrl,
            HOTEL_URL_PARAM_KEYS.CHILDREN_COUNT,
            null
          );
          updatedUrl.searchParams.delete(HOTEL_URL_PARAM_KEYS.CHILDREN_AGES);
        }

        return {
          updatedUrl,
          navigateWithReplace: false,
        };
      },
      [HotelsAvailabilityActions.setPriceFilter.type]: (
        payload: PriceRange
      ) => {
        const updatedUrl = new URL(currentUrl);

        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.PRICE_RANGE_MIN,
          payload.min.toString()
        );
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.PRICE_RANGE_MAX,
          payload.max.toString()
        );

        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
      [HotelsAvailabilityActions.setAmenitiesFilter.type]: (
        payload: Amenity[]
      ) => {
        const updatedUrl = new URL(currentUrl);
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.AMENITIES,
          payload.length ? payload.join(",") : null
        );
        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
      [HotelsAvailabilityActions.setStarRatingFilter.type]: (
        payload: HotelStarRating[]
      ) => {
        const updatedUrl = new URL(currentUrl);
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.STAR_RATING,
          payload.length ? payload.map(toStarRating).join(",") : null
        );
        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
      [HotelsAvailabilityActions.setSort.type]: (
        payload: HotelSortOptionEnum
      ) => {
        const updatedUrl = new URL(currentUrl);
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.SORT_BY,
          HotelNavigationUtility.isValidSort(payload) ? payload : null
        );
        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
      [HotelsAvailabilityActions.setCancellationFilter.type]: (
        payload: boolean
      ) => {
        const updatedUrl = new URL(currentUrl);
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.FREE_CANCELLATION,
          payload ? "true" : null
        );
        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
      [HotelsAvailabilityActions.resetFilters.type]: (_: undefined) => {
        const updatedUrl = new URL(currentUrl);
        [
          HOTEL_URL_PARAM_KEYS.STAR_RATING,
          HOTEL_URL_PARAM_KEYS.AMENITIES,
          HOTEL_URL_PARAM_KEYS.PRICE_RANGE_MIN,
          HOTEL_URL_PARAM_KEYS.PRICE_RANGE_MAX,
          HOTEL_URL_PARAM_KEYS.FREE_CANCELLATION,
        ].forEach((param) => updatedUrl.searchParams.delete(param));
        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
      [HotelsAvailabilityActions.setAllFilters.type]: (
        payload: FilterState
      ) => {
        const updatedUrl = new URL(currentUrl);
        const { starRating, amenities, priceRange, freeCancellation } = payload;
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.STAR_RATING,
          starRating.length ? starRating.map(toStarRating).join(",") : null
        );
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.AMENITIES,
          amenities.length ? amenities.join(",") : null
        );
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.PRICE_RANGE_MIN,
          priceRange.min.toString()
        );
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.PRICE_RANGE_MAX,
          priceRange.max.toString()
        );
        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.FREE_CANCELLATION,
          freeCancellation ? "true" : null
        );
        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
      [HotelsAvailabilityActions.setMapData.type]: (payload: MapState) => {
        const updatedUrl = new URL(currentUrl);
        const { centroid, zoom } = payload;

        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.LAT_LNG,
          centroid ? `${centroid.lat},${centroid.lng}` : null
        );

        updateSearchParam(
          updatedUrl,
          HOTEL_URL_PARAM_KEYS.ZOOM,
          zoom ? zoom.toString() : null
        );

        return {
          updatedUrl,
          navigateWithReplace: true,
        };
      },
    };

    const mergedHandlers = {
      ...defaultHandlers,
      ...customHandlers,
    };

    const handler = mergedHandlers[action.type];

    if (handler != null) {
      const { updatedUrl, navigateWithReplace } = handler(action.payload);
      if (updatedUrl.toString() !== currentUrl.toString()) {
        navigateTo(`${updatedUrl.pathname}${updatedUrl.search}`, {
          replace: navigateWithReplace,
        });
      }
    }
    return next(action);
  };

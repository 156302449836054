import { type FC, useContext, useMemo } from "react";
import { Dealness, type Prediction } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ActionLink,
  ButtonWrap,
  Icon,
  IconComponent,
  IconName,
} from "@hopper-b2b/ui";
import { tenantFlagsEnabled } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import { ClientContext } from "../../../../../App";

const PRICE_DROP_PROTECTION_AMOUNT = 50;

export const PricePredictionBar: FC<{
  prediction: Prediction;
  onOpenPrediction?: () => void;
  onOpenPriceDrop?: () => void;
}> = ({ prediction, onOpenPrediction, onOpenPriceDrop }) => {
  const clientContext = useContext(ClientContext);
  const { t, formatFiatCurrency, exists } = useI18nContext();
  const { dealness } = prediction;

  const dealSuggestion = useMemo((): string => {
    switch (dealness) {
      case Dealness.great:
        return t("priceForecast.greatDescription");
      case Dealness.good:
        return t("priceForecast.goodDescription");
      case Dealness.fair:
        return t("priceForecast.fairDescription");
      case Dealness.wait:
        return t("priceForecast.waitDescription");
      default:
        return "";
    }
  }, [dealness, t]);

  const pricePredictionCopy = useMemo(
    () => ({
      title:
        dealness === Dealness.fair && exists("pricePredictionCard.fairTitle")
          ? t("pricePredictionCard.fairTitle")
          : prediction?.predictionCopy?.recommendationTitle[0] ?? "",
      detail:
        dealness === Dealness.fair && exists("pricePredictionCard.fairDetail")
          ? t("pricePredictionCard.fairDetail")
          : prediction?.predictionCopy?.recommendationBody[0] ?? "",
    }),
    [
      dealness,
      exists,
      prediction?.predictionCopy?.recommendationBody,
      prediction?.predictionCopy?.recommendationTitle,
      t,
    ]
  );

  const priceDropProtectionBody = (
    <>
      {`${t("priceForecast.recommendedBody", {
        amount: formatFiatCurrency(
          {
            value: PRICE_DROP_PROTECTION_AMOUNT,
            currencyCode: prediction.lowestPrice.fiat.currencyCode,
          },
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }
        ),
      })}`}
      <ActionLink
        onClick={onOpenPriceDrop}
        content={
          <>
            <b>{t("priceForecast.recommendedInfoLabel")}</b>
            <IconComponent name={IconName.InfoCircle} className="info-icon" />
          </>
        }
      />
    </>
  );

  return (
    <Box className="mobile-price-prediction-bar price-prediction-card">
      <Typography variant="subtitle2" className="more-info">
        {dealSuggestion}
      </Typography>
      <Typography variant="h6" className="mobile-price-prediction-title">
        <span
          dangerouslySetInnerHTML={{
            __html: pricePredictionCopy.title,
          }}
        ></span>
        <ButtonWrap className="info-icon-container" onClick={onOpenPrediction}>
          {clientContext?.assets?.info ? (
            <img src={clientContext.assets.info} alt="Info" />
          ) : (
            <IconComponent name={IconName.InfoCircle} className="info-icon" />
          )}
        </ButtonWrap>
      </Typography>
      <Typography variant="body2" className="mobile-price-prediction-detail">
        <span
          dangerouslySetInnerHTML={{
            __html: pricePredictionCopy.detail,
          }}
        ></span>
        {tenantFlagsEnabled.FlightPriceDropProtection
          ? ` ${priceDropProtectionBody}`
          : ""}
        <span className="mobile-price-prediction-lowest-price">
          {t("pricePrediction.currentLowestPrice", {
            price: formatFiatCurrency(prediction?.lowestPrice.fiat, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          })}{" "}
          <ActionLink
            className="mobile-price-prediction-learn-more inline"
            content={
              <>
                {t("learnMore")}
                <img
                  className="learn-more-arrow"
                  src={clientContext.assets?.navArrow}
                  alt=""
                />
              </>
            }
            onClick={onOpenPrediction}
          />
        </span>
      </Typography>
      {tenantFlagsEnabled.FlightPriceDropProtection ? (
        <Typography
          variant="caption"
          className="mobile-price-prediction-protection"
        >
          <Icon
            name={IconName.PriceDropProtectionShield}
            className="info-icon"
          />
          <span>{t("priceForecast.freePriceDrop")}</span>
          <div onClick={onOpenPriceDrop}>
            <Icon name={IconName.InfoCircle} className="info-icon" />
          </div>
        </Typography>
      ) : null}
      <div
        className={`mobile-price-prediction-bar-line ${dealness.toString()}`}
      >
        <div
          className={`mobile-price-prediction-bar-marker ${dealness.toString()}`}
        />
      </div>
      {tenantFlagsEnabled.FlightShopPredictionLearnMore ? (
        <ActionLink
          className="mobile-price-prediction-learn-more"
          content={
            <>
              {t("learnMore")}
              <img
                className="learn-more-arrow"
                src={clientContext.assets?.navArrow}
                alt=""
              />
            </>
          }
          onClick={onOpenPrediction}
        />
      ) : null}
      {tenantFlagsEnabled.FlightPriceDropProtection ? (
        <ActionLink
          className="mobile-price-prediction-learn-more"
          content={
            <>
              <b>{t("priceForecast.learnMore")}</b>
              <Icon name={IconName.InfoCircle} className="info-icon" />
            </>
          }
          onClick={onOpenPriceDrop}
        />
      ) : null}
    </Box>
  );
};

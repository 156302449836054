import type { Branding } from "@hopper-b2b/types";
import { Env, type PortalConfig } from "../../types";
import { buildThemeFromBasicConfig } from "./theme";

const getEnv = (environment: string | undefined): Env => {
  const env = environment != null ? Env[environment] : Env.local;

  if (environment == null)
    console.warn(
      `${environment} is not a valid environment. Local environment use instead`
    );

  return env;
};

const config: Partial<Omit<PortalConfig, "branding">> & {
  branding: Partial<Branding>;
} = {
  bootConfig: {
    env: getEnv(import.meta.env.VITE_ENV),
    processEnv: getEnv(process.env.NODE_ENV),
  },
  theme: buildThemeFromBasicConfig({
    variables: {},
    images: {},
    icons: {},
  }),
  featureFlags: {
    enablePricePrediction: true,
  },
  branding: {
    default: "en",
  },
};

export default config;

import {
  passengerCountSelector,
  selectedTripSelector,
} from "@b2bportal/core-flights";
import { PriceFreezeShopRequestFlight } from "@b2bportal/price-freeze-api";
import { createSelector } from "@reduxjs/toolkit";
import { FlightsPriceFreezeState } from "../../../store";

export const getFlightPriceFreezeOffers = (state: FlightsPriceFreezeState) => {
  return state.flightsPriceFreeze.purchase.flightsPriceFreezeOffers;
};

export const getIsFlightPriceFreezeShopLoading = (
  state: FlightsPriceFreezeState
) => state.flightsPriceFreeze.purchase.isLoading;

export const getFlightPriceFreezeShopFailure = (
  state: FlightsPriceFreezeState
) => state.flightsPriceFreeze.purchase.failureReason;

export const getLowestPriceFreezeOffer = createSelector(
  getFlightPriceFreezeOffers,
  (offers) => {
    if (!offers || offers?.length < 1) {
      return null;
    }

    let minOffer = offers[0];
    offers.forEach((offer) => {
      if (
        offer.serviceDetails.perUnitServiceFee.fiat.value <
        minOffer.serviceDetails.perUnitServiceFee.fiat.value
      ) {
        minOffer = offer;
      }
    });

    return minOffer;
  }
);
export const getLongestDurationDay = createSelector(
  getFlightPriceFreezeOffers,
  (offers) => {
    if (!offers || offers?.length < 1) {
      return 0;
    }

    return offers.reduce(
      (max, offer) => Math.max(max, offer.serviceDetails.serviceDurationDays),
      0
    );
  }
);

export const getPriceFreezeShopRequest = createSelector(
  (state) => selectedTripSelector(state),
  (state) => passengerCountSelector(state),
  (selectedTrip, passengerCount): PriceFreezeShopRequestFlight | null => {
    const tripId = selectedTrip?.tripId;
    const fareId = selectedTrip?.returnFareId ?? selectedTrip?.outgoingFareId;

    if (tripId != null && !!tripId && fareId != null && !!fareId) {
      return {
        PriceFreezeShopRequest: "Flight",
        tripId,
        fareId,
        passengerCount: {
          numOfAdults: passengerCount.adultsCount,
          numOfChildren: passengerCount.childrenCount,
          numOfInfants: passengerCount.infantsInSeatCount,
          numOfLapInfants: passengerCount.infantsOnLapCount,
        },
      };
    } else {
      return null;
    }
  }
);

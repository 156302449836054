import { useEffect, useCallback, useState, useContext } from "react";
import { type RouteComponentProps, useLocation } from "react-router";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackEvent } from "@hopper-b2b/api";
import { I18nMarkup, useI18nContext } from "@hopper-b2b/i18n";
import {
  CHANGED_TRIP_TYPE,
  ChangeTripTypeScreenEnum,
  type TripCategory,
} from "@hopper-b2b/types";
import {
  Header,
  ActionLink,
  IconComponent,
  IconName,
  B2BLoadingPopup,
  Slot,
} from "@hopper-b2b/ui";
import {
  tenantFlagsEnabled,
  useEnableInstallmentsCampaign,
  useEnableBlackFridayExperiment,
  useGetNubankMaxInstallments,
} from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";

import { PATH_HOME } from "../../../../utils/urlPaths";
import { MobileFlightSearchStep, getCalendarA11yEnabled } from "../../reducer";
import { CalendarPicker } from "../CalendarPicker";
import { LocationSearchWithDropdowns } from "./components";
import type { MobileFlightSearchConnectorProps } from "./container";
import "./styles.scss";
import { ClientContext } from "../../../../App";
import { useDispatch, useSelector } from "react-redux";
import { setCalendarA11yMode } from "../../actions/actions";

export interface IMobileFlightSearchControlProps
  extends MobileFlightSearchConnectorProps,
    RouteComponentProps {}

export const MobileFlightSearchContent = (
  props: IMobileFlightSearchControlProps
) => {
  const {
    originCode,
    destinationCode,
    tripCategory,
    fetchDepartureCalendar,
    setCalendar,
    currentStep,
    departureDate,
    returnDate,
    setCurrentStep,
    history,
    renderSearchInput,
    setRenderSearchInput,
    calendarVisited,
    setCalendarVisited,
    populateSearchUrlParams,
    setDepartureDate,
    setReturnDate,
    hasSelectedDates,
    trackingProperties,
    origin,
    destination,
  } = props;

  const enableBlackFriday = useEnableBlackFridayExperiment();
  const enableInstallmentsCampaign = useEnableInstallmentsCampaign();
  const maxInstallments = useGetNubankMaxInstallments();

  const updateTripCategory = (value: TripCategory) => {
    populateSearchUrlParams({ tripCategory: value }, history);
    trackEvent({
      eventName: CHANGED_TRIP_TYPE,
      properties: { screen: ChangeTripTypeScreenEnum.LOCATION_PICKER },
    });
  };

  const { t } = useI18nContext();

  const { assets } = useContext(ClientContext);
  const { featureFlag } = useContext(ClientContext);
  const dispatch = useDispatch();

  const calendarA11yModeSelector = useSelector(getCalendarA11yEnabled);

  const focusedMonthIndex = departureDate
    ? dayjs(departureDate).diff(dayjs(), "month")
    : 0;

  const handleGoBack = useCallback(() => {
    if (renderSearchInput) {
      setRenderSearchInput("");
    } else {
      setCurrentStep(
        currentStep > MobileFlightSearchStep.LocationSearch
          ? currentStep - 1
          : currentStep
      );
    }
  }, [currentStep, renderSearchInput, setCurrentStep, setRenderSearchInput]);

  useEffect(() => {
    if (
      tenantFlagsEnabled.FlightSearchCalendarPrediction &&
      originCode &&
      destinationCode
    ) {
      fetchDepartureCalendar();
    } else {
      setCalendar();
    }

    if (!calendarVisited && originCode && destinationCode) {
      setCurrentStep(MobileFlightSearchStep.CalendarPicker);
    }
  }, [
    originCode,
    destinationCode,
    tripCategory,
    calendarVisited,
    fetchDepartureCalendar,
    setCalendar,
    setCurrentStep,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (currentStep === MobileFlightSearchStep.CalendarPicker) {
      setRenderSearchInput("");
    }
  }, [currentStep, setRenderSearchInput]);

  const handleLocationSearchContinue = useCallback(
    () => setCurrentStep(MobileFlightSearchStep.CalendarPicker),
    [setCurrentStep]
  );

  const closeButtonHandler = useCallback(() => {
    setRenderSearchInput("");
    setCurrentStep(MobileFlightSearchStep.LocationSearch);
    history.push(PATH_HOME);
  }, [history, setCurrentStep, setRenderSearchInput]);

  const calendarA11yHandler = useCallback(() => {
    dispatch(setCalendarA11yMode(!calendarA11yModeSelector));
  }, [calendarA11yModeSelector, dispatch]);

  return (
    <Box
      className={clsx({
        "date-range-picker":
          currentStep === MobileFlightSearchStep.CalendarPicker,
      })}
    >
      <Header
        center={
          <HeaderCenterSection
            currentStep={currentStep}
            renderSearchInput={renderSearchInput}
          />
        }
        left={
          <Slot
            id="flight-search-mobile-header-left-content"
            className={clsx("mobile-flight-search-header-go-back", {
              hidden:
                currentStep === MobileFlightSearchStep.LocationSearch &&
                !renderSearchInput,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft as IconProp} />}
            searchStateStep={currentStep}
            component={
              <ActionLink
                className={clsx("mobile-flight-search-header-go-back", {
                  hidden:
                    currentStep === MobileFlightSearchStep.LocationSearch &&
                    !renderSearchInput,
                })}
                onClick={handleGoBack}
                content={<FontAwesomeIcon icon={faChevronLeft as IconProp} />}
              />
            }
          />
        }
        right={
          featureFlag?.enableA11yMode ? (
            <ActionLink
              className="mobile-flight-search-header-calendar-a11y"
              content={
                <img
                  src={
                    calendarA11yModeSelector
                      ? assets?.a11yIconActive
                      : assets?.a11yIcon
                  }
                  alt=""
                />
              }
              onClick={calendarA11yHandler}
            />
          ) : (
            <ActionLink
              className="mobile-flight-search-header-close"
              onClick={closeButtonHandler}
              content={
                <IconComponent
                  ariaLabel="Close button icon"
                  className={clsx("close-button-icon")}
                  name={IconName.Close}
                />
              }
              label={t("close.button")}
            />
          )
        }
        isMobile={true}
        fullWidth={true}
      />
      {/* Black Friday Banner, Remove after 11/12/2024 */}
      {enableBlackFriday &&
      currentStep === MobileFlightSearchStep.LocationSearch ? (
        <div className="black-friday-banner">
          <img
            src={assets.blackFriday}
            className="icon"
            alt={t("blackFriday.title")}
          />
          <I18nMarkup
            className="default-text"
            tKey={"blackFriday.description"}
          />
        </div>
      ) : null}
      {enableInstallmentsCampaign ? (
        <div className="installments-campaign-banner">
          {t("installmentsCampaign", { installments: maxInstallments })}
        </div>
      ) : null}
      {currentStep === MobileFlightSearchStep.LocationSearch && (
        <Box className="location-search-container">
          <LocationSearchWithDropdowns
            onComplete={handleLocationSearchContinue}
            origin={origin}
            destination={destination}
            tripCategory={tripCategory}
            setTripCategory={updateTripCategory}
          />
        </Box>
      )}
      {currentStep === MobileFlightSearchStep.CalendarPicker && (
        <CalendarPicker
          tripCategory={tripCategory}
          departureDate={departureDate}
          returnDate={returnDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          focusedMonthIndex={focusedMonthIndex}
          isMobile
          calendarVisited={calendarVisited}
          setCalendarVisited={setCalendarVisited}
          populateSearchUrlParams={populateSearchUrlParams}
          disabled={!hasSelectedDates}
          trackingProperties={trackingProperties}
          a11yMode={calendarA11yModeSelector}
        />
      )}
    </Box>
  );
};

const MobileFlightSearchLoader = () => {
  const { t } = useI18nContext();
  return (
    <B2BLoadingPopup
      open
      message={t("loading")}
      className="flight-search-loading-popup"
      popupSize={"mobile"}
      fullScreen
    />
  );
};

export const MobileFlightSearchControl = (
  props: IMobileFlightSearchControlProps
) => {
  const { destination, origin } = props;
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const originParam = searchParams.get("origin");
  const destinationParam = searchParams.get("destination");

  // Check if redux and params match, if not wait till they match or
  // - for the params to be cleared by fetchSearchFromUrlSaga
  useEffect(() => {
    if (
      originParam &&
      originParam.length > 0 &&
      destinationParam &&
      destinationParam.length > 0
    ) {
      if (
        originParam === origin?.id?.code?.code &&
        destinationParam === destination?.id?.code?.code
      ) {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [origin, destination, originParam, destinationParam]);

  return loading ? (
    <MobileFlightSearchLoader />
  ) : (
    <MobileFlightSearchContent {...props} />
  );
};

interface IHeaderCenterSectionProps {
  currentStep: number;
  renderSearchInput: string;
}

const HeaderCenterSection = (props: IHeaderCenterSectionProps) => {
  const { currentStep, renderSearchInput } = props;
  const { t } = useI18nContext();

  const renderLocationSearchHeader = () => {
    switch (renderSearchInput) {
      case "origin":
        return <span>{t("searchControl.originHeader")}</span>;
      case "destination":
        return <span>{t("searchControl.destinationHeader")}</span>;
      default:
        return <span>{t("flights")}</span>;
    }
  };

  return (
    <Box className="header-center-section">
      {currentStep === MobileFlightSearchStep.CalendarPicker && (
        <Box className="trip-origin-destination-summary">
          <Box className="origin-destination-container">
            <Typography variant="body2">
              {t("mobileSearchChooseDate")}
            </Typography>
          </Box>
        </Box>
      )}
      {currentStep === MobileFlightSearchStep.LocationSearch && (
        <Box className="mobile-flight-search-location-label">
          {renderLocationSearchHeader()}
        </Box>
      )}
    </Box>
  );
};

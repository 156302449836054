import {
  type FilterState,
  getVehicleClass,
  type VehicleAvailabilityWithSupplier,
  getBaggageQty,
  type FilterOptions,
} from "@apac/components/cars";
import { IconNameEnum } from "@b2bportal/core-themes";
import type { TFunction } from "i18next";
import { maxBy, minBy, uniq } from "lodash-es";

export interface CarsFilter {
  title: (t: TFunction) => string;
  iconName: IconNameEnum;
  getOptions: (cars: VehicleAvailabilityWithSupplier[]) => unknown;
  apply: (
    car: VehicleAvailabilityWithSupplier,
    filterValue: unknown
  ) => boolean;
}

type FilterKeys = keyof FilterState;

export const CARS_FILTER_LIST: FilterKeys[] = [
  "vehicleClass",
  "priceRange",
  "passengerQty",
  "baggageQty",
];

export const CARS_FILTERS: Record<FilterKeys, CarsFilter> = {
  priceRange: {
    title: (t) => t("apac.cars.filters.price.title"),
    iconName: IconNameEnum.fareDetailsCharge,
    getOptions: (cars): FilterOptions["priceRange"] => {
      const carsPrice = cars.flatMap((car) => {
        const { totalCharge } = car;
        return totalCharge == null ? [] : [totalCharge.fiat];
      });
      const minPrice = minBy(carsPrice, (fiat) => fiat.value) ?? carsPrice[0];
      const maxPrice = maxBy(carsPrice, (fiat) => fiat.value) ?? carsPrice[0];
      return {
        min: minPrice,
        max: maxPrice,
      };
    },
    apply: (car, filterValue: NonNullable<FilterState["priceRange"]>) => {
      return (
        filterValue.min <= car.totalCharge.fiat.value &&
        filterValue.max >= car.totalCharge.fiat.value
      );
    },
  },
  vehicleClass: {
    title: (t) => t("apac.cars.filters.vehicleClass.title"),
    iconName: IconNameEnum.car,
    getOptions: (cars): FilterOptions["vehicleClass"] => {
      return uniq(cars.map((car) => getVehicleClass(car.vehicle)));
    },
    apply: (car, filterValue: NonNullable<FilterState["vehicleClass"]>) => {
      return filterValue.includes(getVehicleClass(car.vehicle));
    },
  },
  passengerQty: {
    title: (t) => t("apac.cars.filters.passengerQty.title"),
    iconName: IconNameEnum.guest,
    getOptions: (cars): FilterOptions["passengerQty"] => {
      const passengerQtys = cars.map((car) =>
        car.vehicle.passengerQty.toString()
      );
      return uniq(passengerQtys);
    },
    apply: (car, filterValue: NonNullable<FilterState["passengerQty"]>) => {
      return filterValue.includes(car.vehicle.passengerQty.toString());
    },
  },
  baggageQty: {
    title: (t) => t("apac.cars.filters.baggageQty.title"),
    iconName: IconNameEnum.baggageChecked,
    getOptions: (cars): FilterOptions["baggageQty"] => {
      const baggageQtys = cars.map((car) =>
        getBaggageQty(car.vehicle).toString()
      );
      return uniq(baggageQtys);
    },
    apply: (car, filterValue: NonNullable<FilterState["baggageQty"]>) => {
      const baggageQty = getBaggageQty(car.vehicle);
      return filterValue.includes(baggageQty.toString());
    },
  },
};

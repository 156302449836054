export const CARS_ROUTES = {
  SEARCH_ROUTE: "search",
  AVAILABILITY_ROUTE: "availability",
  SHOP_ROUTE: "shop",
  BOOK_ROUTE: "book",
};

export const CARS_HOME_ROUTE = `/`;

export const CARS_SEARCH_CALENDAR_HASH = "#calendar";

export const CARS_AVAILABILITY_PARAMS = ":pickupLocation/:dropoffLocation";
export const CARS_PATH = `/cars`;

export const CARS_SEARCH_PATH = `${CARS_PATH}/${CARS_ROUTES.SEARCH_ROUTE}`;

export const CARS_AVAILABILITY_PATH = `${CARS_PATH}/${CARS_ROUTES.AVAILABILITY_ROUTE}`;

export const CARS_SHOP_PATH = `${CARS_PATH}/${CARS_ROUTES.SHOP_ROUTE}`;

export const CARS_BOOK_PATH = `${CARS_PATH}/${CARS_ROUTES.BOOK_ROUTE}`;

import { PriceFreezeQuoteRequestV3 } from "@b2bportal/price-freeze-api";
import { Product, ProductOpaqueValue } from "@b2bportal/purchase-api";
import { getObjectKeysAsObject, setContextWithKey } from "@checkout/helpers";
import { cartHelpers } from "@checkout/states";
import { assign } from "xstate";
import {
  AirPriceFreezeMachineContext,
  initialAirPriceFreezeOfferContext,
} from "./context";
import {
  SetAvailableOffersEvent,
  SetContactInfoEvent,
  SetSelectedOfferIdEvent,
  UpdateEmailEvent,
} from "./events";
import { AirPriceFreezeStateEnum } from "./types";

export const actions = {
  // clean both available offers and selected offers
  resetPriceFreezeOffers: assign((ctx: AirPriceFreezeMachineContext) =>
    setContextWithKey(
      ctx,
      `${AirPriceFreezeStateEnum.priceFreezeOffer}`,
      initialAirPriceFreezeOfferContext
    )
  ),
  setPriceFreezeOffers: assign(
    (ctx: AirPriceFreezeMachineContext, event: SetAvailableOffersEvent) =>
      setContextWithKey(
        ctx,
        `${AirPriceFreezeStateEnum.priceFreezeOffer}.flightsPriceFreezeOffers`,
        event.offers
      )
  ),
  resetSelectedPriceFreezeOffer: assign((ctx: AirPriceFreezeMachineContext) =>
    setContextWithKey(
      ctx,
      `${AirPriceFreezeStateEnum.priceFreezeOffer}.selectedOfferId`,
      undefined
    )
  ),
  setSelectedPriceFreezeOfferId: assign(
    (ctx: AirPriceFreezeMachineContext, event: SetSelectedOfferIdEvent) => {
      return setContextWithKey(
        ctx,
        `${AirPriceFreezeStateEnum.priceFreezeOffer}.selectedOfferId`,
        event.offerId
      );
    }
  ),
  setContactInfo: assign(
    (ctx: AirPriceFreezeMachineContext, event: SetContactInfoEvent) => {
      setContextWithKey(
        ctx,
        AirPriceFreezeStateEnum.contactInformation,
        event.contactInfo
      );
      ctx.cartQuote.customerEmail = event.contactInfo.email;
      return ctx;
    }
  ),
  updateEmail: assign(
    (ctx: AirPriceFreezeMachineContext, event: UpdateEmailEvent) => {
      setContextWithKey(
        ctx,
        `${AirPriceFreezeStateEnum.contactInformation}.email`,
        event.email
      );
      ctx.cartQuote.customerEmail = event.email;
      return ctx;
    }
  ),

  addPriceFreezeProduct: assign((ctx: AirPriceFreezeMachineContext) => {
    const priceFreezeProduct: PriceFreezeQuoteRequestV3 = {
      offerId: ctx.priceFreezeOffer.selectedOfferId ?? "",
    };

    const opaqueProduct: ProductOpaqueValue = {
      type: Product.PriceFreeze,
      value: priceFreezeProduct,
    };
    cartHelpers.addQuoteProduct(opaqueProduct, ctx);

    return ctx;
  }),
};

export const ActionTypes = getObjectKeysAsObject(actions);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPlace } from "./api/fetchPlace";
import type { CarsAvailabilitySearchState } from "../types";
import { GroundSelectionEnum } from "@b2bportal/ground-api";
import { fetchCars, initialCarAvailabilityState } from "../index";
import { CarNavigationUtility } from "../../../../utilities";
import { DropoffType, type SearchDetails } from "../../../../types";
import type { CarsState } from "../../../../index";
import { uniq } from "lodash-es";

export const getPartialSearchFromURL = (
  searchParams: URLSearchParams
): Omit<
  SearchDetails,
  "pickupLocation" | "dropoffLocation" | "searchType" | "dropoffType"
> | null => {
  const urlParams = CarNavigationUtility.parseGroundParams(searchParams);

  if (isNaN(urlParams.pickupDateTime.getTime())) {
    return null;
  }

  return {
    pickupDateTime: CarNavigationUtility.formatDateForUrl(
      urlParams.pickupDateTime
    ),
    dropoffDateTime: CarNavigationUtility.formatDateForUrl(
      urlParams.dropoffDateTime
    ),
    driverAge: Number(urlParams.driverAge),
  };
};

export const initializeSearchState = createAsyncThunk<
  CarsAvailabilitySearchState,
  {
    searchParams: URLSearchParams;
    pickupLocation: string;
    dropoffLocation: string;
  }
>(
  "carsAvailability/initializeSearchState",
  async ({ searchParams, pickupLocation, dropoffLocation }, thunkAPI) => {
    const maybePartialRequestedSearch = getPartialSearchFromURL(searchParams);

    try {
      if (maybePartialRequestedSearch == null) {
        return thunkAPI.rejectWithValue("Dates are missing");
      }

      const urlParams = CarNavigationUtility.parseGroundParams(searchParams);

      const [pickupPlace, dropoffPlace] = await Promise.all(
        uniq([pickupLocation, dropoffLocation]).map(fetchPlace)
      );

      if (pickupPlace == null) {
        return thunkAPI.rejectWithValue("Error fetching place");
      }

      const requestedSearch = {
        ...maybePartialRequestedSearch,
        pickupLocation: pickupPlace,
        dropoffLocation: dropoffPlace ?? pickupPlace,
        searchType: GroundSelectionEnum.Place,
        dropoffType: dropoffPlace ? DropoffType.OneWay : DropoffType.RoundTrip,
      };

      return {
        searchFormValues: requestedSearch,
        requestedSearch: requestedSearch,
        map: {
          centroid: urlParams.map?.centroid,
          zoom: urlParams.map?.zoom ?? initialCarAvailabilityState.map.zoom,
        },
        sort: urlParams.sortBy,
        filters: urlParams.filters,
      };
    } catch (error) {
      console.error(`Error fetching search locations. ${error}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initializeFetchCars = createAsyncThunk<
  void,
  void,
  { state: CarsState; rejectValue: { error: string } }
>("carsAvailability/initializeFetchCars", async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const { requestedSearch } = state.cars.carsAvailability;

  if (requestedSearch == null) {
    return thunkAPI.rejectWithValue({ error: "Search is empty" });
  }

  try {
    thunkAPI.dispatch(
      fetchCars({
        GroundSelection: GroundSelectionEnum.Place,
        request: requestedSearch,
      })
    );
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    return thunkAPI.rejectWithValue({ error: errorMessage });
  }
});

export const initializeCars = createAsyncThunk<
  void,
  {
    searchParams: URLSearchParams;
    pickupLocation: string;
    dropoffLocation: string;
  }
>("carsAvailability/initializeCars", async (props, thunkAPI) => {
  try {
    await thunkAPI.dispatch(initializeSearchState(props));
    thunkAPI.dispatch(initializeFetchCars());
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    return thunkAPI.rejectWithValue({ error: errorMessage });
  }
});

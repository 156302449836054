import { createAsyncThunk } from "@reduxjs/toolkit";
import type { HotelsState } from "../../../../store";
import {
  AvailabilityApiCall,
  fetchAvailabilityByLodgingId,
  fetchAvailabilityByPlaceAPI,
  fetchInitialAvailabilityByLocation,
} from "./api/fetchAvailability";
import {
  Initial,
  LodgingSelectionEnum,
  Platform,
} from "@b2bportal/lodging-api";
import { ensureExhaustive, getDeviceType } from "@b2bportal/core-utilities";
import { v4 as uuidv4 } from "uuid";

export const fetchInitialHotels = createAsyncThunk<
  Initial & { searchId: string },
  AvailabilityApiCall,
  { state: HotelsState; rejectValue: { error: string } }
>("hotelsAvailability/fetchInitialHotels", async (req, thunkAPI) => {
  const state = thunkAPI.getState();
  const { requestedSearch, map } = state.hotels.hotelsAvailability;

  if (requestedSearch == null) {
    return thunkAPI.rejectWithValue({ error: "Search is empty" });
  }

  try {
    const fetchAvailability = () => {
      const { LodgingSelection, request } = req;

      switch (LodgingSelection) {
        case LodgingSelectionEnum.Place:
          return fetchAvailabilityByPlaceAPI(request);

        case LodgingSelectionEnum.Location:
          return fetchInitialAvailabilityByLocation(request);

        case LodgingSelectionEnum.LodgingIds:
          return fetchAvailabilityByLodgingId(request);

        default:
          ensureExhaustive(LodgingSelection);
      }
    };

    const result = await fetchAvailability();
    return { ...result, searchId: uuidv4() };
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    return thunkAPI.rejectWithValue({ error: errorMessage });
  }
});

export enum FlightShopTrackingRawEventName {
  ViewedFlightListLoading = "ViewedFlightListLoading",
  ViewedFlightList = "ViewedFlightList",
  ViewedForecast = "ViewedForecast",
  AppliedFilter = "AppliedFilter",
  ViewedSlice = "ViewedSlice",
  SelectedFlight = "SelectedFlight",
  ViewedTripSummary = "ViewedTripSummary",
  ViewedFareDetails = "ViewedFareDetails",
}

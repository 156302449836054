import {
  IconNameEnum,
  useModuleBEM,
  useTripsStyles,
} from "@b2bportal/core-themes";
import {
  CoreTripsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { Icon } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import defaultStyles from "./styles.module.scss";

export interface TripsCardComponentProps extends PlatformComponentProps {
  banner?: JSX.Element;
  status?: JSX.Element;
  product: JSX.Element;
  title: string;
  onClick: () => void;
}

const COMPONENT_KEY = CoreTripsComponent.TripsCard;

export const TripsCard = ({
  banner,
  className,
  children,
  status,
  product,
  title,
  onClick,
}: TripsCardComponentProps) => {
  const styles = useTripsStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const { t } = useI18nContext();

  return (
    <button onClick={() => onClick()} className={clsx(block, className)}>
      {banner ? <div className={cn("banner")}>{banner}</div> : null}
      <div className={cn("product")}>{product}</div>
      <div className={cn("title")}>{title}</div>
      {children ? <div className={cn("product-info")}>{children}</div> : null}
      <div className={cn("action-icon-container")}>
        <Icon iconName={IconNameEnum.chevronRight} />
      </div>
      {/* {status ? <div className={cn("status")}>{status}</div> : null} */}
    </button>
  );
};

import { CallState } from "@b2bportal/core-types";
import {
  FindProductToExerciseResponseEnum,
  FrozenProductEnum,
} from "@b2bportal/price-freeze-api";
import type { FlightsPriceFreezeState } from "../../../store";
import { createSelector } from "@reduxjs/toolkit";

export const getIsPriceFreezeDetailsLoading = (
  state: FlightsPriceFreezeState
) =>
  state.flightsPriceFreeze.details.fetchTripDetailsCallState ===
    CallState.InProcess ||
  state.flightsPriceFreeze.details.fetchPriceFreezeVoucherCallState ===
    CallState.InProcess ||
  state.flightsPriceFreeze.details.matchedLastestTripCallState ===
    CallState.InProcess;

export const getPriceFreezeDetailsFailed = (state: FlightsPriceFreezeState) =>
  state.flightsPriceFreeze.details.fetchTripDetailsCallState ===
    CallState.Failed ||
  state.flightsPriceFreeze.details.fetchPriceFreezeVoucherCallState ===
    CallState.Failed ||
  state.flightsPriceFreeze.details.matchedLastestTripCallState ===
    CallState.Failed;

export const getPriceFreezeTripDetails = (state: FlightsPriceFreezeState) => {
  return state.flightsPriceFreeze.details.tripDetails;
};

export const getMatchedLatestTrip = (state: FlightsPriceFreezeState) => {
  return state.flightsPriceFreeze.details.matchedLatestTrip;
};

export const getMatchedLatestTripCallStatus = (
  state: FlightsPriceFreezeState
) => {
  return state.flightsPriceFreeze.details.matchedLastestTripCallState;
};

export const getPriceFreezeVoucher = (state: FlightsPriceFreezeState) =>
  state.flightsPriceFreeze.details.voucher;

export const getPriceFreezeFareDetails = createSelector(
  getMatchedLatestTrip,
  getPriceFreezeTripDetails,
  (latestTrip, tripDetails) => {
    if (latestTrip == null || tripDetails == null) {
      return;
    }
    return tripDetails.fareDetails.find((f) => f.id === latestTrip.fareId);
  }
);

export const getPassengersNumber = (state: FlightsPriceFreezeState) => {
  const flight = state.flightsPriceFreeze.details.voucher?.frozenProduct;
  if (flight == null || flight.FrozenProduct !== FrozenProductEnum.Flight) {
    return NaN;
  }

  return (
    flight.passengerCount.numOfAdults +
    flight.passengerCount.numOfChildren +
    flight.passengerCount.numOfInfants +
    flight.passengerCount.numOfLapInfants
  );
};

export const openExerciseRefundErrorModal = (
  state: FlightsPriceFreezeState
) => {
  const details = state.flightsPriceFreeze.details;
  return details.matchedLastestTripCallState === CallState.Failed;
};

export const getMatchedTripNotAvailable = (state: FlightsPriceFreezeState) => {
  const details = state.flightsPriceFreeze.details;
  if (details == null) {
    return false;
  }
  return (
    details.matchedLastestTripCallState === CallState.Failed &&
    details.failureReason === FindProductToExerciseResponseEnum.NotAvailable
  );
};

import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  GetHotelReservationRequest,
  HotelItinerary,
  HotelItineraryState,
  HotelItinerarySummary,
} from "@b2bportal/lodging-api";
import axios from "axios";
import { hotelsApi } from "@overrides/utilities";
import dayjs from "dayjs";
import { ReservationWrapper } from "@b2bportal/core-types";

export interface IHotelItineraryResponse {
  itineraries: {
    [key: string]: Array<ReservationWrapper<HotelItinerarySummary>>;
  };
}

export const handleAxiosError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const status = error.response?.status || 500;
    const data = error.response?.data || error.message;
    return { type: "network", error: { status, data } };
  }

  return {
    error: { type: "unknown" },
  };
};

export const hotelsPostBookingApi = createApi({
  reducerPath: "hotelsPostBooking",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["HotelReservationList"],
  endpoints: (builder) => ({
    fetchAllHotelReservations: builder.query<IHotelItineraryResponse, void>({
      queryFn: async () => {
        try {
          const response = await hotelsApi.apiV0TripsHotelPut({
            states: [
              HotelItineraryState.Canceled,
              HotelItineraryState.Future,
              HotelItineraryState.Present,
              HotelItineraryState.Past,
            ],
            referenceDateTime: dayjs().format(),
          });

          const wrappedReservations = Object.keys(
            response.data.itineraries
          ).reduce(
            (acc, key) => {
              const reservations = response.data.itineraries[key];
              const wrapped = reservations.map((reservation) => ({
                reservation,
                reservationId: reservation.reservationBookingId,
                sortDate: reservation.checkInDate,
                recentFirst:
                  key === HotelItineraryState.Past ||
                  key === HotelItineraryState.Canceled,
              }));
              return { ...acc, [key]: wrapped };
            },
            {} as {
              [key: string]: Array<ReservationWrapper<HotelItinerarySummary>>;
            }
          );

          return {
            data: {
              itineraries: wrappedReservations,
            },
          };
        } catch (error) {
          return handleAxiosError(error);
        }
      },
      providesTags: ["HotelReservationList"],
    }),
    fetchHotelReservation: builder.query<
      HotelItinerary,
      GetHotelReservationRequest
    >({
      queryFn: async (request) => {
        try {
          const response = await hotelsApi.apiV0TripsHotelGetReservationPut(
            request
          );

          return { data: response.data };
        } catch (error) {
          return handleAxiosError(error);
        }
      },
    }),
  }),
});

import { CARS_FILTERS, type FilterOptions } from "@apac/components/cars";
import { CallState } from "@b2bportal/core-types";
import {
  GroundSelectionEnum,
  type GroundSupplier,
  type VehicleAvailability,
} from "@b2bportal/ground-api";
import { ensureExhaustive } from "@overrides/utilities";
import { createSelector } from "@reduxjs/toolkit";
import type {
  CarsState,
  VehicleAvailabilityGroup,
  VehicleAvailabilityWithSupplier,
} from "../../../store";
import { CarListSortComparatorsMap } from "../../../utilities/availability";
import dayjs from "dayjs";

export const isSearchStateInitialized = (state: CarsState) =>
  state.cars.carsAvailability.initialized === CallState.Success;

export const getSearchFormPickupLocation = (state: CarsState) =>
  state.cars.carsAvailability.searchFormValues.pickupLocation;

export const getSearchFormPickupDateTime = (state: CarsState) =>
  toDateIfDefined(state.cars.carsAvailability.searchFormValues.pickupDateTime);

export const getSearchFormDropoffType = (state: CarsState) =>
  state.cars.carsAvailability.searchFormValues.dropoffType;

export const getSearchFormDropoffLocation = (state: CarsState) =>
  state.cars.carsAvailability.searchFormValues.dropoffLocation;

export const getSearchFormDropoffDateTime = (state: CarsState) =>
  toDateIfDefined(state.cars.carsAvailability.searchFormValues.dropoffDateTime);

export const getSearchFormDriverAge = (state: CarsState) =>
  state.cars.carsAvailability.searchFormValues.driverAge;

export const getSearch = (state: CarsState) =>
  state.cars.carsAvailability.search;

export const getRequestedSearch = (state: CarsState) =>
  state.cars.carsAvailability.requestedSearch;

export const getTotalDays = (state: CarsState) => {
  const search = getSearch(state);

  if (search == null) return undefined;

  const { pickupDateTime, dropoffDateTime } = search;
  if (pickupDateTime != null && dropoffDateTime != null) {
    const fromDate = dayjs(pickupDateTime);
    const untilDate = dayjs(dropoffDateTime);
    return untilDate.diff(fromDate, "days");
  }
};

export const getRawCars = (state: CarsState) =>
  state.cars.carsAvailability.searchResult.data?.vehicles ?? [];

export const getSuppliers = (state: CarsState) =>
  state.cars.carsAvailability.searchResult.data?.context.suppliers;

const embedSupplierData = (
  vehicle: VehicleAvailability,
  suppliers?: Record<string, GroundSupplier>
) => {
  const supplier = suppliers?.[vehicle.supplierRef];
  if (supplier == null) {
    // TODO: log error to datadog
    throw new Error("missing supplier: " + vehicle.supplierRef);
  }

  return {
    ...vehicle,
    supplier,
  };
};

export const getUnsortedCars = createSelector(
  getRawCars,
  getSuppliers,
  (cars, suppliers) => cars.map((car) => embedSupplierData(car, suppliers))
);

export const getOpaqueShopRequestContext = (state: CarsState) =>
  state.cars.carsAvailability.searchResult.data?.context
    .opaqueShopRequestContext;

export const getCarsEqualityCheck = (
  prevCars: VehicleAvailabilityWithSupplier[],
  newCars: VehicleAvailabilityWithSupplier[]
) => {
  return (
    prevCars.length === newCars.length &&
    // This should only execute if the length of the arrays is the same
    prevCars.every((car, index) => car.vehicle.id === newCars[index].vehicle.id)
  );
};

export const isLoading = (state: CarsState) => {
  const { state: callState, data } = state.cars.carsAvailability.searchResult;
  return (
    callState === CallState.InProcess && (!data || data?.vehicles.length === 0)
  );
};
export const isInitializingValues = (state: CarsState) =>
  state.cars.carsAvailability.initialized === CallState.InProcess;
export const hasError = (state: CarsState) =>
  state.cars.carsAvailability.searchResult.state === CallState.Failed;
export const hasNeverFetchedCars = (state: CarsState) =>
  state.cars.carsAvailability.searchResult.state === CallState.NotCalled;

export const isAvailabilityPageLoading = (state: CarsState) =>
  isLoading(state) || hasNeverFetchedCars(state);

export const getSort = (state: CarsState) => state.cars.carsAvailability.sort;

export const getVehicleById = createSelector(
  getUnsortedCars,
  (_, vehicleId?: string) => vehicleId,
  (cars, vehicleId) =>
    vehicleId == null ? null : cars.find((car) => car.vehicle.id === vehicleId)
);

export const getAnyVehicleFromStore = createSelector(
  getUnsortedCars,
  (_, storeKey?: string) => storeKey,
  (cars, storeKey) =>
    storeKey == null
      ? null
      : cars.find((car) => getCarGroupKey(car) === storeKey)
);

export const getCars = (state: CarsState) => {
  const rawCars = getUnsortedCars(state);
  const sort = getSort(state);
  const sortComparator = CarListSortComparatorsMap[sort];
  if (sortComparator == null) {
    return rawCars;
  }
  return rawCars.slice().sort(sortComparator);
};

export const getStoreCars = createSelector(
  getUnsortedCars,
  getSort,
  getSuppliers,
  (_, storeKey: string) => storeKey,
  (cars, sort, suppliers, storeKey) => {
    const storeCars = cars.filter((car) => getCarGroupKey(car) === storeKey);
    if (storeCars.length === 0) return storeCars;
    const storeCarsWithSupplier = storeCars.map((car) =>
      embedSupplierData(car, suppliers)
    );
    const sortComparator = CarListSortComparatorsMap[sort];
    if (sortComparator === null) return storeCarsWithSupplier;
    return storeCarsWithSupplier.sort(sortComparator);
  }
);

export const getFilters = (state: CarsState) =>
  state.cars.carsAvailability.filters;

export const getFilterOptions = createSelector(getUnsortedCars, (allCars) => {
  return Object.fromEntries(
    Object.entries(CARS_FILTERS).map(([key, filter]) => [
      key,
      filter.getOptions(allCars),
    ])
  ) as any as FilterOptions;
});

export const amountOfSelectedFilters = createSelector(
  getFilters,
  (filters): number => {
    return Object.values(filters).filter((value) => value != null).length;
  }
);

export const hasSetFiltersSelector = createSelector(
  amountOfSelectedFilters,
  (amount): boolean => {
    return amount > 0;
  }
);

export const getFilteredCars = createSelector(
  getCars,
  getFilters,
  (cars, filters) => {
    return cars.filter((car) => {
      return Object.entries(filters).every(([key, value]) => {
        if (value == null || (Array.isArray(value) && value.length === 0))
          return true;
        const filter = CARS_FILTERS[key];
        return filter?.apply(car, value) ?? true;
      });
    });
  }
);

export const getCarGroupKey = (car: VehicleAvailability) =>
  `${car.supplierName}_${car.pickupLocationName}`;

export const getGroupedCars = createSelector(getFilteredCars, (cars) => {
  const groups: VehicleAvailabilityGroup[] = [];
  const groupsByKey: Record<string, VehicleAvailabilityGroup> = {};
  for (const car of cars) {
    const key = getCarGroupKey(car);
    let group = groupsByKey[key];
    if (group == null) {
      group = {
        key,
        supplier: car.supplier,
        supplierName: car.supplierName ?? "",
        pickupLocationName: car.pickupLocationName ?? "",
        pickUp: car.pickUp,
        dropOff: car.dropOff,
        cars: [],
      };
      groups.push(group);
      groupsByKey[key] = group;
    }
    group.cars.push(car);
  }
  return groups;
});

export const getCentroid = (state: CarsState) =>
  state.cars.carsAvailability.map.centroid;

export const getMapZoom = (state: CarsState) =>
  state.cars.carsAvailability.map.zoom;

export const getMapBoundingBox = (state: CarsState) =>
  state.cars.carsAvailability.map.boundingBox;

export const getCurrentAvailabilityRequest = createSelector(
  getSearch,
  (search) => {
    if (!search) return undefined;

    switch (search.searchType) {
      case GroundSelectionEnum.Place: {
        return {
          GroundSelection: search.searchType,
          request: search,
        };
      }
      case GroundSelectionEnum.Location:
        return undefined;
      default:
        ensureExhaustive(search.searchType, undefined);
    }
  }
);

const toDateIfDefined = (date: string | undefined) =>
  date == null ? undefined : new Date(date);

import type {
  FlightItinerarySegment,
  ScheduleChangeSeverity,
} from "@b2bportal/air-booking-api";
import type { TripSegment, TripSlice } from "@b2bportal/air-shopping-api";
import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  ScheduleChangeDisplayMode,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import {
  FlightAirportDetails,
  FlightCardRecheckBaggageBanner,
  FlightMissedConnectionDialog,
  FlightScheduleChangeBadge,
  useFlightDetailsSegment,
} from "@components/flights";
import { AirlineIcon, Icon } from "@components/ui";
import { Trans } from "@hopper-b2b/i18n";
import clsx from "clsx";
import defaultStyles from "./FlightDetails.module.scss";

export interface FlightDetailsSegmentComponentProps
  extends PlatformComponentProps {
  cabinClass?: string;
  nextSegment?: TripSegment;
  planeInfo?: string;
  scheduleChangeDisplayMode?: ScheduleChangeDisplayMode;
  scheduleChangeSeverity?: ScheduleChangeSeverity;
  flightSlice: TripSlice;
  segment: TripSegment;
  updatedSegment?: FlightItinerarySegment;
  isSelfTransferLayover?: boolean;
}

export const FlightDetailsSegment = (
  props: FlightDetailsSegmentComponentProps
) => {
  const {
    flightSlice,
    segment,
    cabinClass,
    planeInfo,
    className,
    children,
    isSelfTransferLayover = false,
  } = props;
  const { context, text } = useFlightDetailsSegment(props);
  const COMPONENT_KEY = CoreFlightsComponent.FlightDetailsSegment;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY, {
    "has-layover": context.stopoverDurationMins > 0,
  });

  return (
    <div
      className={clsx(block, className)}
      key={`trip-segment-${segment.airlineCode}-${segment.flightNumber}`}
    >
      <div className={cn("icon")}>
        <div className={cn("circle")}></div>
        <div className={cn("line")}></div>
        <div className={cn("circle")}></div>
      </div>
      <div className={cn("airport-details", ["departure"])}>
        <FlightAirportDetails
          airportCode={segment.originCode}
          airportName={segment.originName}
          classNames={{
            originalTime: cn("time", ["original"]),
            segmentTime: cn("time", {
              original:
                context.hasScheduleChange &&
                context.displayMode === ScheduleChangeDisplayMode.original,
              updated:
                context.hasScheduleChange &&
                context.displayMode === ScheduleChangeDisplayMode.updated,
            }),
            updatedTime: cn("time", ["updated"]),
          }}
          currentTime={context.currentDepartureTime}
          displayMode={context.displayMode}
          hasScheduleChange={context.hasScheduleChange}
          prevTime={context.prevDepartureTime}
        />
      </div>
      {context.showScheduleChangeBadge &&
      props.scheduleChangeSeverity != null ? (
        <FlightScheduleChangeBadge
          className={cn("schedule-change-badge")}
          severity={props.scheduleChangeSeverity}
        />
      ) : null}
      <div
        className={cn("travel-time", {
          original:
            context.hasScheduleChange &&
            context.displayMode === ScheduleChangeDisplayMode.original,
        })}
      >
        {`${text.travelTime} ${context.travelTime}`}
      </div>
      <div className={cn("airport-details", ["arrival"])}>
        <FlightAirportDetails
          airportCode={segment.destinationCode}
          airportName={segment.destinationName}
          classNames={{
            originalTime: cn("time", ["original"]),
            segmentTime: cn("time", {
              original:
                context.hasScheduleChange &&
                context.displayMode === ScheduleChangeDisplayMode.original,
              updated:
                context.hasScheduleChange &&
                context.displayMode === ScheduleChangeDisplayMode.updated,
            }),
            updatedTime: cn("time", ["updated"]),
          }}
          currentTime={context.currentArrivalTime}
          displayMode={context.displayMode}
          hasScheduleChange={context.hasScheduleChange}
          prevTime={context.prevArrivalTime}
        />
      </div>
      <div className={cn("flight-info-root")}>
        <div className={cn("flight-info-details")}>
          <AirlineIcon
            className={cn("flight-info-icon")}
            airlineCode={segment.airlineCode}
            altText={segment.airlineName}
          />
          {segment.airlineName} / {segment.airlineCode} {segment.flightNumber}
          {cabinClass != null ? ` / ${cabinClass}` : null}
          {planeInfo != null ? ` / ${planeInfo}` : null}
        </div>
        {context.hasDifferentOperatingAirline === true ? (
          <div className={cn("operating-airline-info")}>{text.operatedBy}</div>
        ) : null}
        {context.plusDays !== 0 ? (
          <div className={cn("plus-days")}>
            {context.plusDays > 1 ? (
              // Flight arrives multiple days in the future
              <Trans
                i18nKey={"flightArrivalDifferentDay.flightArrivesPlusDays"}
                values={{
                  plusDays: context.plusDays,
                  arrivalDate: context.arrivalDate,
                }}
                components={{ 1: <span className={cn("notification-date")} /> }}
              />
            ) : context.plusDays > 0 ? (
              // Flight arrives multiple days after the departure date
              <Trans
                i18nKey={"flightArrivalDifferentDay.flightArrivesNextDay"}
                values={{
                  arrivalDate: context.arrivalDate,
                }}
                components={{ 1: <span className={cn("notification-date")} /> }}
              />
            ) : (
              // The flight arrives at a previous date, message should only show the arrival date
              <Trans
                i18nKey={"flightArrivalDifferentDay.flightArrivesOnDate"}
                values={{
                  arrivalDate: context.arrivalDate,
                }}
                components={{ 1: <span className={cn("notification-date")} /> }}
              />
            )}
          </div>
        ) : null}
      </div>
      {text.layoverWithLocation == null ? null : (
        <div className={cn("layover")}>
          <div
            className={cn("layover-time", {
              "self-transfer": isSelfTransferLayover,
            })}
          >
            {text.layoverWithLocation}
          </div>
          {isSelfTransferLayover ? (
            <>
              <div className={cn("missed-connection-guarantee")}>
                <Icon
                  className={cn("icon", ["check"])}
                  iconName={IconNameEnum.checkFilled}
                />
                <Trans
                  i18nKey={"core-flights.vi.missedConnectionGuarantee.banner"}
                  components={{
                    action: (
                      // The dialog component contains a button that opens the
                      // missed connection dialog as a `<DialogTrigger>`.
                      // `<Trans>` will set the copy as children of the dialog,
                      // which will become children of the button.
                      <FlightMissedConnectionDialog />
                    ),
                    info: (
                      <Icon
                        className={cn("icon")}
                        iconName={IconNameEnum.info}
                      />
                    ),
                  }}
                />
              </div>
              <FlightCardRecheckBaggageBanner
                className={cn("recheck-baggage-banner")}
                flightSlice={flightSlice}
              />
            </>
          ) : null}
        </div>
      )}
      {context.connectingFlightInfo != null &&
      ((context.plusDays !== 0 &&
        context.connectingFlightInfo.plusDays === 0) || // display connecting flight departure message if the current flight is arriving next day and the next connecting flight departs the day the current flight arrives
        context.connectingFlightInfo.plusDays !== 0) ? ( // or if the connecting flight is +1 or more days from the current flight's arrival
        <div className={cn("connecting-flight")}>
          {context.connectingFlightInfo.plusDays > 1 ? (
            // Flight departs multiple days in the future
            <Trans
              i18nKey={"connectingFlightDifferentDay.flightDepartsPlusDays"}
              values={{
                plusDays: context.connectingFlightInfo.plusDays,
                departureDate:
                  context.connectingFlightInfo.formattedDepartureDate,
              }}
              components={{ 1: <span className={cn("notification-date")} /> }}
            />
          ) : context.connectingFlightInfo.plusDays > 0 ? (
            // Flight departs multiple days after the departure date
            <Trans
              i18nKey={"connectingFlightDifferentDay.flightDepartsNextDay"}
              values={{
                departureDate:
                  context.connectingFlightInfo.formattedDepartureDate,
              }}
              components={{ 1: <span className={cn("notification-date")} /> }}
            />
          ) : (
            // The flight departs on current or at a previous date, message should only show the arrival date
            <Trans
              i18nKey={"connectingFlightDifferentDay.flightDepartsOnDate"}
              values={{
                departureDate:
                  context.connectingFlightInfo.formattedDepartureDate,
              }}
              components={{ 1: <span className={cn("notification-date")} /> }}
            />
          )}
        </div>
      ) : undefined}
      {children}
    </div>
  );
};

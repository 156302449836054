import { useCallback } from "react";
import styles from "./styles.module.scss";

interface SwitchProps {
  checked: boolean;
  onClick?: (e?: Event) => void;
}

const Switch = ({ checked, onClick }: SwitchProps) => {
  const onClickHandler = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  return (
    <label className={styles.Switch}>
      <input
        type="checkbox"
        checked={checked}
        defaultChecked={checked}
        onClick={onClickHandler}
      />
      <span className="slider"></span>
    </label>
  );
};

export default Switch;

import { uniquePhoneCodes } from "@hopper-b2b/ui";
import { Select, SelectItem } from "@components/ui";
import { PlatformComponentProps } from "@b2bportal/core-types";
import { IContactInfo } from "@b2bportal/core-types";
import { ControllerRenderProps } from "react-hook-form";

const countryCodeOptions = uniquePhoneCodes.map((countryCode) => ({
  key: countryCode,
  value: `+${countryCode}`,
}));

export interface CountryCodeProps
  extends PlatformComponentProps,
    ControllerRenderProps<IContactInfo, "countryCode"> {
  defaultValue?: string;
  onValueChange?: (value: string) => void;
}

export const CountryCode = (props: CountryCodeProps) => {
  const { className, defaultValue, onValueChange, ...field } = props;
  return (
    <Select
      {...field}
      className={className}
      onValueChange={onValueChange}
      defaultValue={defaultValue ?? field.value}
    >
      {countryCodeOptions.map((item, index) => {
        return (
          <SelectItem
            key={item.key || `${item.value}-${index}`}
            value={item.value}
          >
            {item.value}
          </SelectItem>
        );
      })}
    </Select>
  );
};

import {
  AlertKeyEnum,
  FlightKey,
  RegionType,
  ShopFilter,
} from "@b2bportal/air-price-watch-api";
import {
  getDepartureDate,
  getReturnDate,
  getOrigin,
  getDestination,
  allFiltersSelector,
  getFlightsData,
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  FlightState,
} from "@b2bportal/core-flights";
import { PassengerTypes, Passengers } from "@hopper-b2b/types";
import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import priceWatchApi from "./api";
import { isEqual } from "lodash-es";

type PassengersForWatchRequest = {
  [P in keyof Passengers]: Passengers[P] extends number ? number : never;
};

export const passengerCountSelector = createSelector(
  getAdultsCount,
  getChildrenCount,
  getInfantsInSeatCount,
  getInfantsOnLapCount,
  (
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount
  ): PassengersForWatchRequest => {
    const passengerObj: Partial<Passengers> = {};
    if (adultsCount > 0) passengerObj[PassengerTypes.Adult] = adultsCount;
    if (infantsInSeatCount > 0)
      passengerObj[PassengerTypes.InfantInSeat] = infantsInSeatCount;
    if (infantsOnLapCount > 0)
      passengerObj[PassengerTypes.InfantInLap] = infantsOnLapCount;
    if (childrenCount > 0) passengerObj[PassengerTypes.Child] = childrenCount;
    return passengerObj as PassengersForWatchRequest;
  }
);

export const alertKeySelector: (state: FlightState) => FlightKey | null =
  createSelector(
    allFiltersSelector,
    getFlightsData,
    getDepartureDate,
    getReturnDate,
    getOrigin,
    getDestination,
    (
      _,
      flights,
      departureDate,
      returnDate,
      origin,
      destination
    ): FlightKey | null => {
      if (!flights) {
        return null;
      }

      const summary = {
        originCode: "",
        destinationCode: "",
        departureTime: "",
        returningDepartureTime: "",
      };

      if (flights && Object.values(flights?.trips)[0]) {
        //Flight shop v2
        const flight = Object.values(flights?.trips)[0];

        const departureSlice = flights.slices[flight.outbound];
        const returnSlice = flight.return
          ? flights.slices[flight.return]
          : null;
        summary.originCode = departureSlice.origin;
        summary.destinationCode = departureSlice.destination;
        summary.departureTime = departureDate
          ? dayjs(departureDate).format("YYYY-MM-DD")
          : dayjs(departureSlice.departure).format("YYYY-MM-DD");
        if (returnDate) {
          summary.returningDepartureTime =
            dayjs(returnDate).format("YYYY-MM-DD") ||
            (returnSlice?.departure
              ? dayjs(returnSlice.departure).format("YYYY-MM-DD")
              : "");
        }
      }

      // TODO Check if we want this.
      // const fareclassOptionFilter = shopFilters.fareclassOptionFilter;
      // const stopsOption = shopFilters.stopsOption;
      // let keyFilter: ShopFilter = ShopFilter.NoFilter;
      // const filterOutBasicFares =
      //   !fareclassOptionFilter.basic &&
      //   fareclassOptionFilter.luxury &&
      //   fareclassOptionFilter.enhanced &&
      //   fareclassOptionFilter.premium &&
      //   fareclassOptionFilter.standard;

      // if (filterOutBasicFares) {
      //   keyFilter =
      //     stopsOption === SliceStopCountFilter.NONE
      //       ? ShopFilter.NonStopNoLCC
      //       : ShopFilter.NoLCC;
      // } else if (stopsOption === SliceStopCountFilter.NONE) {
      //   keyFilter = ShopFilter.NonStop;
      // }
      return {
        AlertKey: AlertKeyEnum.FlightKey,
        value: {
          filter: ShopFilter.NoFilter, // keyFilter,
          origin: origin
            ? origin.id.code
            : {
                code: summary.originCode,
                regionType: RegionType.airport,
              },
          destination: destination
            ? destination.id.code
            : {
                code: summary.destinationCode,
                regionType: RegionType.airport,
              },
          departureDate: departureDate
            ? dayjs(departureDate).format("YYYY-MM-DD")
            : dayjs(summary.departureTime).format("YYYY-MM-DD"),
          ...(returnDate && {
            returnDate:
              dayjs(returnDate).format("YYYY-MM-DD") ||
              dayjs(summary.returningDepartureTime).format("YYYY-MM-DD"),
          }),
        },
      };
    }
  );

export const watchesResultSelector =
  priceWatchApi.endpoints.getWatches.select();

export const watchesSelector = createSelector(
  watchesResultSelector,
  (result) => result.data
);

export const isWatchingSelector = createSelector(
  watchesSelector,
  alertKeySelector,
  (watches, currentKey): boolean => {
    if (!Array.isArray(watches?.watches)) {
      return false;
    }

    return (
      watches.watches.find((watch) => isEqual(watch.key, currentKey)) != null
    );
  }
);

import { combineReducers } from "@reduxjs/toolkit";
import carsAvailabilityReducer from "../features/availability/store/slice";
import type { CarsAvailabilityState } from "../features/availability/store";
import { createCarUrlMiddleware } from "./urlMiddleWare";
import type { CarShopState } from "../features/shop/store";
import carsShopReducer from "../features/shop/store/slice";
import { carsPostBookingApi } from "../features/postbooking/store/api";

export interface CarsState {
  cars: {
    carsAvailability: CarsAvailabilityState;
    carsShop: CarShopState;
  };
}

export const carsRootReducer = combineReducers({
  carsAvailability: carsAvailabilityReducer,
  carsShop: carsShopReducer,
});

export const carReducers = {
  cars: carsRootReducer,
  [carsPostBookingApi.reducerPath]: carsPostBookingApi.reducer,
};

export const carMiddlewares = [
  carsPostBookingApi.middleware,
  createCarUrlMiddleware(),
];

export { carsPostBookingApi };

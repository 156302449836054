import { CallState } from "@b2bportal/core-types";
import { HotelsShopState } from "./types";
import { GuestsSelection } from "@b2bportal/lodging-api";

export const initialShopGuests: GuestsSelection = {
  adults: 2,
  children: [],
};

export const initialHotelShopState: HotelsShopState = {
  shopCallState: CallState.NotCalled,
  availabilityCallState: CallState.NotCalled,
};

import { default as dayjs, Dayjs } from "dayjs";
import { HOTEL_URL_PARAM_KEYS } from "../queryParams";
import {
  DateRange,
  GuestsSelection,
  HotelStarRating,
  Amenity,
} from "@b2bportal/lodging-api";
import { HotelSortOptionEnum, MapCoordinates } from "@b2bportal/core-types";
import { toHotelStarRating } from "../availability";

export const URL_DATE_FORMAT = "YYYY-MM-DD" as const;

type BuildSearchParamsArgs = {
  dateRange: DateRange;
  guests: GuestsSelection;
};

function defined<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const buildSearchParams = ({
  dateRange,
  guests,
}: BuildSearchParamsArgs) => {
  const searchParams = new URLSearchParams(
    [
      [HOTEL_URL_PARAM_KEYS.FROM_DATE, formatDateForUrl(dateRange?.from)],
      [HOTEL_URL_PARAM_KEYS.UNTIL_DATE, formatDateForUrl(dateRange?.until)],
      guests?.adults
        ? [HOTEL_URL_PARAM_KEYS.ADULTS_COUNT, `${guests.adults}`]
        : null,
      guests?.children?.length
        ? [HOTEL_URL_PARAM_KEYS.CHILDREN_COUNT, `${guests.children.length}`]
        : null,
      ...(guests?.children?.length
        ? guests.children.map((age) => [
            HOTEL_URL_PARAM_KEYS.CHILDREN_AGES,
            `${age}`,
          ])
        : []),
    ].filter(defined)
  );
  return searchParams;
};

export const formatDateForUrl = (
  dateString?: string | Dayjs | null
): (string & WithFormat<typeof URL_DATE_FORMAT>) | "" =>
  dateString
    ? (dayjs(dateString).format(URL_DATE_FORMAT) as string &
        WithFormat<typeof URL_DATE_FORMAT>)
    : "";

export const unifyChildrenAges = (
  childrenAges: number[],
  childrenCount: number
): number[] => {
  return childrenCount > 0
    ? new Array(childrenCount)
        .fill(1)
        .map((_, index) =>
          Number.isInteger(childrenAges[index]) && childrenAges[index] >= 0
            ? childrenAges[index]
            : 1
        )
    : [];
};

export interface WithFormat<TFormat extends string> {
  __format: TFormat;
}

function initPriceRange(urlMin: number, urlMax: number) {
  const min = isNaN(urlMin) ? 0 : urlMin;
  const max = isNaN(urlMax) || urlMax === 0 ? Number.MAX_SAFE_INTEGER : urlMax;
  return {
    min,
    max,
    lowest: 0,
    highest: Number.MAX_SAFE_INTEGER,
  };
}

export enum ViewOption {
  LIST = "list",
  MAP = "map",
}

export function isValidSort(sort: unknown): sort is HotelSortOptionEnum {
  return Object.values(HotelSortOptionEnum).includes(
    sort as HotelSortOptionEnum
  );
}

export function isValidView(sort: unknown): sort is ViewOption {
  return Object.values(ViewOption).includes(sort as ViewOption);
}

export type HotelSearchQueryParamValues = {
  adults: number;
  children: number[];
  rooms: number;
  fromDate: (string & WithFormat<typeof URL_DATE_FORMAT>) | "";
  untilDate: (string & WithFormat<typeof URL_DATE_FORMAT>) | "";
  map:
    | {
        centroid: MapCoordinates | undefined;
        zoom: number;
      }
    | undefined;
  lodgingIds: string[];
  filters: {
    starRating: HotelStarRating[];
    userRating: number;
    amenities: Amenity[];
    filterAmenitiesAnd: boolean;
    freeCancellation: boolean;
    priceRange: { min: number; max: number; lowest: number; highest: number };
  };
  sortBy: HotelSortOptionEnum;
  view: ViewOption;
};

export const parseLodgingParams = (
  searchParams: URLSearchParams
): HotelSearchQueryParamValues => {
  const count =
    Number(searchParams.get(HOTEL_URL_PARAM_KEYS.CHILDREN_COUNT)) || 0;
  const ages = searchParams
    .getAll(HOTEL_URL_PARAM_KEYS.CHILDREN_AGES)
    .map(Number);
  const children = unifyChildrenAges(ages, count);
  const latlng =
    searchParams
      .get(HOTEL_URL_PARAM_KEYS.LAT_LNG)
      ?.split(",")
      .map(Number)
      .filter((_) => !Number.isNaN(_)) || [];
  const zoom = Number(searchParams.get(HOTEL_URL_PARAM_KEYS.ZOOM)) || 13;

  const amenityMap = Object.keys(Amenity).reduce<
    Record<string, keyof typeof Amenity>
  >((acc, key) => {
    acc[Amenity[key as keyof typeof Amenity].toLowerCase()] =
      key as keyof typeof Amenity;
    return acc;
  }, {});

  const filters = {
    starRating:
      searchParams
        .get(HOTEL_URL_PARAM_KEYS.STAR_RATING)
        ?.split(",")
        .reduce<HotelStarRating[]>((acc, r) => {
          const rating = isNaN(Number(r)) ? null : toHotelStarRating(Number(r));
          if (rating != null) acc.push(rating);
          return acc;
        }, []) ?? [],
    userRating: Number(searchParams.get(HOTEL_URL_PARAM_KEYS.USER_RATING)) || 0,
    amenities:
      searchParams
        .get(HOTEL_URL_PARAM_KEYS.AMENITIES)
        ?.split(",")
        .reduce<(keyof typeof Amenity)[]>((acc, str) => {
          const lowerStr = str.toLowerCase();
          if (amenityMap[lowerStr]) {
            acc.push(amenityMap[lowerStr]);
          }
          return acc;
        }, []) ?? [],
    filterAmenitiesAnd:
      searchParams.get(HOTEL_URL_PARAM_KEYS.FILTER_AMENITIES_AND) === null,
    freeCancellation:
      searchParams.get(HOTEL_URL_PARAM_KEYS.FREE_CANCELLATION) !== null,
    priceRange: initPriceRange(
      Number(searchParams.get(HOTEL_URL_PARAM_KEYS.PRICE_RANGE_MIN)),
      Number(searchParams.get(HOTEL_URL_PARAM_KEYS.PRICE_RANGE_MAX))
    ),
  };

  const sortBy = searchParams.get(HOTEL_URL_PARAM_KEYS.SORT_BY);

  const view = searchParams.get(HOTEL_URL_PARAM_KEYS.VIEW);

  return {
    adults: Number(searchParams.get(HOTEL_URL_PARAM_KEYS.ADULTS_COUNT)) || 2,
    children,
    rooms: Number(searchParams.get(HOTEL_URL_PARAM_KEYS.ROOMS_COUNT)) || 1,
    fromDate: formatDateForUrl(
      searchParams.get(HOTEL_URL_PARAM_KEYS.FROM_DATE)
    ),
    untilDate: formatDateForUrl(
      searchParams.get(HOTEL_URL_PARAM_KEYS.UNTIL_DATE)
    ),
    map: {
      centroid:
        latlng.length > 1
          ? {
              lat: latlng[0] || 0,
              lng: latlng[1] || 0,
            }
          : undefined,
      zoom: zoom,
    },
    lodgingIds:
      searchParams
        .get(HOTEL_URL_PARAM_KEYS.LODGING_IDS)
        ?.split(",")
        .filter(Boolean) || [],
    filters,
    sortBy: isValidSort(sortBy) ? sortBy : HotelSortOptionEnum.Recommended,
    view: isValidView(view) ? (view as ViewOption) : ViewOption.LIST,
  };
};

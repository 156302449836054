import { StateValue } from "xstate";
import {
  AirPriceFreezeMachineContext,
  AirPriceFreezeOfferContext,
} from "./context";

export type AirPriceFreezeStateWithoutValue = {
  context: AirPriceFreezeOfferContext | AirPriceFreezeMachineContext;
};

export type AirPriceFreezeMachineStateWithoutValue = {
  context: AirPriceFreezeMachineContext;
};

export type AirPriceFreezeMachineState = {
  value: StateValue;
  context: AirPriceFreezeMachineContext;
};

export enum AirPriceFreezeStateEnum {
  flightShop = "flightShop",
  contactInformation = "contactInformation",
  priceFreezeOffer = "priceFreezeOffer",
  walletPayment = "walletPayment",
}

import { FieldValues, FieldPath } from "react-hook-form";
import {
  ControlledFormControlWrapper,
  UncontrolledFormControlWrapper,
  UncontrolledFormControlWrapperProps,
  type ControlledFormControlWrapperProps,
} from "@apac/form-ui";

export type FormControlWrapperProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> =
  | ControlledFormControlWrapperProps
  | UncontrolledFormControlWrapperProps<TFieldValues, TName>;

export const FormControlWrapper = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...props
}: FormControlWrapperProps<TFieldValues, TName>): JSX.Element => {
  if ("control" in props) {
    return <UncontrolledFormControlWrapper {...props} />;
  } else {
    return <ControlledFormControlWrapper {...props} />;
  }
};

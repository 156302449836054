import type { MapCoordinates } from "@b2bportal/core-types";
import type { Coordinates as GroundCoordinates } from "@b2bportal/ground-api";

export const toGroundCoordinates = (
  coordinates: MapCoordinates
): GroundCoordinates => {
  return {
    lat: coordinates.lat,
    lon: coordinates.lng,
  };
};

export const toMapCoordinates = (
  coordinates: GroundCoordinates
): MapCoordinates => {
  return {
    lat: coordinates.lat,
    lng: coordinates.lon,
  };
};

// Docs: https://developers.google.com/maps/documentation/maps-static/start
const MAP_PREFIX = "https://maps.googleapis.com/maps/api/staticmap";

export enum MarkerType {
  NONE = "NONE",
  PIN = "PIN",
  IMG = "IMG",
}

interface GoogleStaticMapProps {
  markerType: MarkerType;
  coordinates: { lat: number; lon: number };
  zoom: number;
  size: { width: number; height: number };
  scale: number;
  imgSource?: string;
  markerColor?: string;
}

export const getGoogleStaticMapSource = (props: GoogleStaticMapProps) => {
  const {
    markerType,
    coordinates,
    zoom,
    size,
    scale = 1,
    imgSource = "",
    markerColor = "red",
  } = props;
  const coordinatesString = `${coordinates.lat},${coordinates.lon}`;

  let marker: string;
  if (markerType === MarkerType.PIN) {
    marker = `&markers=color:${markerColor}|${coordinates.lat},${coordinates.lon}`;
  } else if (markerType === MarkerType.IMG && imgSource) {
    // Google Maps marker icon URL needs to satisfy the following conditions
    // 1. Ampersand (&) should be encoded as %26
    // 2. Image dimensions should be less than 60x60 pixels
    const url = new URL(imgSource);
    const queryParams = new URLSearchParams(url.search);
    queryParams.set("w", "60");
    queryParams.set("h", "60");
    queryParams.set("dpr", "1");
    const encodedQueryParams = queryParams.toString().replace(/&/g, "%26");
    const encodedImgSource = `${url.origin}${url.pathname}?${encodedQueryParams}`;
    marker = `&markers=icon:${encodedImgSource}|${coordinates.lat},${coordinates.lon}`;
  } else {
    marker = "";
  }

  return `${MAP_PREFIX}?center=${coordinatesString}&zoom=${zoom}&size=${
    size.width
  }x${size.height}&scale=${scale}${marker}&maptype=roadmap
  &key=${import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY}`;
};

// TODO: move this to core-utilities
const GOOGLE_MAP_URL = "https://www.google.com/maps";
export const getGoogleMapLinkWithCoordinatesAndZoom = (
  coordinates: GroundCoordinates,
  zoom: number
) => {
  return `${GOOGLE_MAP_URL}?q=${coordinates.lat},${coordinates.lon}&z=${zoom}`;
};

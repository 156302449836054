import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  type AvailabilityRequest,
  AvailabilityRequestEnum,
  LodgingCollection,
  LodgingSelectionEnum,
  Platform,
  type ShopResponseAvailable,
  ShopResponseEnum,
} from "@b2bportal/lodging-api";
import type { HotelsState } from "../../../../store";
import { getDeviceType } from "@b2bportal/core-utilities";
import { HotelsShopActions, type ShopSearch } from "..";
import { hotelsApi } from "@overrides/utilities";

export type FetchShopFulfillResponse = {
  shop: ShopResponseAvailable;
  search: ShopSearch;
};

export const fetchHotelsShop = createAsyncThunk<
  FetchShopFulfillResponse,
  { search: ShopSearch },
  { state: HotelsState; rejectValue: { error: string } }
>("hotelsShop/fetchHotelsShop", async ({ search }, thunkAPI) => {
  const { isDesktopAndUp } = getDeviceType();

  const availabilityRequest: AvailabilityRequest = {
    progressiveConfig: {},
    stayDates: search.dateRange,
    guests: search.guests,
    platform: isDesktopAndUp ? Platform.Desktop : Platform.Mobile,
    lodgingSelection: {
      lodgingIds: [search.lodgingId],
      preserveOrder: true,
      LodgingSelection: LodgingSelectionEnum.LodgingIds,
    },
    rooms: {
      numberOfRooms: 1, //TODO: handle multi room
    },
    lodgingCollection: LodgingCollection.NoCollection,
    excludeNonLuxuryLodgings: false,
    AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
  };

  try {
    const availabilityRes = await hotelsApi.apiV0HotelsAvailabilityPost(
      availabilityRequest
    );

    const lodging = availabilityRes.data.lodgings[0];

    if (lodging != null) {
      thunkAPI.dispatch(HotelsShopActions.setLodging(lodging));
    } else {
      return thunkAPI.rejectWithValue({
        error: "Lodging not available",
      });
    }

    const opaqueRequest = lodging.price?.opaqueShopRequest;

    if (opaqueRequest == null) {
      return thunkAPI.rejectWithValue({
        error: "Opaque shop request not available",
      });
    }

    const res = await hotelsApi.apiV0HotelsShopPost({
      opaqueRequest,
    });

    const data = res.data;

    switch (data.ShopResponse) {
      case ShopResponseEnum.Available: {
        return { shop: data, search };
      }
      case ShopResponseEnum.Failure:
        return thunkAPI.rejectWithValue({ error: ShopResponseEnum.Failure });
      case ShopResponseEnum.Unavailable:
        return thunkAPI.rejectWithValue({
          error: ShopResponseEnum.Unavailable,
        });
    }
  } catch (error) {
    // Reject the thunk with an error message
    return thunkAPI.rejectWithValue({ error: String(error) });
  }
});

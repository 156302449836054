import { DateTimeFormatStyle, useI18nContext } from "@hopper-b2b/i18n";
import { FrozenFlightCardComponentProps } from "./FrozenFlightCard";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { FrozenProductFlight, StateEnum } from "@b2bportal/price-freeze-api";
import { PriceDisplay } from "@b2bportal/core-ui";
import { useNavigate } from "react-router-dom-v5-compat";
import { removeTimezone } from "@hopper-b2b/utilities";

const DATE_FORMAT = DateTimeFormatStyle.ShortMonthDayShortWeekday;
const SHORT_TIME_FORMAT = DateTimeFormatStyle.ShortTime;

export const useFrozenFlightCard = (props: FrozenFlightCardComponentProps) => {
  const { priceFreezeVoucher: voucher } = props;
  const navigate = useNavigate();
  const { t, formatDateTime, formatFiatCurrency } = useI18nContext();
  const frozenFlight = voucher.frozenProduct as FrozenProductFlight;
  const origin =
    frozenFlight.tripDetails?.slices[0]?.originCode ??
    frozenFlight.segments[0].origin;
  const departureDate =
    frozenFlight.tripDetails?.slices[0]?.zonedDepartureTime ??
    frozenFlight.segments[0].departureTime;
  const destination =
    frozenFlight.tripDetails?.slices[0]?.destinationCode ??
    frozenFlight.segments[0].destination;
  const returnDate =
    frozenFlight.tripDetails && frozenFlight.tripDetails?.slices?.length > 1
      ? frozenFlight.tripDetails.slices[1].departureTime
      : null;

  const originLabel = frozenFlight.tripDetails?.slices[0]?.originName ?? origin;
  const destinationLabel =
    frozenFlight.tripDetails?.slices[0]?.destinationName ?? destination;

  const travellerPrice = formatFiatCurrency(
    frozenFlight.quotedPrice?.fiat ?? frozenFlight.shoppedPrice.fiat
  );

  const expiration = dayjs(voucher.purchasedAt).add(
    voucher.serviceDetails.serviceDurationDays,
    "day"
  );

  const isActive =
    expiration.isAfter(dayjs()) &&
    (voucher.voucherState.State === StateEnum.Valid ||
      voucher.voucherState.State === StateEnum.Pending);

  const expirationString = useMemo(() => {
    let key;
    if (isActive) {
      key = "priceFreeze.frozenPriceExpirationString";
    } else if (voucher.voucherState.State === StateEnum.Refunded) {
      key = "priceFreeze.frozenPriceRefundedString";
    } else if (voucher.voucherState.State === StateEnum.Exercised) {
      key = "priceFreeze.frozenPriceBookedString";
    } else if (voucher.voucherState.State === StateEnum.Cancelled) {
      key = "priceFreeze.frozenPriceCancelledString";
    } else {
      key = "priceFreeze.frozenPriceExpiredString";
    }

    return t(key, {
      date: formatDateTime(dayjs(expiration).toDate(), DATE_FORMAT),
    });
  }, [isActive, voucher.voucherState.State, t, formatDateTime, expiration]);

  const onBook = useCallback(
    () => navigate(`${encodeURIComponent(voucher.priceFreezeId)}`),
    [navigate, voucher.priceFreezeId]
  );

  const getFrozenFlightCardTitle = useCallback(() => {
    return t("originToDestination", {
      origin: `${originLabel} (${origin})`,
      destination: `${destinationLabel} (${destination})`,
      interpolation: { escapeValue: false },
    });
  }, [destination, destinationLabel, origin, originLabel, t]);

  return useMemo(() => {
    return {
      text: {
        bookNow: t("priceFreeze.priceFreezeBookButton"),
        title: getFrozenFlightCardTitle(),
        departureDate: formatDateTime(
          dayjs(departureDate).toDate(),
          DATE_FORMAT
        ),
        returnDate:
          returnDate !== null && returnDate !== undefined
            ? formatDateTime(dayjs(returnDate).toDate(), DATE_FORMAT)
            : "",
        departureTime: formatDateTime(
          dayjs(removeTimezone(departureDate)).toDate(),
          SHORT_TIME_FORMAT
        ),
        returnTime:
          returnDate !== null && returnDate !== undefined
            ? formatDateTime(
                dayjs(removeTimezone(returnDate)).toDate(),
                SHORT_TIME_FORMAT
              )
            : "",
        tripType:
          returnDate !== null && returnDate !== undefined
            ? t("flightRoundTrip")
            : t("flightOneWay"),
        priceString: t("priceFreeze.frozenPriceString", {
          price: travellerPrice,
        }),
        expirationString,
      },
      elements: {
        activePriceElement: isActive ? (
          <PriceDisplay
            prices={frozenFlight.quotedPrice ?? frozenFlight.shoppedPrice}
          />
        ) : null,
      },
      handlers: {
        onBook: isActive ? onBook : null,
      },
    };
  }, [
    t,
    getFrozenFlightCardTitle,
    formatDateTime,
    departureDate,
    returnDate,
    travellerPrice,
    expirationString,
    isActive,
    frozenFlight.quotedPrice,
    frozenFlight.shoppedPrice,
    onBook,
  ]);
};

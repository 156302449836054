import { IconNameEnum } from "@b2bportal/core-themes";
import type { AppDispatch } from "@b2bportal/core-types";
import {
  FindProductToExerciseResponseEnum,
  type FindProductToExerciseResponse,
  type FrozenProductFlight,
} from "@b2bportal/price-freeze-api";
import { DateTimeFormatStyle, useI18nContext } from "@hopper-b2b/i18n";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom-v5-compat";
import {
  FLIGHTS_PRICE_FREEZE_EXERCISE_PATH_RELATIVE,
  PATH_CONTACT_SUPPORT,
  PATH_TERMS,
} from "../../../../types/utilities";
import {
  fetchPriceFreezeVoucher,
  fetchTripDetails,
  findVoucherLatestTrip,
  getIsPriceFreezeDetailsLoading,
  getPriceFreezeTripDetails,
  getPriceFreezeVoucher,
} from "../../store";

const DATE_FORMAT = DateTimeFormatStyle.ShortMonthDayShortWeekday;

export const useFrozenFlightDetails = () => {
  const params = useParams<{
    priceFreezeId: string;
  }>();
  const navigate = useNavigate();
  const { t, formatDateTime, formatFiatCurrency } = useI18nContext();
  const dispatch = useDispatch<AppDispatch>();
  const voucher = useSelector(getPriceFreezeVoucher);
  const tripDetails = useSelector(getPriceFreezeTripDetails);
  const isLoading = useSelector(getIsPriceFreezeDetailsLoading);

  // Load the flight itinerary
  useEffect(() => {
    const fetchMatchedTrip = async (freezeId: string) => {
      const { payload } = await dispatch(findVoucherLatestTrip(freezeId));
      if (
        (payload as FindProductToExerciseResponse)
          .FindProductToExerciseResponse ===
        FindProductToExerciseResponseEnum.Available
      ) {
        dispatch(
          fetchTripDetails({ value: (payload as any).matchingProduct.tripId })
        );
      }
      // not available will end with matchedLastestTripCallState to CallState.Failed and trigger refund error modal
    };

    if (params.priceFreezeId) {
      dispatch(fetchPriceFreezeVoucher(params.priceFreezeId));
      fetchMatchedTrip(params.priceFreezeId);
    }
  }, [dispatch, params.priceFreezeId]);

  const onContinue = useCallback(() => {
    navigate(
      `/${FLIGHTS_PRICE_FREEZE_EXERCISE_PATH_RELATIVE}/?priceFreezeId=${voucher?.priceFreezeId}`
    );
  }, [navigate, voucher?.priceFreezeId]);

  const onContactSupportClick = useCallback(
    () => navigate(PATH_CONTACT_SUPPORT),
    [navigate]
  );

  // TODO: this is need to be configurable
  const onTermsClick = useCallback(() => window.open(PATH_TERMS, "_blank"), []);

  const text = useMemo(() => {
    let frozenPriceClassName = "";
    let currentPriceClassName = "";
    let iconClassName = "";
    let iconName: IconNameEnum = IconNameEnum.info;
    let numTravelers = 0;
    let frozenPriceBanner = null;
    let currentPriceTexts = null;
    let maxPriceIncreaseText = null;
    let frozenPrice = null;

    if (!!tripDetails?.id && !!voucher) {
      const frozenFlight = voucher.frozenProduct as FrozenProductFlight;
      Object.values(frozenFlight.passengerCount).forEach(
        (count) => (numTravelers += count)
      );
      const fareDetails = tripDetails.fareDetails?.[0];
      const adultPrice = fareDetails.paxPricings
        ? fareDetails.paxPricings.find((p) => p.paxType === "ADT")?.pricing
            .total.fiat
        : undefined;
      const maxPriceIncrease =
        voucher.serviceDetails.perUnitServiceCap.fiat.value ?? 0;
      frozenPrice =
        voucher.frozenProduct.quotedPrice ?? voucher.frozenProduct.shoppedPrice;
      const priceDifference = (adultPrice?.value ?? 0) - frozenPrice.fiat.value;
      const isAtCap = priceDifference > maxPriceIncrease;
      if (priceDifference < 0) {
        frozenPriceClassName = "strikethrough";
        iconClassName = "price-freeze-price-lower";
        iconName = IconNameEnum.PriceFreezePriceGoesDown;
        frozenPriceBanner = t("priceFreeze.priceGoesDown");
      } else if (priceDifference > 0) {
        currentPriceClassName = "strikethrough";
        iconName = IconNameEnum.PriceFreezePriceGoesUp;
        frozenPriceBanner = t("priceFreeze.priceGoesUp");
        const maxPriceCap = voucher.serviceDetails.perUnitServiceCap.fiat.value;
        if (priceDifference > maxPriceCap) {
          maxPriceIncreaseText = {
            label: t("priceFreeze.maxPriceIncreaseLabel"),
            value: t("priceFreeze.maxPriceIncraseValue", {
              price: maxPriceCap - priceDifference,
            }),
          };
        }
      } else {
        iconClassName = "price-freeze-same-price";
        iconName = IconNameEnum.info;
        frozenPriceBanner = t("priceFreeze.priceTheSame");
      }
      if (adultPrice) {
        currentPriceTexts = {
          label: t("priceFreeze.currentPriceLabel"),
          price: t("priceFreeze.currentPrice", {
            price: formatFiatCurrency(adultPrice),
          }),
          description: isAtCap
            ? t("priceFreeze.currentPriceLabelSubtitle", {
                maxPrice: formatFiatCurrency(
                  voucher.serviceDetails.perUnitServiceCap.fiat
                ),
              })
            : null,
          iconClassName,
          iconName,
        };
      }
    }

    return {
      title: t("priceFreeze.frozenPriceTitle"),
      subtitle: t("priceFreeze.frozenPriceSubtitle", {
        destination: tripDetails?.slices[0].originName,
      }),
      priceFreezePeriodTitle: t("priceFreeze.priceFreezePeriodTitle"),
      priceFreezePeriod: voucher
        ? formatDateTime(
            dayjs(voucher?.purchasedAt)
              .add(voucher?.serviceDetails.serviceDurationDays, "day")
              .toDate(),
            DATE_FORMAT
          )
        : "",
      currentPriceTexts,
      maxPriceIncreaseText,
      currentPriceClassName,
      frozenPriceLabel: t("priceFreeze.frozenPriceLabel"),
      frozenPriceValue: voucher
        ? t("priceFreeze.frozenPriceValue", {
            frozenPrice: frozenPrice?.fiat
              ? formatFiatCurrency(frozenPrice.fiat)
              : undefined,
          })
        : "",
      frozenPriceClassName,
      frozenPriceBanner,
      termsAndConditions: t("termsAndConditions"),
      needHelp: t("priceFreeze.needHelp"),
      contactSupport: t("contactSupport"),
      numTravellersLabel: t("priceFreeze.numTravelersLabel"),
      numTravellersValue:
        numTravelers < 2
          ? t("priceFreeze.numTravelerValue")
          : t("priceFreeze.numTravelersValue", {
              numTravelers: numTravelers,
            }),
      continue: t("continue"),
      iconClassName,
    };
  }, [formatDateTime, formatFiatCurrency, t, tripDetails, voucher]);

  return {
    text: text,
    context: { isLoading, slices: tripDetails?.slices },
    handlers: { onContinue, onTermsClick, onContactSupportClick },
  };
};

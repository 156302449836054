import type {
  CarSearchQueryParamValues,
  VehicleAvailabilityWithSupplier,
} from "@apac/components/cars";
import { CallState } from "@b2bportal/core-types";
import type { ShopResponse } from "@b2bportal/ground-api";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialCarShopState } from "./initialState";
import { fetchCarsShop } from "./thunk";

export const carShopSlice = createSlice({
  name: "carShop",
  initialState: initialCarShopState,
  reducers: {
    setVehicle: (
      state,
      action: PayloadAction<VehicleAvailabilityWithSupplier | undefined>
    ) => {
      state.selectedVehicle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchCarsShop
      .addCase(fetchCarsShop.pending, (state) => {
        state.shopCallState = CallState.InProcess;
      })
      .addCase(
        fetchCarsShop.fulfilled,
        (state, action: PayloadAction<ShopResponse>) => {
          state.shopCallState = CallState.Success;
          state.shop = action.payload;
        }
      )
      .addCase(fetchCarsShop.rejected, (state) => {
        state.shopCallState = CallState.Failed;
      });
  },
});

export const CarsShopActions = carShopSlice.actions;

export default carShopSlice.reducer;

import { CheckoutPaymentProperties } from "../checkout/tracking";

export enum LodgingShopTrackingEvents {
  hotel_entry = "hotel_entry",
  hotel_tapped_enter_booking_flow = "hotel_tapped_enter_booking_flow",
  hotel_loaded_room_info_list = "hotel_loaded_room_info_list",
  hotel_tapped_bed_types = "hotel_tapped_bed_types",
  hotel_view_bed_types = "hotel_view_bed_types",
  hotel_tapped_cancellation_policy = "hotel_tapped_cancellation_policy",
  hotel_view_cancellation_policy = "hotel_view_cancellation_policy",
  hotel_viewed_room_in_list = "hotel_viewed_room_in_list",
  hotel_tapped_room_selection_continue = "hotel_tapped_room_selection_continue",
  hotel_loaded_list_page = "hotel_loaded_list_page",
  hotel_viewed_calendar = "hotel_viewed_calendar",
  hotel_viewed_map = "hotel_viewed_map",
  hotel_viewed_search = "hotel_viewed_search",
  hotel_tapped_filter = "hotel_tapped_filter",
  hotel_applied_filters = "hotel_applied_filters",
  hotel_viewed_filters = "hotel_viewed_filters",
  hotel_tapped_sort = "hotel_tapped_sort",
  hotel_applied_sort = "hotel_applied_sort",
  hotel_tapped_redo_search_in_map = "hotel_tapped_redo_search_in_map",
  hotel_viewed_lodging_in_list = "hotel_viewed_lodging_in_list",
  hotel_tapped_lodging_in_list = "hotel_tapped_lodging_in_list",
  hotel_tapped_map_marker = "hotel_tapped_map_marker",
  hotel_swiped_carousel = "hotel_swiped_carousel",
  hotel_viewed_cover = "hotel_viewed_cover",
  hotel_viewed_bed_types = "hotel_viewed_bed_types",
  hotel_viewed_price_change_snackbar = "hotel_viewed_price_change_snackbar",
  hotel_viewed_add_on = "hotel_viewed_add_on",
  hotel_cfar_add_on_choice = "hotel_cfar_add_on_choice",
  hotel_updated_list_page = "hotel_updated_list_page",
  modal_alert = "modal_alert",
  hotel_tapped_guests = "hotel_tapped_guests",
  hotel_tapped_apply_guests = "hotel_tapped_apply_guests",
  tapped_search_suggestion = "tapped_search_suggestion",
  hotel_selected_calendar_date = "hotel_selected_calendar_date",
  hotel_tapped_select_dates = "hotel_tapped_select_dates",
  viewed_offer = "viewed_offer",
  hotel_tapped_map_card = "hotel_tapped_map_card",
  enter_screen = "enter_screen",
  viewed_room_selection_filter = "viewed_room_selection_filter",
  clicked_room_selection_filter = "clicked_room_selection_filter",
}

export enum LodgingCheckoutTrackingEvents {
  hotel_view_contact_info = "hotel_view_contact_info",
  hotel_add_contact_info = "hotel_add_contact_info",
  hotel_view_travelers = "hotel_view_travelers",
  hotel_viewed_add_guest = "hotel_viewed_add_guest",
  hotel_saved_traveler = "hotel_saved_traveler",
  hotel_selected_traveler = "hotel_selected_traveler",
  hotel_add_guest = "hotel_add_guest",
  hotel_edit_guest_information = "hotel_edit_guest_information",
  hotel_choose_traveler = "hotel_choose_traveler",
  hotel_view_payment_method = "hotel_view_payment_method",
  hotel_view_add_payment_method = "hotel_view_add_payment_method",
  hotel_saved_payment_method = "hotel_saved_payment_method",
  hotel_choose_payment_method = "hotel_choose_payment_method",
  hotel_tapped_continue_with_payment = "hotel_tapped_continue_with_payment",
  hotel_review_details = "hotel_review_details",
  hotel_review_details_edit_email = "hotel_review_details_edit_email",
  hotel_review_details_edit_phone = "hotel_review_details_edit_phone",
  hotel_review_details_edit_traveler = "hotel_review_details_edit_traveler",
  hotel_tapped_complete_booking = "hotel_tapped_complete_booking",
  hotel_complete_buy = "hotel_complete_buy",
  modal_alert = "modal_alert",
  tapped_rewards_redemption_choice = "tapped_rewards_redemption_choice",
  deleted_saved_card = "deleted_saved_card",
  hotel_view_rewards_offers_payment = "hotel_view_rewards_offers_payment",
  hotel_tapped_continue_rewards_offers_payment = "hotel_tapped_continue_rewards_offers_payment",
  viewed_loading_state = "viewed_loading_state",
  viewed_hotel_booking_confirmation = "viewed_hotel_booking_confirmation",
}

export interface HotelCfarProperties {
  cfar_premium_percentage?: number;
  cfar_premium_usd?: number;
  cfar_pricing_description?: string;
  cfar_hotel_choice?: 0 | 1;
}

export interface HotelRoomShopProperties extends HotelCfarProperties {
  check_in_date?: string;
  check_out_date?: string;
  city_name?: string;
  country_name?: string;
  daily_owed_now_excluding_taxes_fees_usd?: number;
  daily_owed_now_including_taxes_fees_usd?: number;
  daily_owed_overall_excluding_taxes_fees_usd?: number;
  daily_owed_overall_including_taxes_fees_usd?: number;
  daily_net_rate_excluding_taxes_fees_usd?: number;
  daily_net_rate_including_taxes_fees_usd?: number;
  owed_later_taxes_fees_usd?: number;
  owed_later_excluding_taxes_fees_usd?: number;
  owed_later_including_taxes_fees_usd?: number;
  owed_now_taxes_fees_usd?: number;
  owed_now_excluding_taxes_fees_usd?: number;
  owed_now_including_taxes_fees_usd?: number;
  owed_overall_excluding_taxes_fees_usd?: number;
  owed_overall_including_taxes_fees_usd?: number;
  owed_overall_taxes_fees_usd?: number;
  net_rate_excluding_taxes_fees_usd?: number;
  // TODO: Check if we need this? What is this? Set to lodging_name for now
  im_lodging_name?: string;
  lodging_id?: string;
  lodging_name?: string;
  provider_name?: string;
  provider_rate_category?: string;
  province_name?: string;
  commission_usd?: number;
  daily_revenue_usd?: number;
  revenue_usd?: number;
  lodging_star_rating?: number;
  number_of_photos?: number;
  number_of_videos?: number;
  number_of_reviews?: number;
  amenities_shown?: boolean;
  num_amenities_shown?: number;
  room_media_count?: number;
  breakfast_included_shown?: boolean;
  assigned_at_checkin_only?: boolean;
  pay_now_provider_name?: string;
  payment_schedule_type?: string;
  refundable?: boolean;
  refundable_type?: string;
  room_type_name?: string;
  source_currency?: string;
  user_currency?: string;
  lodging_tripadvisor_rating?: number;

  google_place_description?: string;
  description_language?: string;
  description_provider?: string;
  lodging_type?: string;

  // NOT from /shop trackingPropertiesV2
  advance?: number;
  booking_entry_type?: string;
  selectable_rates_count?: number;
  room_media_shown?: boolean;
  room_index_in_shop?: number;
  selection_property_count?: number;
  room_type_count?: number;
  room_media_owner?: string;
  rate_type_count?: number;
  provider_internal_name?: string;
  markup_percentage?: number;
  hopper_preferred_hotel?: boolean;
  metadata_provider?: string;
  metadata_language?: string;
  lowest_comparable_provider_name?: string;
  total_provider_cost_including_taxes_fees_usd?: number;
  total_markup_usd?: number;
  rate_type?: string;
  rate_provider?: string;
  market_name_full?: string;
  market_name?: string;
  market_id?: string;

  lodging_list_index?: number;
  los?: number;
  promotion_applicable?: boolean;
  promotion_discount_percentage_value?: number;
  promotion_discount_type?: string;
  promotion_discount_usd?: number;
  promotion_id?: string;
  promotion_kind?: string;
  promotion_name?: string;
  promotion_shown?: boolean;
  sort_order?: string;
  adults_count?: number;
  children_count?: number;
  room_count?: number;
}

export interface HotelCheckoutProperties
  extends HotelRoomShopProperties,
    CheckoutPaymentProperties {
  // NOT from /api/v1/purchase/fulfill/poll trackingProperties; NOT being addressed currently
  original_provider_owed_now_currency?: string;
  // NOT from /api/v1/purchase/fulfill/poll trackingProperties
  success?: boolean;
  // NOT from /api/v1/purchase/fulfill/poll trackingProperties
  reservation_id?: string;
  // NOT from /api/v1/purchase/fulfill/poll trackingProperties; NOT being addressed currently
  hotel_bookings_lifetime?: string;
  // NOT from /api/v1/purchase/fulfill/poll trackingProperties; NOT being addressed currently
  booking_advance_days?: number;
  // NOT from /api/v1/purchase/fulfill/poll trackingProperties; NOT being addressed currently
  brand?: string;
  // NOT from /api/v1/purchase/fulfill/poll trackingProperties; NOT being addressed currently
  chain?: string;
  // NOT from /api/v1/purchase/fulfill/poll trackingProperties; NOT being addressed currently
  google_place_type_main?: string;
  // NOT from /api/v1/purchase/fulfill/poll trackingProperties; NOT being addressed currently
  google_place_types?: string[];
  /*
    save_contact_info:
      new = user’s first time adding info
      update = user updated their info
      same = user did not change their info
  */
  save_contact_info?: "new" | "update" | "same";
}

export type HotelTappedEnterBookingFlowProperties = Pick<
  HotelRoomShopProperties,
  | "advance"
  | "booking_entry_type"
  | "check_in_date"
  | "check_out_date"
  | "city_name"
  | "country_name"
  | "daily_owed_now_excluding_taxes_fees_usd"
  | "daily_owed_overall_excluding_taxes_fees_usd"
  | "daily_owed_overall_including_taxes_fees_usd"
  | "google_place_description"
  | "im_lodging_name"
  | "lodging_id"
  | "lodging_name"
  | "market_id"
  | "market_name"
  | "market_name_full"
  | "owed_now_including_taxes_fees_usd"
  | "owed_overall_including_taxes_fees_usd"
  | "provider_name"
  | "provider_rate_category"
  | "province_name"
  | "rate_provider"
  | "rate_type"
  | "revenue_usd"
  | "total_markup_usd"
  | "total_provider_cost_including_taxes_fees_usd"
>;

export type HotelLoadedRoomInfoListProperties = Pick<
  HotelRoomShopProperties,
  | "advance"
  | "check_in_date"
  | "check_out_date"
  | "city_name"
  | "country_name"
  | "daily_owed_now_excluding_taxes_fees_usd"
  | "daily_owed_now_including_taxes_fees_usd"
  | "daily_owed_overall_excluding_taxes_fees_usd"
  | "daily_owed_overall_including_taxes_fees_usd"
  | "description_language"
  | "description_provider"
  | "im_lodging_name"
  | "lodging_id"
  | "lodging_name"
  | "lodging_star_rating"
  | "lodging_tripadvisor_rating"
  | "lodging_type"
  | "lowest_comparable_provider_name"
  | "market_id"
  | "market_name"
  | "market_name_full"
  | "metadata_language"
  | "metadata_provider"
  | "number_of_photos"
  | "number_of_reviews"
  | "number_of_videos"
  | "owed_later_taxes_fees_usd"
  | "owed_now_including_taxes_fees_usd"
  | "owed_overall_including_taxes_fees_usd"
  | "provider_name"
  | "provider_rate_category"
  | "province_name"
  | "rate_provider"
  | "rate_type"
  | "revenue_usd"
  | "total_markup_usd"
  | "total_provider_cost_including_taxes_fees_usd"
>;

export type HotelTappedBedTypesProperties = Pick<
  HotelRoomShopProperties,
  | "advance"
  | "amenities_shown"
  | "num_amenities_shown"
  | "assigned_at_checkin_only"
  | "breakfast_included_shown"
  | "check_in_date"
  | "check_out_date"
  | "city_name"
  | "commission_usd"
  | "country_name"
  | "daily_net_rate_excluding_taxes_fees_usd"
  | "daily_net_rate_including_taxes_fees_usd"
  | "daily_owed_now_excluding_taxes_fees_usd"
  | "daily_owed_now_including_taxes_fees_usd"
  | "daily_owed_overall_excluding_taxes_fees_usd"
  | "daily_owed_overall_including_taxes_fees_usd"
  | "daily_revenue_usd"
  | "description_language"
  | "description_provider"
  | "hopper_preferred_hotel"
  | "im_lodging_name"
  | "lodging_id"
  | "lodging_name"
  | "lodging_star_rating"
  | "lodging_tripadvisor_rating"
  | "lodging_type"
  | "lowest_comparable_provider_name"
  | "market_id"
  | "market_name"
  | "market_name_full"
  | "markup_percentage"
  | "metadata_language"
  | "metadata_provider"
  | "net_rate_excluding_taxes_fees_usd"
  | "number_of_photos"
  | "number_of_reviews"
  | "number_of_videos"
  | "owed_later_excluding_taxes_fees_usd"
  | "owed_later_including_taxes_fees_usd"
  | "owed_later_taxes_fees_usd"
  | "owed_now_excluding_taxes_fees_usd"
  | "owed_now_including_taxes_fees_usd"
  | "owed_now_taxes_fees_usd"
  | "owed_overall_excluding_taxes_fees_usd"
  | "owed_overall_including_taxes_fees_usd"
  | "owed_overall_taxes_fees_usd"
  | "pay_now_provider_name"
  | "payment_schedule_type"
  | "provider_internal_name"
  | "provider_name"
  | "provider_rate_category"
  | "province_name"
  | "rate_provider"
  | "rate_type"
  | "rate_type_count"
  | "refundable"
  | "refundable_type"
  | "revenue_usd"
  | "room_index_in_shop"
  | "room_media_count"
  | "room_media_owner"
  | "room_media_shown"
  | "room_type_count"
  | "room_type_name"
  | "source_currency"
  | "total_markup_usd"
  | "total_provider_cost_including_taxes_fees_usd"
  | "user_currency"
>;

export type HotelTappedRoomSelectionContinueProperties = Pick<
  HotelRoomShopProperties,
  | "advance"
  | "check_in_date"
  | "check_out_date"
  | "lodging_id"
  | "lodging_name"
  | "refundable"
  | "refundable_type"
  | "room_index_in_shop"
  | "room_media_owner"
  | "room_media_shown"
  | "room_type_count"
  | "room_type_name"
  | "selectable_rates_count"
  | "selection_property_count"
>;

export type HotelAddContactInfoProperties = Pick<
  HotelCheckoutProperties,
  "lodging_id" | "lodging_name" | "save_contact_info"
>;

export type HotelSelectedTravelerProperties = Pick<
  HotelCheckoutProperties,
  "lodging_id" | "lodging_name"
>;

export type HotelTappedContinueWithPaymentProperties = Pick<
  HotelCheckoutProperties,
  | "lodging_id"
  | "lodging_name"
  | "payment_method"
  | "payment_method_type"
  | "amount"
  | "usd_amount"
>;

export type HotelViewedAddOnProperties = Pick<
  HotelRoomShopProperties,
  "cfar_premium_percentage" | "cfar_premium_usd" | "cfar_pricing_description"
>;

export type HotelCfarAddOnChoiceProperties = Pick<
  HotelRoomShopProperties,
  | "cfar_hotel_choice"
  | "cfar_premium_percentage"
  | "cfar_premium_usd"
  | "cfar_pricing_description"
>;

export type HotelReviewDetailsProperties = Pick<
  HotelCheckoutProperties,
  | "advance"
  | "amenities_shown"
  | "num_amenities_shown"
  | "cfar_hotel_choice"
  | "cfar_premium_percentage"
  | "cfar_premium_usd"
  | "cfar_pricing_description"
  | "check_in_date"
  | "check_out_date"
  | "city_name"
  | "commission_usd"
  | "country_name"
  | "daily_net_rate_excluding_taxes_fees_usd"
  | "daily_net_rate_including_taxes_fees_usd"
  | "daily_owed_now_excluding_taxes_fees_usd"
  | "daily_owed_now_including_taxes_fees_usd"
  | "daily_owed_overall_excluding_taxes_fees_usd"
  | "daily_owed_overall_including_taxes_fees_usd"
  | "daily_revenue_usd"
  | "description_language"
  | "description_provider"
  | "hopper_preferred_hotel"
  | "im_lodging_name"
  | "lodging_id"
  | "lodging_name"
  | "lodging_star_rating"
  | "lodging_tripadvisor_rating"
  | "lodging_type"
  | "lowest_comparable_provider_name"
  | "market_id"
  | "market_name"
  | "market_name_full"
  | "markup_percentage"
  | "metadata_language"
  | "metadata_provider"
  | "net_rate_excluding_taxes_fees_usd"
  | "number_of_photos"
  | "number_of_reviews"
  | "number_of_videos"
  | "owed_later_excluding_taxes_fees_usd"
  | "owed_later_including_taxes_fees_usd"
  | "owed_later_taxes_fees_usd"
  | "owed_now_excluding_taxes_fees_usd"
  | "owed_now_including_taxes_fees_usd"
  | "owed_now_taxes_fees_usd"
  | "owed_overall_excluding_taxes_fees_usd"
  | "owed_overall_including_taxes_fees_usd"
  | "owed_overall_taxes_fees_usd"
  | "pay_now_provider_name"
  | "payment_schedule_type"
  | "provider_internal_name"
  | "provider_name"
  | "provider_rate_category"
  | "province_name"
  | "rate_provider"
  | "rate_type"
  | "rate_type_count"
  | "refundable"
  | "refundable_type"
  | "revenue_usd"
  | "room_index_in_shop"
  | "room_media_count"
  | "room_media_owner"
  | "room_media_shown"
  | "room_type_count"
  | "room_type_name"
  | "source_currency"
  | "total_markup_usd"
  | "total_provider_cost_including_taxes_fees_usd"
  | "user_currency"
>;

export type HotelCompleteBuyProperties = Pick<
  HotelCheckoutProperties,
  | "advance"
  | "amenities_shown"
  | "num_amenities_shown"
  | "booking_advance_days"
  | "brand"
  | "breakfast_included_shown"
  | "cfar_hotel_choice"
  | "cfar_premium_percentage"
  | "cfar_premium_usd"
  | "cfar_pricing_description"
  | "chain"
  | "check_in_date"
  | "check_out_date"
  | "city_name"
  | "commission_usd"
  | "country_name"
  | "daily_net_rate_excluding_taxes_fees_usd"
  | "daily_net_rate_including_taxes_fees_usd"
  | "daily_owed_now_excluding_taxes_fees_usd"
  | "daily_owed_now_including_taxes_fees_usd"
  | "daily_owed_overall_excluding_taxes_fees_usd"
  | "daily_owed_overall_including_taxes_fees_usd"
  | "daily_revenue_usd"
  | "description_language"
  | "description_provider"
  | "google_place_description"
  | "google_place_type_main"
  | "google_place_types"
  | "hopper_preferred_hotel"
  | "hotel_bookings_lifetime"
  | "im_lodging_name"
  | "lodging_id"
  | "lodging_name"
  | "lodging_star_rating"
  | "lodging_tripadvisor_rating"
  | "lodging_type"
  | "los"
  | "lowest_comparable_provider_name"
  | "market_id"
  | "market_name"
  | "market_name_full"
  | "markup_percentage"
  | "metadata_language"
  | "metadata_provider"
  | "net_rate_excluding_taxes_fees_usd"
  | "number_of_photos"
  | "number_of_reviews"
  | "number_of_videos"
  | "original_provider_owed_now_currency"
  | "owed_later_excluding_taxes_fees_usd"
  | "owed_later_including_taxes_fees_usd"
  | "owed_later_taxes_fees_usd"
  | "owed_now_excluding_taxes_fees_usd"
  | "owed_now_including_taxes_fees_usd"
  | "owed_now_taxes_fees_usd"
  | "owed_overall_excluding_taxes_fees_usd"
  | "owed_overall_including_taxes_fees_usd"
  | "owed_overall_taxes_fees_usd"
  | "pay_now_provider_name"
  | "payment_schedule_type"
  | "provider_name"
  | "provider_rate_category"
  | "province_name"
  | "rate_provider"
  | "rate_type"
  | "rate_type_count"
  | "refundable"
  | "refundable_type"
  | "reservation_id"
  | "revenue_usd"
  | "room_index_in_shop"
  | "room_media_count"
  | "room_media_owner"
  | "room_media_shown"
  | "room_type_count"
  | "room_type_name"
  | "source_currency"
  | "success"
  | "total_markup_usd"
  | "total_provider_cost_including_taxes_fees_usd"
  | "user_currency"
  | "payment_method_type"
>;

export type HotelViewPaymentMethodProperties = Pick<
  HotelCheckoutProperties,
  | "other_payment_methods"
  | "preselected_payment_method"
  | "saved_payment_methods"
  | "total_payment_methods"
>;

export type HotelChoosePaymentMethodProperties = Pick<
  HotelCheckoutProperties,
  "payment_method_type" | "amount" | "usd_amount"
>;

export enum HotelModalAlertType {
  AUTHENTICATION_ERROR = "authentication_error",
  MINIMUM_AGE_NOT_MET = "minimum_age_not_met",
  HOTEL_PRICE_INCREASE = "hotel_price_increase",
  HOTEL_PRICE_DECREASE = "hotel_price_decrease",
  HOTEL_WITH_ADD_ON_PRICE_DIFFERENCE = "hotel_with_add_on_price_difference",
  HOTEL_AVAILABILITY_ERROR = "hotel_availability_error",
  SCHEDULE_PRICE_QUOTE_FAILED = "schedule_price_quote_failed",
  POLL_PRICE_QUOTE_FAILED = "poll_price_quote_failed",
  PAYMENT_VERIFICATION_FAILED = "payment_verification_failed",
  SCHEDULE_BOOK_FAILED = "schedule_book_failed",
  CONFIRMATION_DETAILS_FAILED = "confirmation_details_failed",
  OTHER = "other",
}

export interface HotelModalAlertProperties {
  screen?: "hotels_availability" | "hotels_checkout";
  step?:
    | "availability"
    | "save_travelers"
    | "save_payment"
    | "select_travelers"
    | "select_payment"
    | "confirm_and_book"
    | "quote";
  modal_type?: HotelModalAlertType;
  price_change?: number;
  provider_name?: string;
}
export type HotelAvailabilityTrackingProperties = Pick<
  HotelRoomShopProperties,
  | "advance"
  | "check_in_date"
  | "check_out_date"
  | "city_name"
  | "country_name"
  | "los"
  | "sort_order"
  | "adults_count"
  | "children_count"
>;

export type HotelShopTrackingProperties = Pick<
  HotelRoomShopProperties,
  | "city_name"
  | "country_name"
  | "daily_owed_overall_excluding_taxes_fees_usd"
  | "daily_owed_overall_including_taxes_fees_usd"
  | "description_provider"
  | "hopper_preferred_hotel"
  | "lodging_id"
  | "lodging_list_index"
  | "lodging_name"
  | "lodging_type"
  | "market_id"
  | "market_name"
  | "metadata_language"
  | "metadata_provider"
  | "number_of_photos"
  | "owed_now_including_taxes_fees_usd"
  | "owed_overall_including_taxes_fees_usd"
  | "promotion_applicable"
  | "promotion_discount_percentage_value"
  | "promotion_discount_type"
  | "promotion_discount_usd"
  | "promotion_id"
  | "promotion_kind"
  | "promotion_name"
  | "provider_name"
  | "provider_rate_category"
  | "province_name"
  | "revenue_usd"
  | "room_media_shown"
  | "room_media_count"
  | "room_index_in_shop"
  | "room_type_count"
  | "refundable"
  | "refundable_type"
  | "selectable_rates_count"
  | "total_provider_cost_including_taxes_fees_usd"
>;

import { DropoffType } from "@apac/components/cars";
import {
  type Suggestion,
  type AvailabilityRequest,
  type AvailabilityResponse,
  CodeContextEnum,
  type CodeContext,
  GroundSelectionEnum,
  type BoundingBox,
} from "@b2bportal/ground-api";
import { carsApi } from "../../../../../utilities";

interface AvailabilityQuery {
  pickupDateTime: string;
  dropoffDateTime: string;
  driverAge: number;
}

export interface AvailabilityByPlaceQuery extends AvailabilityQuery {
  pickupLocation: Suggestion;
  dropoffLocation: Suggestion;
}

export interface AvailabilityByBoundingBoxQuery extends AvailabilityQuery {
  pickupLocation: BoundingBox;
  dropoffType: DropoffType;
  // from initial place query
  dropoffLocation: Suggestion;
}

export type AvailabilityApiCall =
  | { GroundSelection: "Place"; request: AvailabilityByPlaceQuery }
  | { GroundSelection: "Location"; request: AvailabilityByBoundingBoxQuery };

export const fetchAvailabilityByPlaceAPI = async (
  query: AvailabilityByPlaceQuery
): Promise<AvailabilityResponse> => {
  const body: AvailabilityRequest = {
    pickupTime: query.pickupDateTime,
    pickupSelection: getCodeContextFromPlace(query.pickupLocation),
    dropOffTime: query.dropoffDateTime,
    dropOffSelection: getCodeContextFromPlace(query.dropoffLocation),
    driverAge: query.driverAge,
  };
  return fetchAvailability(body);
};

export const fetchAvailabilityByBoundingBox = async (
  query: AvailabilityByBoundingBoxQuery
): Promise<AvailabilityResponse> => {
  const boundingBox = {
    CodeContext: CodeContextEnum.GroundBoundingBox,
    northEast: query.pickupLocation.northEast,
    southWest: query.pickupLocation.southWest,
  };

  const body: AvailabilityRequest = {
    pickupTime: query.pickupDateTime,
    pickupSelection: boundingBox,
    dropOffTime: query.dropoffDateTime,
    dropOffSelection:
      query.dropoffType === DropoffType.OneWay
        ? getCodeContextFromPlace(query.dropoffLocation)
        : boundingBox,
    driverAge: query.driverAge,
  };
  return fetchAvailability(body);
};

export const fetchAvailability = async (req: AvailabilityRequest) => {
  try {
    const response = await carsApi.apiV1GroundAvailabilityPost(req);

    return response.data;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);

    throw new Error(`Failed to fetch availability: ${errorMessage}`);
  }
};

function getCodeContextFromPlace(place: Suggestion): CodeContext {
  if (place.id.Id === "Flight") {
    return {
      CodeContext: CodeContextEnum.Iata,
      code: place.id.code.code,
    };
  }
  if (
    place.id.Id === "Grounds" &&
    place.id.groundSelection.GroundSelection === GroundSelectionEnum.Place
  ) {
    return {
      CodeContext: CodeContextEnum.GooglePlace,
      code: place.id.groundSelection.placeId,
    };
  }
  throw new Error("Unsupported place type");
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import type { HotelsState } from "../../../../store";
import { fetchAvailabilityNextPage } from "./api/fetchAvailability";
import { More } from "@b2bportal/lodging-api";
import { SearchDetails } from "@b2bportal/core-types";

export const fetchHotelsNextPage = createAsyncThunk<
  More & { searchId: string },
  { nextPageToken: string; searchId: string },
  { state: HotelsState; rejectValue: { error: string } }
>(
  "hotelsAvailability/fetchHotelsNextPage",
  async ({ nextPageToken, searchId }, thunkAPI) => {
    try {
      const results = await fetchAvailabilityNextPage(nextPageToken);
      return { ...results, searchId };
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : String(e);
      return thunkAPI.rejectWithValue({ error: errorMessage });
    }
  }
);

import {
  priceFreezeApi,
  PriceFreezeShopRequest,
} from "@b2bportal/price-freeze-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPriceFreezeOffers = createAsyncThunk(
  "flightsPriceFreezePurchase/fetchPriceFreezeOffers",
  async (request: PriceFreezeShopRequest, thunkAPI) => {
    try {
      const res = await priceFreezeApi(axios).apiV3PricefreezeOfferShopPost(
        request
      );

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

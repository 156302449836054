import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialHotelShopState } from "./initialState";
import { fetchHotelsShop, type FetchShopFulfillResponse } from "./thunk";
import { CallState } from "@b2bportal/core-types";
import type { Lodging, RoomProduct } from "@b2bportal/lodging-api";
import type { ShopSearch } from "./types";

export const hotelShopSlice = createSlice({
  name: "hotelShop",
  initialState: initialHotelShopState,
  reducers: {
    setShopSearch: (state, action: PayloadAction<ShopSearch>) => {
      state.shopSearch = action.payload;
    },

    setLodging: (state, action: PayloadAction<Lodging>) => {
      state.lodging = action.payload;
      state.availabilityCallState = CallState.Success;
    },
    setSelectedRoomProduct: (state, action: PayloadAction<RoomProduct>) => {
      state.selectedRoomProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchHotelsShop
      .addCase(fetchHotelsShop.pending, (state) => {
        state.shopCallState = CallState.InProcess;
        state.availabilityCallState = CallState.InProcess;
      })
      .addCase(
        fetchHotelsShop.fulfilled,
        (state, action: PayloadAction<FetchShopFulfillResponse>) => {
          state.shopCallState = CallState.Success;
          state.shop = action.payload.shop;
          state.shopSearch = action.payload.search;
        }
      )
      .addCase(fetchHotelsShop.rejected, (state) => {
        state.shopCallState = CallState.Failed;
        state.availabilityCallState = CallState.Failed;
      });
  },
});

export const HotelsShopActions = hotelShopSlice.actions;

export default hotelShopSlice.reducer;

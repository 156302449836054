import {
  FuelType,
  type Vehicle,
  type VehicleAvailability,
  VehicleCategory,
  VehicleType,
} from "@b2bportal/ground-api";
import { type CarComparator, CarSortOptionEnum } from "../types";

export const sortByPrice = (a: VehicleAvailability, b: VehicleAvailability) => {
  const aPrice = a.totalCharge.fiat.value;
  const bPrice = b.totalCharge.fiat.value;
  // If a Car does not have a price, it's unavailable and should be pushed to the end
  if (aPrice == null && bPrice == null) {
    return 0;
  } else if (aPrice == null) {
    return 1;
  } else if (bPrice == null) {
    return -1;
  } else {
    return aPrice - bPrice;
  }
};

export const sortByDistance = (
  a: VehicleAvailability,
  b: VehicleAvailability
) => {
  const aDistance = a.pickUp.metersToRequested;
  const bDistance = b.pickUp.metersToRequested;

  if (aDistance == null && bDistance == null) {
    return 0;
  } else if (aDistance == null) {
    return 1;
  } else if (bDistance == null) {
    return -1;
  } else {
    return aDistance - bDistance;
  }
};

/**
 * A map of sort options to their respective comparators.
 *
 * A `null` value here means the Cars will not be re-ordered, and they will be shown in the order
 * in which they are received from the API.
 *
 * All options should be set, but we have to use a PartialRecord to allow for extending the
 * CarSortOptionEnum. If a new option is added by an override, this map should also be overridden
 * to support that option.
 */
export const CarListSortComparatorsMap: Record<
  CarSortOptionEnum,
  CarComparator | null
> = {
  [CarSortOptionEnum.Recommended]: null,
  [CarSortOptionEnum.Price]: sortByPrice,
  [CarSortOptionEnum.Distance]: sortByDistance,
};

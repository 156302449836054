import { useModuleBEM, useUiStyles } from "@b2bportal/core-themes";
import { CoreUiComponents } from "@b2bportal/core-types";
import defaultStyles from "./LazyLoading.module.scss";
import clsx from "clsx";
import { Loading } from "@components/ui";

export const LazyLoading = ({ className }: { className?: string }) => {
  const styles = useUiStyles(CoreUiComponents.Loading, defaultStyles);
  const [block] = useModuleBEM(styles, CoreUiComponents.LazyLoading);
  return (
    <div className={clsx(block, className)}>
      <Loading />
    </div>
  );
};

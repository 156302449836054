import { WalletPaymentContext } from "@/modules/checkout/states";
import { RewardsPaymentContext } from "@/modules/checkout/states/Rewards";
import { IContactInfo } from "@b2bportal/core-types";
import { CardPaymentContext, CartContext } from "@checkout/states";
import { AirPriceFreezeStateEnum } from "./types";
import { ParentState } from "@checkout/types";
import { PassengerCount, PriceFreezeOffer } from "@b2bportal/price-freeze-api";

export interface FlightShopContext {
  fareId: string;
  passengerCount: PassengerCount;
  tripId: string;
}

export interface AirPriceFreezeOfferContext {
  flightsPriceFreezeOffers: PriceFreezeOffer[];
  selectedOfferId?: string;
}

export const initialAirPriceFreezeOfferContext: AirPriceFreezeOfferContext = {
  flightsPriceFreezeOffers: [],
};

export interface AirPriceFreezeMachineContext extends CartContext {
  // key name to match each context's selector
  [AirPriceFreezeStateEnum.walletPayment]: WalletPaymentContext;
  [ParentState.awardsPayment]: RewardsPaymentContext;
  [ParentState.cardPayment]: CardPaymentContext;

  [AirPriceFreezeStateEnum.flightShop]?: IContactInfo;
  [AirPriceFreezeStateEnum.contactInformation]?: IContactInfo;
  [AirPriceFreezeStateEnum.priceFreezeOffer]: AirPriceFreezeOfferContext;
}

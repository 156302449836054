import { airShoppingApi, type TripId } from "@b2bportal/air-shopping-api";
import { priceFreezeApi } from "@b2bportal/price-freeze-api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchTripDetails = createAsyncThunk(
  "flightsPriceFreezeDetails/fetchTripDetails",
  async (tripId: TripId, thunkAPI) => {
    try {
      const res = await airShoppingApi(axios).apiV0TripDetailPost(tripId);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchPriceFreezeVoucher = createAsyncThunk(
  "flightsPriceFreezeDetails/fetchPriceFreezeVoucher",
  async (priceFreezeId: string, thunkAPI) => {
    try {
      const res = await priceFreezeApi(axios).apiV3PricefreezeVoucherGetPost({
        GetPriceFreezeVoucherRequest: "ByPriceFreezeId",
        priceFreezeId,
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const findVoucherLatestTrip = createAsyncThunk(
  "flightsPriceFreezeDetails/findVoucherLatestTrip",
  async (priceFreezeId: string, thunkAPI) => {
    try {
      const res = await priceFreezeApi(
        axios
      ).apiV3PricefreezeVoucherExerciseFindPost({
        priceFreezeId,
      });

      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

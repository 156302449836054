import { useDeviceType } from "@b2bportal/core-utilities";
import { DisruptionExerciseHeader } from "@components/disruption";
import { CoreDisruptionComponent } from "@b2bportal/core-types";
import { useDisruptionStyles, useModuleBEM } from "@b2bportal/core-themes";
import defaultStyles from "./DisruptionExercisePage.module.scss";
import clsx from "clsx";
import {
  DisruptionExerciseButton,
  DisruptionExerciseButtonType,
} from "../DisruptionExerciseButton";
import { DisruptionExerciseMoreInfo } from "../DisruptionExerciseMoreInfo";
import { DisruptionExerciseMoreInfoType } from "../DisruptionExerciseMoreInfo/DisruptionExerciseMoreInfo";
import { DisruptionExerciseLoading } from "@b2bportal/core-disruption";
import { useEffect } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";

export enum DisruptionExercisePageWidth {
  Regular = "reguler",
  Large = "large",
}

export interface DisruptionExercisePageProps {
  className: string;
  pageWidth?: DisruptionExercisePageWidth;
  title: string;
  subtitle?: string;
  disruptionProductLogo?: string;
  onBack: () => void;
  content: JSX.Element | JSX.Element[];
  openErrorModal?: boolean;
  onCloseErrorModal?: () => void;
  onSupportClick?: () => void;
  handleRefundClick?: () => void;
  submitButtonTitle?: string;
  submitButtonAction?: () => void;
  submitButtonDisabled?: boolean;
  submitButtonHidden?: boolean;
  isLoading?: boolean;
  loadingMessage?: string;
  rightNavElement?: JSX.Element;
  termsLink: string;
  delayHours: string;
  numberOfHours: string;
  onCancelFlow?: () => void;
}

export const DisruptionExercisePage = ({
  className,
  pageWidth = DisruptionExercisePageWidth.Regular,
  title,
  subtitle,
  disruptionProductLogo,
  onBack,
  content,
  openErrorModal = false,
  onCloseErrorModal,
  onSupportClick,
  handleRefundClick,
  submitButtonTitle = "",
  submitButtonAction,
  submitButtonDisabled = false,
  submitButtonHidden = false,
  isLoading = false,
  loadingMessage,
  rightNavElement,
  termsLink,
  delayHours,
  numberOfHours,
  onCancelFlow,
}: DisruptionExercisePageProps) => {
  const { isDesktopAndUp } = useDeviceType();
  const COMPONENT_KEY = CoreDisruptionComponent.DisruptionExercisePage;
  const styles = useDisruptionStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t } = useI18nContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ctaButton = isDesktopAndUp ? (
    <DisruptionExerciseButton
      title={submitButtonTitle}
      onClick={submitButtonAction!}
      disabled={submitButtonDisabled}
    ></DisruptionExerciseButton>
  ) : (
    <div className={cn("floatingSection", { disabled: submitButtonDisabled })}>
      {submitButtonAction != null ? (
        <DisruptionExerciseButton
          title={submitButtonTitle}
          onClick={submitButtonAction}
          disabled={submitButtonDisabled}
        />
      ) : null}
      {onCancelFlow != null ? (
        <DisruptionExerciseButton
          title={t("cancel")}
          onClick={onCancelFlow}
          disabled={false}
          type={DisruptionExerciseButtonType.Secondary}
        />
      ) : null}
    </div>
  );

  const moreHelpPopup = (
    <DisruptionExerciseMoreInfo
      open={openErrorModal}
      onClose={onCloseErrorModal}
      type={DisruptionExerciseMoreInfoType.MoreHelp}
      onSupportClick={onSupportClick}
      handleRefundClick={handleRefundClick}
      termsLink={termsLink}
      delayHours={delayHours}
      numberOfHours={numberOfHours}
    ></DisruptionExerciseMoreInfo>
  );

  const loadingPopup = (
    <DisruptionExerciseLoading isLoading={isLoading} message={loadingMessage} />
  );

  const pageClass =
    pageWidth === DisruptionExercisePageWidth.Large
      ? cn("large")
      : cn("reguler");
  const headerClass = isDesktopAndUp
    ? cn("header-container-desktop")
    : cn("header-container-mobile");

  return (
    <div className={clsx([className, pageClass])}>
      <div className={headerClass}>
        <DisruptionExerciseHeader
          title={title}
          subtitle={subtitle}
          logo={disruptionProductLogo}
          onBack={onBack}
          withLogo={disruptionProductLogo != null}
          rightNavElement={rightNavElement}
        ></DisruptionExerciseHeader>
        {moreHelpPopup}
        {loadingPopup}
      </div>
      {content}
      {!submitButtonHidden && (
        <div className={cn("disruption-exercise-submit-button-container")}>
          {ctaButton}
        </div>
      )}
    </div>
  );
};

export default DisruptionExercisePage;

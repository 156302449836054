import { BookedFlightItineraryWithDepartureTime } from "@b2bportal/air-booking-api";
import { FlightItineraryResponse } from "@b2bportal/air-booking-api/lib/api";
import { IOpenModal, MyTripsFilter } from "@hopper-b2b/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type FlightsData = Omit<FlightItineraryResponse, "context">;

export interface TripsListState {
  tripsFilter: MyTripsFilter;
  openModal: IOpenModal;
  selectedTrip: BookedFlightItineraryWithDepartureTime | null;
}

const initialState: TripsListState = {
  tripsFilter: MyTripsFilter.UPCOMING_TRIPS,
  selectedTrip: null,
  openModal: { type: null, selectedItinerary: null },
};

export const tripListSlice = createSlice({
  name: "tripList",
  initialState,
  reducers: {
    setMyTripsFilter: (state, action: PayloadAction<MyTripsFilter>) => {
      state.tripsFilter = action.payload;
    },
    setSelectedTrip: (
      state,
      action: PayloadAction<BookedFlightItineraryWithDepartureTime>
    ) => {
      state.selectedTrip = action.payload;
    },
    setOpenModal: (state, action: PayloadAction<IOpenModal>) => {
      state.openModal = action.payload;
    },
  },
});

export const { setMyTripsFilter, setSelectedTrip, setOpenModal } =
  tripListSlice.actions;

export const tripListActions = tripListSlice.actions;
export default tripListSlice.reducer;

export enum CAR_URL_PARAM_KEYS {
  PICKUP_LOCATION = "pickupLocation",
  DROPOFF_LOCATION = "dropoffLocation",
  PICKUP_DATETIME = "pickupDateTime",
  DROPOFF_DATETIME = "dropoffDateTime",
  DRIVER_AGE = "driverAge",
  PRICE_RANGE_MIN = "priceMin",
  PRICE_RANGE_MAX = "priceMax",
  LAT_LNG = "latlng",
  ZOOM = "zoom",
  BOUNDS = "bounds",
  SORT_BY = "sortBy",
  VIEW = "view",
  PREV_SEARCH = "prevSearch",
  STEP = "step",
  SKIP_STORE = "skipStore",
  STORE_KEY = "store",
  VEHICLE_ID = "vehicleId",
  FILTERS = "filters",
}

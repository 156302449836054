import {
  HotelNavigationUtility,
  getDeviceType,
  logger,
  toHotelCoordinatesType,
} from "@overrides/utilities";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPlace } from "./api/fetchPlace";
import { BoundingBox, SearchDetails } from "@b2bportal/core-types";
import { HotelsAvailabilitySearchState } from "../types";
import type { HotelsState } from "../../../../store";
import {
  LocationDescriptorEnum,
  LodgingSelectionEnum,
  Platform,
} from "@b2bportal/lodging-api";
import { fetchHotels, initialHotelAvailabilityState } from "../index";

export const getPartialSearchFromURL = (
  searchParams: URLSearchParams
): Omit<SearchDetails, "place" | "searchType"> | null => {
  const urlParams = HotelNavigationUtility.parseLodgingParams(searchParams);

  if (
    urlParams.fromDate == null ||
    urlParams.fromDate === "" ||
    urlParams.untilDate == null ||
    urlParams.untilDate === ""
  )
    return null;

  return {
    dateRange: {
      from: urlParams.fromDate,
      until: urlParams.untilDate,
    },
    guests: {
      adults: urlParams.adults,
      children: urlParams.children,
    },
    rooms: urlParams.rooms,
  };
};

export const initializeSearchState = createAsyncThunk<
  HotelsAvailabilitySearchState,
  { searchParams: URLSearchParams; location: string }
>(
  "hotelsAvailability/initializeSearchState",
  async ({ searchParams, location }, thunkAPI) => {
    const maybePartialRequestedSearch = getPartialSearchFromURL(searchParams);

    try {
      if (maybePartialRequestedSearch == null) {
        return thunkAPI.rejectWithValue("Dates are missing");
      }

      const urlParams = HotelNavigationUtility.parseLodgingParams(searchParams);

      const place = await fetchPlace(
        HotelNavigationUtility.urlToPlaceQuery(location)
      );

      if (place == null) {
        return thunkAPI.rejectWithValue("Error fetching place");
      }

      const requestedSearch = {
        place,
        ...maybePartialRequestedSearch,
        searchType: urlParams.map?.centroid
          ? LodgingSelectionEnum.Location
          : LodgingSelectionEnum.Place,
      };
      return {
        searchFormValues: requestedSearch,
        requestedSearch: requestedSearch,
        map: {
          centroid: urlParams.map?.centroid,
          zoom: urlParams.map?.zoom ?? initialHotelAvailabilityState.map.zoom,
        },
        sort: urlParams.sortBy,
        filters: urlParams.filters,
      };
    } catch (error) {
      logger.debug(`Error fetching search locations. ${error}`);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Utility to poll for bounding box availability
const waitForBoundingBox = async (
  getState: () => HotelsState,
  interval = 500,
  maxTries = 5
): Promise<BoundingBox | null> => {
  let tries = 0;
  while (tries < maxTries) {
    const { boundingBox } = getState().hotels.hotelsAvailability.map;
    if (boundingBox) return boundingBox;
    await new Promise((resolve) => setTimeout(resolve, interval));
    tries++;
  }
  return null;
};

export const initializeFetchHotels = createAsyncThunk<
  void,
  void,
  { state: HotelsState; rejectValue: { error: string } }
>("hotelsAvailability/initializeFetchHotels", async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const { requestedSearch, map, hotels } = state.hotels.hotelsAvailability;

  if (requestedSearch == null) {
    return thunkAPI.rejectWithValue({ error: "Search is empty" });
  }

  const { guests, place, dateRange, rooms } = requestedSearch;
  const { isDesktopAndUp } = getDeviceType();

  const baseRequest = {
    guests,
    stayDates: dateRange,
    rooms,
    platform: isDesktopAndUp ? Platform.Desktop : Platform.Mobile,
  };

  const placeSearch = () => {
    thunkAPI.dispatch(
      fetchHotels({
        LodgingSelection: LodgingSelectionEnum.Place,
        request: { ...baseRequest, place },
      })
    );
    return;
  };

  try {
    // Check if a map centroid exists for a location-based.
    if (
      map.centroid &&
      requestedSearch.searchType === LodgingSelectionEnum.Location
    ) {
      const boundingBox = await waitForBoundingBox(thunkAPI.getState);

      // If a bounding box is available, do a location-based search
      if (boundingBox) {
        thunkAPI.dispatch(
          fetchHotels({
            LodgingSelection: LodgingSelectionEnum.Location,
            request: {
              ...baseRequest,
              location: {
                descriptor: {
                  northEast: toHotelCoordinatesType(boundingBox.northEast),
                  southWest: toHotelCoordinatesType(boundingBox.southWest),
                  LocationDescriptor: LocationDescriptorEnum.BoundingBox,
                },
                LodgingSelection: LodgingSelectionEnum.Location,
              },
            },
          })
        );
        return;
      }
    }

    placeSearch();
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    return thunkAPI.rejectWithValue({ error: errorMessage });
  }
});

export const initializeHotels = createAsyncThunk<
  void,
  { searchParams: URLSearchParams; location: string }
>("hotelsAvailability/initializeHotels", async (props, thunkAPI) => {
  try {
    await thunkAPI.dispatch(initializeSearchState(props));
    thunkAPI.dispatch(initializeFetchHotels());
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    return thunkAPI.rejectWithValue({ error: errorMessage });
  }
});

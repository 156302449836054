import type { TripSlice } from "@b2bportal/air-shopping-api";
import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  Icon,
  InfoBanner,
  InfoBannerSeverity,
} from "@components/ui";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import dayjs from "dayjs";
import defaultStyles from "./FlightRecheckBagsDialog.module.scss";

export interface FlightRecheckBagsDialogProps extends PlatformComponentProps {
  flightSlice?: TripSlice;
}

export const FlightRecheckBagsDialog = ({
  flightSlice,
  children,
}: FlightRecheckBagsDialogProps) => {
  const COMPONENT_KEY = CoreFlightsComponent.FlightRecheckBagsDialog;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t, formatInterval } = useI18nContext();

  const selfTransferLayovers =
    flightSlice?.segmentDetails
      .flatMap((segment, index, allSegments) =>
        segment.isSelfTransferLayover === true && index < allSegments.length - 1
          ? ([[segment, allSegments[index + 1]]] as const)
          : []
      )
      .map(([segment, nextSegment]) => {
        return {
          locationName: segment.destinationName,
          locationCode: segment.destinationCode,
          duration: formatInterval(
            dayjs(nextSegment.zonedDepartureTime).diff(
              dayjs(segment.zonedArrivalTime),
              "minutes"
            )
          ),
        };
      }) ?? [];

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        title={t("core-flights.vi.recheckBags.dialog.title")}
        className={cn("recheck-bags-dialog")}
      >
        <Trans
          i18nKey="core-flights.vi.recheckBags.dialog.instructions"
          components={{
            title: <span className={cn("title")} />,
            paragraph: <span className={cn("paragraph")} />,
            section: <span className={cn("section")} />,
          }}
        />
        <div className={cn("international-flights")}>
          <Icon iconName={IconNameEnum.world} className={cn("icon")} />
          <Trans
            i18nKey="core-flights.vi.recheckBags.dialog.international-flights"
            components={{
              title: <span className={cn("title")} />,
              paragraph: <span className={cn("paragraph")} />,
            }}
          />
        </div>
        {selfTransferLayovers.map((layover) => (
          <InfoBanner
            key={layover.locationCode}
            severity={InfoBannerSeverity.WARNING}
            className={cn("banner")}
          >
            <Trans
              i18nKey="core-flights.vi.recheckBags.dialog.layover-notice"
              values={{
                duration: layover.duration,
                locationName: layover.locationName,
                locationCode: layover.locationCode,
              }}
              components={{
                bold: <span className={cn("banner-bold")} />,
              }}
            />
          </InfoBanner>
        ))}
      </DialogContent>
    </Dialog>
  );
};

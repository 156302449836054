import { useCallback, useContext, useMemo, useState } from "react";
import {
  type MessageMethod,
  MessageMethodEnum,
  ShopFilter,
} from "@b2bportal/air-price-watch-api";
import { useI18nContext, Trans } from "@hopper-b2b/i18n";
import { ButtonWrap, MobileFloatingButton } from "@hopper-b2b/ui";
import { SessionContext } from "@hopper-b2b/utilities";
import { openNubankTermsAndConditions } from "../../utils/nubankHandoff";
import { CheckboxRowItem } from "../CheckboxRowItem";
import { ToggleRowItem } from "../ToggleRowItem";

const PriceWatchPopoverContent = ({
  onSubmit,
}: {
  onSubmit: (method: { method: MessageMethod }, filter: ShopFilter) => void;
}) => {
  const [nonstopOnlySelected, setNonstopOnlySelected] = useState(false);
  const [excludeBasicSelected, setExcludeBasicSelected] = useState(false);
  const [emailSelected, setEmailSelected] = useState(false);
  const { email: sessionEmail } = useContext(SessionContext);
  // TODO: add email editing functionality
  const [email, setEmail] = useState(sessionEmail || "gabriela.lima@email.com");
  const [notificationsSelected, setNotificationsSelected] = useState(true);

  const { t } = useI18nContext();

  const content = useMemo(
    () => ({
      flightTypes: [
        {
          title: t("priceWatch.nonstopOnly"),
          selected: nonstopOnlySelected,
          onCheckClick: setNonstopOnlySelected,
        },
        {
          title: t("priceWatch.excludeBasic"),
          selected: excludeBasicSelected,
          onCheckClick: setExcludeBasicSelected,
        },
      ],
      notificationMethods: [
        {
          title: t("priceWatch.appNotifications"),
          subtitle: t("priceWatch.appNotificationsSubtitle"),
          selected: notificationsSelected,
          onClick: setNotificationsSelected,
        },
        {
          title: t("priceWatch.emailDefault"),
          subtitle: email,
          selected: emailSelected,
          onClick: setEmailSelected,
        },
      ],
    }),
    [
      email,
      emailSelected,
      excludeBasicSelected,
      nonstopOnlySelected,
      notificationsSelected,
      t,
    ]
  );

  const openTermsHandler = useCallback(() => {
    openNubankTermsAndConditions();
  }, []);

  const onSubmitHandler = useCallback(() => {
    const priceWatchInfo: { method: MessageMethod } = {
      // TODO: typing?
      method: {
        MessageMethod:
          emailSelected && notificationsSelected
            ? MessageMethodEnum.MultiMethod
            : !emailSelected
            ? MessageMethodEnum.Push
            : MessageMethodEnum.Email,
        ...(emailSelected ? { email: email } : null),
      },
    };

    const filter =
      nonstopOnlySelected && excludeBasicSelected
        ? ShopFilter.NonStopNoLCC
        : nonstopOnlySelected
        ? ShopFilter.NonStop
        : excludeBasicSelected
        ? ShopFilter.NoLCC
        : ShopFilter.NoFilter;

    onSubmit(priceWatchInfo, filter);
  }, [
    email,
    emailSelected,
    excludeBasicSelected,
    nonstopOnlySelected,
    notificationsSelected,
    onSubmit,
  ]);

  return (
    <>
      <div>
        <div className="preferences flight">
          <h4>{t("priceWatch.preferences")}</h4>
          {content.flightTypes.map((typeOption, i) => (
            <CheckboxRowItem {...typeOption} key={i} />
          ))}
        </div>
        <div className="preferences notify">
          <h4>{t("priceWatch.alertPreferences")}</h4>
          {content.notificationMethods.map((notificationMethod, i) => (
            <ToggleRowItem {...notificationMethod} key={i} />
          ))}
        </div>
        <div className="bottom">
          <p className="instructions">{t("priceWatch.instructions")}</p>
          <p className="instructions">
            <Trans
              i18nKey={"priceWatch.termsAndConditions"}
              components={[
                <ButtonWrap
                  className="terms-link"
                  onClick={openTermsHandler}
                />,
              ]}
            />
          </p>
        </div>
      </div>
      <MobileFloatingButton
        onClick={onSubmitHandler}
        wide
        disabled={!emailSelected && !notificationsSelected}
      >
        {t("priceWatch.watchTrip")}
      </MobileFloatingButton>
    </>
  );
};

export default PriceWatchPopoverContent;

import { createSelector } from "@reduxjs/toolkit";
import travelersApi from "./api";

export const listTravelersResultSelector =
  travelersApi.endpoints.listTravelers.select();

export const watchesSelector = createSelector(
  listTravelersResultSelector,
  (result) => result.data
);

import { useEffect } from "react";
import { Provider } from "react-redux";
import {
  AssetContextProvider,
  type IAssetContextProps,
  StyleContextProvider,
} from "@b2bportal/core-themes";
import { installCoreCssConfig } from "@b2bportal/core-themes";
import type { Store } from "@reduxjs/toolkit";
import type { PortalConfig } from "../../../types";
import { PortalConfigProvider } from "../../context/PortalConfigContext";
import "./styles.scss";
import App from "../App";

export interface CoreProvidersProps<T extends Store>
  extends React.PropsWithChildren {
  config: PortalConfig;
  store: T;
}

export const CoreProviders = <T extends Store>({
  config,
  store,
  children,
}: CoreProvidersProps<T>) => {
  const assetContext: IAssetContextProps = {
    icons: config.theme.icons,
    images: config.theme.images,
  };

  installCoreCssConfig(config.theme.variables);

  useEffect(() => {
    document.body.classList.add(config.clientName, "core", "override-styles");

    return () => {
      document.body.classList.remove(
        config.clientName,
        "core",
        "override-styles"
      );
    };
  }, []);

  return (
    <Provider store={store}>
      <PortalConfigProvider config={config}>
        <StyleContextProvider context={config.theme.styleOverrides ?? {}}>
          <AssetContextProvider context={assetContext}>
            <App mainClassName={config.clientName}>{children}</App>
          </AssetContextProvider>
        </StyleContextProvider>
      </PortalConfigProvider>
    </Provider>
  );
};

import { useMemo } from "react";
import type { FareDetailsV2, TripDetailsV2 } from "@b2bportal/air-shopping-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreFlightsComponent } from "@b2bportal/core-types";
import { FareDetails } from "@components/flights";
import { PriceDisplay } from "@components/ui";
import clsx from "clsx";
import defaultStyles from "./FlightCardFareListItem.module.scss";

interface FlightCardFareListItemProps {
  className?: string;
  trip: TripDetailsV2;
  fare: FareDetailsV2;
  sliceIndex: number;
  fareFormatOptions: Intl.NumberFormatOptions | undefined;
  onClick: () => void;
}

export const FlightCardFareListItem = (props: FlightCardFareListItemProps) => {
  const { className, trip, fare, sliceIndex, fareFormatOptions, onClick } =
    props;
  const styles = useFlightStyles(
    CoreFlightsComponent.FlightCardFareListItem,
    defaultStyles
  );

  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightCardFareListItem
  );

  const prices = useMemo(() => fare.paxPricings?.[0].pricing.total, [fare]);

  return (
    <li className={clsx(className, block)}>
      <div className={cn("fare-info")}>
        {fare.slices[sliceIndex].fareShelf?.brandName ?? ""}
      </div>
      <button className={cn("fare-button")} onClick={onClick}>
        {prices != null && (
          <PriceDisplay prices={prices} formatOptions={fareFormatOptions} />
        )}
      </button>
      <div className={cn("fare-details")}>
        <FareDetails
          segments={trip.slices[sliceIndex].segmentDetails}
          fareSlice={fare.slices[sliceIndex]}
          restrictions={fare.restrictions?.[sliceIndex]}
        />
      </div>
    </li>
  );
};

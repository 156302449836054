import type { VehicleClass } from "@apac/components/cars";
import type { FiatPrice } from "@b2bportal/core-utilities";
import type {
  Suggestion,
  GroundSelectionEnum,
  VehicleAvailability,
} from "@b2bportal/ground-api";

// Define a type for search details
export interface SearchDetails {
  pickupDateTime: string;
  pickupLocation: Suggestion;
  dropoffType: DropoffType;
  dropoffDateTime: string;
  dropoffLocation: Suggestion;
  driverAge: number;
  searchType: GroundSelectionEnum;
}

export enum DropoffType {
  RoundTrip = "roundTrip",
  OneWay = "oneWay",
}

export interface NumberRange {
  min: number;
  max: number;
}

export interface FiatPriceRange {
  min: FiatPrice;
  max: FiatPrice;
}

export interface FilterState {
  priceRange?: NumberRange;
  vehicleClass?: string[];
  passengerQty?: string[];
  baggageQty?: string[];
  [key: string]: string[] | NumberRange | undefined;
}

export interface FilterOptions {
  priceRange: FiatPriceRange;
  vehicleClass: string[];
  passengerQty: string[];
  baggageQty: string[];
  [key: string]: string[] | FiatPriceRange | undefined;
}

export enum CarSortOptionEnum {
  Recommended = "recommended",
  Price = "price",
  Distance = "distance",
}

export type CarComparator = (
  a: VehicleAvailability,
  b: VehicleAvailability
) => number;

import { createAsyncThunk } from "@reduxjs/toolkit";
import type { HotelsState } from "../../../../store";
import { fetchInitialHotels } from "./fetchInitialHotels";
import { fetchHotelsNextPage } from "./fetchHotelsNextPage";
import { AvailabilityApiCall } from "./api/fetchAvailability";

const POLLING_DELAY_MS = 2000;

export const fetchHotels = createAsyncThunk<
  void,
  AvailabilityApiCall,
  { state: HotelsState; rejectValue: { error: string } }
>("hotelsAvailability/fetchHotels", async (req, thunkAPI) => {
  try {
    const initialResponse = await thunkAPI.dispatch(fetchInitialHotels(req));

    if (
      fetchInitialHotels.fulfilled.match(initialResponse) &&
      initialResponse.payload?.nextPageToken
    ) {
      let nextPageToken: string | null = initialResponse.payload.nextPageToken;
      const searchId: string | null = initialResponse.payload.searchId;

      while (nextPageToken) {
        const nextPageResponse = await thunkAPI.dispatch(
          fetchHotelsNextPage({ nextPageToken, searchId: searchId })
        );

        const state = thunkAPI.getState();

        if (
          fetchHotelsNextPage.fulfilled.match(nextPageResponse) &&
          nextPageResponse.payload?.nextPageToken &&
          state.hotels.hotelsAvailability.hotels.data?.searchId === searchId
        ) {
          nextPageToken = nextPageResponse.payload.nextPageToken;

          await new Promise((resolve) => setTimeout(resolve, POLLING_DELAY_MS));
        } else {
          nextPageToken = null;
        }
      }
    }
  } catch (error) {
    console.error("Failed to fetch hotels:", error);
    return thunkAPI.rejectWithValue({ error: "Failed to fetch hotels" });
  }
});

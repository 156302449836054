import { ProductType } from "@b2bportal/core-types";

export enum Event {
  // CommonEvents
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
  CHANGE = "CHANGE",
  GO_IDLE = "GO_IDLE",
  RESET_CONTEXT = "RESET_CONTEXT",

  // PaymentInformation
  OPEN_PAYMENT_FORM = "OPEN_PAYMENT_FORM",
  CLOSE_PAYMENT_FORM = "CLOSE_PAYMENT_FORM",
  SET_APPLIED_BALANCE = "SET_APPLIED_BALANCE",
  VERIFY_PAYMENT_METHOD = "VERIFY_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
  SET_PAYMENT_METHOD_ID = "SET_PAYMENT_METHOD_ID",
  SET_SELECTED_PAYMENT_METHOD_ID = "SET_SELECTED_PAYMENT_METHOD_ID",
  CLEAR_PAYMENT_ERROR = "CLEAR_PAYMENT_ERROR",

  GO_TO_PASSENGER_SELECT = "GO_TO_PASSENGER_SELECT",
  GO_TO_CONTACT_INFORMATION = "GO_TO_CONTACT_INFORMATION",
}

export const CheckoutEvent = {
  ...Event,
} as const;

export type CheckoutEvent = (typeof CheckoutEvent)[keyof typeof CheckoutEvent];

export interface NextEvent {
  type: Event.NEXT;
  productType?: ProductType;
}

export interface VerifyPaymentMethodEvent {
  type: Event.VERIFY_PAYMENT_METHOD;
  token: string;
}

export interface SetSelectedPaymentMethodIdEvent {
  type: Event.SET_SELECTED_PAYMENT_METHOD_ID;
  paymentMethod: {
    paymentId: string | undefined;
  };
}

export interface DeletePaymentMethodEvent {
  type: Event.DELETE_PAYMENT_METHOD;
  paymentMethod: {
    paymentId: string;
  };
}

export interface OpenPaymentFormEvent {
  type: Event.OPEN_PAYMENT_FORM;
}

export interface ClosePaymentFormEvent {
  type: Event.CLOSE_PAYMENT_FORM;
}

export interface ClearPaymentError {
  type: Event.CLEAR_PAYMENT_ERROR;
}

export interface GoToPassengerSelect {
  type: Event.GO_TO_PASSENGER_SELECT;
}

export interface GoToContactInformation {
  type: Event.GO_TO_CONTACT_INFORMATION;
}

export type TEvent =
  | NextEvent
  | VerifyPaymentMethodEvent
  | SetSelectedPaymentMethodIdEvent
  | DeletePaymentMethodEvent
  | OpenPaymentFormEvent
  | ClosePaymentFormEvent
  | ClearPaymentError
  | GoToContactInformation
  | GoToPassengerSelect;

import { useCallback, useMemo } from "react";
import type { TripSegment } from "@b2bportal/air-shopping-api";
import type { FlightDetailsSegmentComponentProps } from "@b2bportal/core-flights";
import { ScheduleChangeDisplayMode } from "@b2bportal/core-types";
import { DateTimeFormatStyle, useI18nContext } from "@hopper-b2b/i18n";
import { removeTimezone } from "@hopper-b2b/utilities";
import dayjs from "dayjs";

interface ConnectingFlightInfo {
  plusDays: number;
  formattedDepartureDate: string;
  nextSegment: TripSegment;
}

export const useFlightDetailsSegment = (
  props: FlightDetailsSegmentComponentProps
) => {
  const { nextSegment, scheduleChangeDisplayMode, segment, updatedSegment } =
    props;
  const { formatDateTime, formatInterval, t } = useI18nContext();
  const { arrivalTime, departureTime } = segment;
  const prevArrivalTime = arrivalTime;
  const prevDepartureTime = departureTime;
  let { zonedArrivalTime, zonedDepartureTime } = segment;
  let currentArrivalTime = arrivalTime;
  let currentDepartureTime = departureTime;

  const formatSegmentTime = useCallback(
    (segmentTime: string, format = DateTimeFormatStyle.ShortTime) => {
      return formatDateTime(
        dayjs(removeTimezone(segmentTime)).toDate(),
        format
      );
    },
    [formatDateTime]
  );

  const formatSegmentInterval = useCallback(
    (startTime: string, endTime: string) =>
      formatInterval(dayjs(endTime).diff(dayjs(startTime), "minutes")),
    [formatInterval]
  );

  // note: we need to use startOf('day') to compare the the difference in day
  // because dayjs doesn't give a diff of 1+ day unless it has been 24+ hours
  // past the datetime being compared
  const getConnectingFlightInfo = useCallback(
    (nextSegment: TripSegment): ConnectingFlightInfo => ({
      nextSegment,
      formattedDepartureDate: formatSegmentTime(
        nextSegment.departureTime,
        DateTimeFormatStyle.MediumDate
      ),
      plusDays: dayjs(removeTimezone(nextSegment.departureTime))
        .startOf("day")
        .diff(dayjs(removeTimezone(currentArrivalTime)).startOf("day"), "day"),
    }),
    [currentArrivalTime, formatSegmentTime]
  );

  if (
    updatedSegment != null &&
    (scheduleChangeDisplayMode === ScheduleChangeDisplayMode.both ||
      scheduleChangeDisplayMode === ScheduleChangeDisplayMode.updated)
  ) {
    currentArrivalTime = updatedSegment.updatedArrival ?? arrivalTime;
    currentDepartureTime = updatedSegment.updatedDeparture ?? departureTime;
    zonedArrivalTime = updatedSegment.zonedUpdatedArrival ?? zonedArrivalTime;
    zonedDepartureTime =
      updatedSegment.zonedUpdatedDeparture ?? zonedDepartureTime;
  }

  return useMemo(
    () => ({
      context: {
        arrivalDate: formatSegmentTime(
          currentArrivalTime,
          DateTimeFormatStyle.MediumDate
        ),
        connectingFlightInfo:
          nextSegment != null
            ? getConnectingFlightInfo(nextSegment)
            : undefined,
        currentArrivalTime: formatSegmentTime(currentArrivalTime),
        currentDepartureTime: formatSegmentTime(currentDepartureTime),
        displayMode: scheduleChangeDisplayMode,
        hasDifferentOperatingAirline:
          segment.operatingAirline.code !== segment.marketingAirline.code,
        hasScheduleChange: !!updatedSegment,
        plusDays: updatedSegment?.plusDays ?? segment.plusDays,
        prevArrivalTime: formatSegmentTime(prevArrivalTime),
        prevDepartureTime: formatSegmentTime(prevDepartureTime),
        showScheduleChangeBadge:
          updatedSegment != null &&
          (scheduleChangeDisplayMode === ScheduleChangeDisplayMode.both ||
            scheduleChangeDisplayMode === ScheduleChangeDisplayMode.original),
        stopoverDurationMins:
          updatedSegment?.stopoverDurationMinutes ??
          segment.stopoverDurationMinutes ??
          0,
        travelTime: formatSegmentInterval(zonedDepartureTime, zonedArrivalTime),
      },
      handlers: {
        formatSegmentInterval,
        formatSegmentTime,
        getConnectingFlightInfo,
      },
      text: {
        layoverWithLocation:
          segment.stopoverDurationMinutes != null &&
          segment.stopoverDurationMinutes > 0
            ? t("layoverWithLocation", {
                duration: formatInterval(segment.stopoverDurationMinutes),
                location: segment.destinationCode,
              })
            : undefined,
        operatedBy: t("operatedBy", { airline: segment.operatingAirline.name }),
        travelTime: t("travelTime"),
      },
    }),
    [
      currentArrivalTime,
      currentDepartureTime,
      formatSegmentInterval,
      formatSegmentTime,
      getConnectingFlightInfo,
      nextSegment,
      prevArrivalTime,
      prevDepartureTime,
      scheduleChangeDisplayMode,
      segment,
      t,
      updatedSegment,
      zonedArrivalTime,
      zonedDepartureTime,
      segment,
    ]
  );
};

export type IFlightDetailsSegmentData = ReturnType<
  typeof useFlightDetailsSegment
>;

import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import {
  Button,
  ButtonTypeEnum,
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@components/ui";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import defaultStyles from "./FlightMissedConnectionDialog.module.scss";

export const FlightMissedConnectionDialog = ({
  children,
}: PlatformComponentProps) => {
  const COMPONENT_KEY = CoreFlightsComponent.FlightMissedConnectionDialog;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [, cn] = useModuleBEM(styles, COMPONENT_KEY);
  const { t, formatFiatCurrency } = useI18nContext();
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button buttonType={ButtonTypeEnum.Link} className={cn("learn-more")}>
          {children}
        </Button>
      </DialogTrigger>
      <DialogContent
        title={t("core-flights.vi.missedConnectionGuarantee.dialog.title")}
        className={cn("missed-connection-dialog")}
      >
        <Trans
          i18nKey="core-flights.vi.missedConnectionGuarantee.dialog.whatsIncluded"
          values={{
            amountCap: formatFiatCurrency(
              {
                value: 5000,
                currencyCode: "USD",
              },
              { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            ),
          }}
          components={{
            title: <span className={cn("title")} />,
            paragraph: <span className={cn("paragraph")} />,
            section: <span className={cn("section")} />,
            bold: <span className={cn("bold")} />,
          }}
        />
        <Trans
          i18nKey="core-flights.vi.missedConnectionGuarantee.dialog.instructions"
          components={{
            title: <span className={cn("subtitle")} />,
            paragraph: <span className={cn("paragraph")} />,
            bold: <span className={cn("bold")} />,
            step: <span className={cn("step")} />,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

import clsx from "clsx";
import { PropsWithChildren } from "react";
import { useModuleBEM, useStyles } from "@b2bportal/core-themes";
import { PlatformComponentProps } from "@b2bportal/core-types";
import defaultStyles from "./styles.module.scss";
import { APAC_FORM_UI_NAME, ApacFormUiComponent } from "@apac/core-form-ui";

interface FormInputContainerProps extends PlatformComponentProps {
  disabled?: boolean;
  error?: boolean;
}

export const FormInputContainer = ({
  className,
  disabled,
  error,
  children,
}: PropsWithChildren<FormInputContainerProps>): JSX.Element => {
  const styles = useStyles(
    APAC_FORM_UI_NAME,
    ApacFormUiComponent.FormInputContainer,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    ApacFormUiComponent.FormInputContainer
  );
  return (
    <div
      className={clsx(className, block, {
        [cn("error")]: error,
        [cn("disabled")]: disabled,
      })}
    >
      {children}
    </div>
  );
};

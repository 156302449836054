import {
  type AvailabilityResponse,
  type LodgingIds,
  AvailabilityRequestEnum,
  LodgingCollection,
  DateRange,
  GuestsSelection,
  Platform,
  Location,
  Initial,
  More,
  Suggestion,
  AvailabilityRequest,
} from "@b2bportal/lodging-api";
import {
  AvailabilityResponseEnum,
  LodgingSelectionEnum,
} from "@b2bportal/lodging-api";
import { hotelsApi } from "@overrides/utilities";

interface AvailabilityQuery {
  stayDates: DateRange;
  guests: GuestsSelection;
  rooms: number;
  platform: Platform;
}

export interface AvailabilityByPlaceQuery extends AvailabilityQuery {
  place: Suggestion;
}

export interface AvailabilityByLodgingQuery extends AvailabilityQuery {
  lodgingSelection: { LodgingSelection: "LodgingIds" } & LodgingIds;
}

export interface AvailabilityByLocationQuery extends AvailabilityQuery {
  location: Location;
}

export type AvailabilityApiCall =
  | { LodgingSelection: "Place"; request: AvailabilityByPlaceQuery }
  | { LodgingSelection: "LodgingIds"; request: AvailabilityByLodgingQuery }
  | { LodgingSelection: "Location"; request: AvailabilityByLocationQuery };

export const fetchAvailabilityByPlaceAPI = async (
  query: AvailabilityByPlaceQuery
): Promise<Initial> => {
  if (
    query.place.id.Id !== "Lodgings" ||
    query.place.id.lodgingSelection.LodgingSelection !== "Place"
  ) {
    return Promise.reject(
      new Error("The server returned a next page availability request response")
    );
  }

  const body = {
    progressiveConfig: {},
    excludeNonLuxuryLodgings: false,
    AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
    lodgingCollection: LodgingCollection.NoCollection,
    lodgingSelection: {
      LodgingSelection: LodgingSelectionEnum.Place,
      placeId: query.place.id.lodgingSelection.placeId,
      searchTerm: query.place.id.lodgingSelection.searchTerm,
      // TODO: Determine what is the purpose of this attribute
      placeTypes: query.place.id.lodgingSelection.placeTypes,
    },
    stayDates: query.stayDates,
    guests: query.guests,
    rooms: {
      numberOfRooms: query.rooms,
    },
    platform: query.platform,
  };
  return fetchInitialAvailability(body);
};

export const fetchAvailabilityNextPage = async (
  token: string
): Promise<More> => {
  const body = {
    progressiveConfig: {},
    excludeNonLuxuryLodgings: false,
    AvailabilityRequest: AvailabilityRequestEnum.FollowUpSearch,
    moreToken: token,
    lodgingCollection: LodgingCollection.NoCollection,
  };
  try {
    const response = await hotelsApi.apiV0HotelsAvailabilityPost(body);

    if (response.data.AvailabilityResponse === AvailabilityResponseEnum.More) {
      return response.data;
    }

    throw new Error("The server returned a 'More' availability response");
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);

    throw new Error(`Failed to fetch availability: ${errorMessage}`);
  }
};

export const fetchAvailabilityByLodgingId = async (
  query: AvailabilityByLodgingQuery
): Promise<Initial> => {
  const body = {
    ...query,
    progressiveConfig: {},
    excludeNonLuxuryLodgings: false,
    AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
    lodgingCollection: LodgingCollection.NoCollection,
    rooms: { numberOfRooms: query.rooms },
  };

  return fetchInitialAvailability(body);
};

export const fetchInitialAvailabilityByLocation = async (
  query: AvailabilityByLocationQuery
): Promise<Initial> => {
  const lodgingSelection = {
    LodgingSelection: LodgingSelectionEnum.Location,
    descriptor: query.location.descriptor,
    center: query.location.center,
  };
  const body = {
    progressiveConfig: {},
    excludeNonLuxuryLodgings: false,
    AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
    lodgingCollection: LodgingCollection.NoCollection,
    lodgingSelection: lodgingSelection,
    stayDates: query.stayDates,
    guests: query.guests,
    rooms: { numberOfRooms: query.rooms },
    platform: query.platform,
  };

  return fetchInitialAvailability(body);
};

export const fetchInitialAvailability = async (req: AvailabilityRequest) => {
  try {
    const response = await hotelsApi.apiV0HotelsAvailabilityPost(req);

    if (
      response.data.AvailabilityResponse === AvailabilityResponseEnum.Initial
    ) {
      return response.data;
    }

    throw new Error("The server returned a 'More' availability response");
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);

    throw new Error(`Failed to fetch availability: ${errorMessage}`);
  }
};

export const CORE_TRIPS_NAME = "core-trips";

export enum CoreTripsComponent {
  TripsList = "TripsList",
  TripListLoadingScreen = "TripListLoadingScreen",
  ContentHeading = "ContentHeading",
  NoTripResults = "NoTripResults",
  TripListPage = "TripListPage",
  Navigation = "Navigation",
  TripListBreadcrumbs = "TripListBreadcrumbs",
  TripsCard = "TripsCard",
}

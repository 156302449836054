import { carsApi } from "@apac/cars";
import {
  type Itinerary,
  ItineraryState,
  type SingleItineraryRequest,
  type SingleItineraryResponse,
} from "@b2bportal/ground-api/lib/api";
import type { ReservationWrapper } from "@overrides/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import dayjs from "dayjs";
export interface ICarItinerariesResponse {
  [key: string]: Array<ReservationWrapper<Itinerary>>;
}
export const carsPostBookingApi = createApi({
  reducerPath: "carsPostBooking",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
  }),
  tagTypes: ["itineraries"],
  endpoints: (builder) => ({
    fetchCarItineraries: builder.query<ICarItinerariesResponse, void>({
      keepUnusedDataFor: 180,
      queryFn: async () => {
        const response = await carsApi.apiV1GroundItinerariesPut({
          states: [
            ItineraryState.Canceled,
            ItineraryState.Future,
            ItineraryState.Present,
            ItineraryState.Past,
          ],
          referenceDateTime: dayjs().format(),
        });
        const wrappedCarReservations: ICarItinerariesResponse = Object.keys(
          response.data.itinerariesByState
        ).reduce((acc, key) => {
          const reservations = response.data.itinerariesByState[key];
          const wrapped = reservations.map((reservation) => ({
            reservation,
            reservationId: reservation.reservation.internalGroundBookingId,
            sortDate: reservation.reservation.pickUp.dateTime,
            recentFirst:
              key === ItineraryState.Past || key === ItineraryState.Canceled,
          }));
          return { ...acc, [key]: wrapped };
        }, {});
        return {
          data: wrappedCarReservations,
        };
      },
      providesTags: ["itineraries"],
    }),
    fetchCarItinerary: builder.query<
      SingleItineraryResponse,
      SingleItineraryRequest
    >({
      queryFn: async (request) => {
        const response = await carsApi.apiV1GroundItinerariesSinglePut(request);
        return { data: response.data };
      },
    }),
  }),
});

export const { useFetchCarItinerariesQuery, useFetchCarItineraryQuery } =
  carsPostBookingApi;

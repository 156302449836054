export const CORE_FLIGHTS_NAME = "core-flights";

export enum CoreFlightsComponent {
  // Search
  Filters = "Filters",
  FilterList = "FilterList",
  FilterDialog = "FilterDialog",
  FilterPrice = "FilterPrice",
  FilterFareClass = "FilterFareClass",
  FilterStops = "FilterStops",
  FilterTime = "FilterTime",
  FilterAirline = "FilterAirline",
  FilterAirport = "FilterAirport",
  FilterFlightNumber = "FilterFlightNumber",

  // Flight Search
  FlightSearch = "FlightSearch",
  HomePageFlightSearch = "HomePageFlightSearch",
  FlightPassengerSelection = "FlightPassengerSelection",
  FlightDateSelection = "FlightDateSelection",
  PassengerCountPicker = "PassengerCountPicker",
  CountPicker = "CountPicker",
  CalendarPicker = "CalendarPicker",
  FlightCategorySelection = "FlightCategorySelection",
  FlightLocationSelection = "FlightLocationSelection",
  DatePickerButton = "DatePickerButton",
  CalendarPriceRangeTags = "CalendarPriceRangeTags",
  FlightsLocationAutocomplete = "FlightsLocationAutocomplete",

  //Shop
  Airline = "Airline",
  AlgomerchTags = "AlgomerchTags",
  DateSelectionButton = "DateSelectionButton",
  FareCombinationBanner = "FareCombinationBanner",
  FareListFareDetails = "FareListFareDetails",
  FareRestrictions = "FareRestrictions",
  FlightCard = "FlightCard",
  FlightCardDetailsLoading = "FlightCardDetailsLoading",
  FlightCardFareList = "FlightCardFareList",
  FlightCardFareListItem = "FlightCardFareListItem",
  FlightCardRecheckBaggageBanner = "FlightCardRecheckBaggageBanner",
  FlightCardSummary = "FlightCardSummary",
  FlightCardSummaryTimeBlock = "FlightCardSummaryTimeBlock",
  FlightDetails = "FlightDetails",
  FlightDetailsDialog = "FlightDetailsDialog",
  FlightDetailsSegment = "FlightDetailsSegment",
  FlightFareGrid = "FlightFareGrid",
  FlightFareGridItem = "FlightFareGridItem",
  FlightList = "FlightList",
  FlightListError = "FlightListError",
  FlightListGridFilter = "FlightListGridFilter",
  FlightListLoading = "FlightListLoading",
  FlightListNoFlights = "FlightListNoFlights",
  FlightMissedConnectionDialog = "FlightMissedConnectionDialog",
  FlightRecheckBagsDialog = "FlightRecheckBagsDialog",
  FlightShopBreadcrumbs = "FlightShopBreadcrumbs",
  FlightShopSearch = "FlightShopSearch",
  FlightShopTripDetails = "FlightShopTripDetails",
  FlightSummary = "FlightSummary",
  FlightTime = "FlightTime",
  ItinerarySummary = "ItinerarySummary",
  LocationSelectionButton = "LocationSelectionButton",
  ReviewBreakdown = "ReviewBreakdown",
  ReviewFare = "ReviewFare",
  ReviewFlightCard = "ReviewFlightCard",
  ReviewFlightHeader = "ReviewFlightHeader",
  ReviewFlightSecondaryContent = "ReviewFlightSecondaryContent",
  Sort = "Sort",

  //PostBooking
  ChangeFlightEligibilityModal = "ChangeFlightEligibilityModal",
  FlightItineraryCard = "FlightItineraryCard",
  FlightTravelerInfo = "FlightTravelerInfo",
  FlightItinerarySliceDetails = "FlightItinerarySliceDetails",
  FlightItineraryRestrictionsDetails = "FlightItineraryRestrictionsDetails",
  FlightItineraryRestrictionInfo = "FlightItineraryRestrictionInfo",

  FlightItineraryDetailPage = "FlightItineraryDetailPage",
  FlightItineraryDetailSummary = "FlightItineraryDetailSummary",
  FlightItineraryDetailSliceSummary = "FlightItineraryDetailSliceSummary",
  FlightItineraryDetailBookingConfirmation = "FlightItineraryDetailBookingConfirmation",
  FlightItineraryDetailTravelers = "FlightItineraryDetailTravelers",
  FlightItineraryDetailPayment = "FlightItineraryDetailPayment",
  FlightItinerarySelfServeLinks = "FlightItinerarySelfServeLinks",
  FlightItineraryRebookReturnFlight = "FlightItineraryRebookReturnFlight",
  FlightItineraryDetailAddOns = "FlightItineraryDetailAddOns",
  FlightScheduleChange = "FlightScheduleChange",
  FlightScheduleChangeBadge = "FlightScheduleChangeBadge",
  FlightScheduleChangeBanner = "FlightScheduleChangeBanner",
  FlightScheduleChangeForm = "FlightScheduleChangeForm",
  FlightScheduleChangeModal = "FlightScheduleChangeModal",
  ScheduleChangeTimePreferences = "ScheduleChangeTimePreferences",

  //Common
  FlightPriceBreakdown = "FlightPriceBreakdown",

  //Checkout
  TripSummary = "TripSummary",

  // Pages
  SearchPage = "SearchPage",
  DeparturePage = "DeparturePage",
  ReturnPage = "ReturnPage",
  ReviewPage = "ReviewPage",
  ReviewFarePage = "ReviewFarePage",

  // Flight Exchange
  FlightExchange = "FlightExchange",
  FlightExchangeLanding = "FlightExchangeLanding",

  // Fintech Marketplace
  FlightsFintechMarketplacePageWrapper = "FlightsFintechMarketplacePageWrapper",
  FlightsFintechMarketplaceTripDetails = "FlightsFintechMarketplaceTripDetails",
}

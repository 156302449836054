import { GuestsSelection } from "@b2bportal/lodging-api";
import {
  HotelsAvailabilitySearchState,
  HotelsAvailabilityState,
} from "./types";
import { CallState } from "@b2bportal/core-types";
import { HotelSortOptionEnum } from "@overrides/types";

export const initialGuests: GuestsSelection = {
  adults: 2,
  children: [],
};

export const initialFiltersState = {
  priceRange: {
    max: Number.MAX_SAFE_INTEGER,
    min: 0,
  },
  starRating: [],
  freeCancellation: false,
  amenities: [],
};

export const initialHotelsAvailabilitySearchState: HotelsAvailabilitySearchState =
  {
    searchFormValues: {
      dateRange: {
        until: undefined,
        from: undefined,
      },
      guests: initialGuests,
      rooms: 1,
      place: undefined,
    },
    sort: HotelSortOptionEnum.Recommended,
    filters: initialFiltersState,
    map: {
      zoom: 13,
    },
  };

export const initialHotelAvailabilityState: HotelsAvailabilityState = {
  ...initialHotelsAvailabilitySearchState,
  hotels: {
    state: CallState.NotCalled,
  },
  initialized: CallState.NotCalled,
};

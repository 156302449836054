import {
  forwardRef,
  type HTMLInputTypeAttribute,
  type ReactNode,
  type Ref,
} from "react";
import {
  type FieldValues,
  type FieldPath,
  type Path,
  useController,
  type UseControllerProps,
  type ControllerRenderProps,
} from "react-hook-form";
import { FormInputWrapper } from "@apac/form-ui";

interface UncontrolledTextFieldProps<
  TFieldValues extends FieldValues,
  FName extends Path<TFieldValues>
> extends UseControllerProps<TFieldValues, FName> {
  label?: ReactNode;
  ref?: Ref<HTMLInputElement>;
}

export const UncontrolledTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  rules,
  label,
  ref,
  ...props
}: UncontrolledTextFieldProps<TFieldValues, TName>): JSX.Element => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });
  return (
    <ControlledTextField
      {...props}
      label={label}
      {...field}
      ref={ref}
      error={fieldState.error?.message}
    />
  );
};

interface ControlledTextFieldProps extends ControllerRenderProps {
  label?: ReactNode;
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  error?: string;
  type?: HTMLInputTypeAttribute;
  icon?: ReactNode;
}

export const ControlledTextField = forwardRef<
  HTMLInputElement,
  ControlledTextFieldProps
>(
  (
    {
      label,
      error,
      value,
      placeholder,
      type = "text",
      onChange,
      onBlur,
      icon = null,
      ...props
    },
    ref
  ): JSX.Element => {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <FormInputWrapper label={label} error={error}>
        <input
          {...props}
          ref={ref}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChange(event.target.value)}
          onBlur={onBlur}
        />
        {icon}
      </FormInputWrapper>
    );
  }
);

export const FormTextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  props:
    | UncontrolledTextFieldProps<TFieldValues, TName>
    | ControlledTextFieldProps
): JSX.Element => {
  if ("value" in props) {
    return <ControlledTextField {...props} />;
  } else {
    return <UncontrolledTextField {...props} />;
  }
};

import type { TripDetailsV2 } from "@b2bportal/air-shopping-api";
import { CallState } from "@b2bportal/core-types";
import {
  FindProductToExerciseResponseEnum,
  GetPriceFreezeVoucherResponseEnum,
  MatchingProductEnum,
  type GetPriceFreezeVoucherResponseFailure,
  type GetPriceFreezeVoucherResponseSuccess,
  type MatchingProductFlight,
  type PriceFreezeVoucher,
} from "@b2bportal/price-freeze-api";
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchPriceFreezeVoucher,
  fetchTripDetails,
  findVoucherLatestTrip,
} from "./thunks";

export interface IPriceFreezeDetailsState {
  fetchTripDetailsCallState: CallState;
  fetchPriceFreezeVoucherCallState: CallState;
  matchedLastestTripCallState: CallState;
  failureReason?: string;
  tripDetails: TripDetailsV2 | null;
  voucher: PriceFreezeVoucher | null;
  matchedLatestTrip: MatchingProductFlight | null;
}

const initialState: IPriceFreezeDetailsState = {
  fetchTripDetailsCallState: CallState.NotCalled,
  fetchPriceFreezeVoucherCallState: CallState.NotCalled,
  matchedLastestTripCallState: CallState.NotCalled,
  tripDetails: null,
  voucher: null,
  matchedLatestTrip: null,
};

export const priceFreezeDetailsSlice = createSlice({
  name: "priceFreezeDetails",
  initialState,
  reducers: {
    resetState: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTripDetails.pending, (state) => {
        state.fetchTripDetailsCallState = CallState.InProcess;
      })
      .addCase(fetchTripDetails.fulfilled, (state, action) => {
        if (action.payload.id) {
          state.fetchTripDetailsCallState = CallState.Success;
          state.tripDetails = action.payload;
        } else {
          state.fetchTripDetailsCallState = CallState.Failed;
        }
      })
      .addCase(fetchTripDetails.rejected, (state, action) => {
        state.fetchTripDetailsCallState = CallState.Failed;
        state.failureReason = action.error.message;
      })
      .addCase(fetchPriceFreezeVoucher.pending, (state) => {
        state.fetchPriceFreezeVoucherCallState = CallState.InProcess;
      })
      .addCase(fetchPriceFreezeVoucher.fulfilled, (state, action) => {
        if (
          action.payload.GetPriceFreezeVoucherResponse ===
          GetPriceFreezeVoucherResponseEnum.Success
        ) {
          state.fetchPriceFreezeVoucherCallState = CallState.Success;
          state.voucher = (
            action.payload as GetPriceFreezeVoucherResponseSuccess
          ).voucher;
        } else {
          state.failureReason = (
            action.payload as GetPriceFreezeVoucherResponseFailure
          ).reason;
          state.fetchPriceFreezeVoucherCallState = CallState.Failed;
        }
      })
      .addCase(fetchPriceFreezeVoucher.rejected, (state, action) => {
        state.fetchPriceFreezeVoucherCallState = CallState.Failed;
        state.failureReason = action.error.message;
      })
      .addCase(findVoucherLatestTrip.pending, (state) => {
        state.matchedLastestTripCallState = CallState.InProcess;
      })
      .addCase(findVoucherLatestTrip.fulfilled, (state, action) => {
        if (
          action.payload.FindProductToExerciseResponse ===
          FindProductToExerciseResponseEnum.Available
        ) {
          if (
            action.payload.matchingProduct.MatchingProduct ===
            MatchingProductEnum.Flight
          ) {
            state.matchedLatestTrip = action.payload.matchingProduct;
            state.matchedLastestTripCallState = CallState.Success;
          } else {
            state.matchedLastestTripCallState = CallState.Failed;
          }
        } else {
          state.matchedLastestTripCallState = CallState.Failed;
          state.failureReason = action.payload.FindProductToExerciseResponse;
        }
      })
      .addCase(findVoucherLatestTrip.rejected, (state, action) => {
        state.matchedLastestTripCallState = CallState.Failed;
        state.failureReason = action.error.message;
      });
  },
});

export const PriceFreezeDetailsActions = priceFreezeDetailsSlice.actions;

export const { reducer: priceFreezeDetailsReducer } = priceFreezeDetailsSlice;

import clsx from "clsx";
import styles from "./App.module.scss";
import "./App.scss";

const App = ({
  children,
  mainClassName,
}: React.PropsWithChildren & { mainClassName: string }) => {
  return (
    <div className={clsx(styles["core-app"], mainClassName)}>{children}</div>
  );
};

export default App;

import { useEffect, useMemo, useRef } from "react";
import dayjs, { type Dayjs } from "dayjs";
import { useEventTracking } from "./TrackingProvider";

export const useSetTrackingAttributes = () => {
  const { setTrackingAttribute, setTrackingAttributes } = useEventTracking();

  return useMemo(
    () => ({
      setTrackingAttribute,
      setTrackingAttributes,
    }),
    [setTrackingAttribute, setTrackingAttributes]
  );
};

export const useTrackEvent = () => {
  const { useTrackEvent } = useEventTracking();
  const trackEvent = useTrackEvent();

  return useMemo(() => trackEvent, [trackEvent]);
};

export const useTrackOnLoad = (event, condition = true) => {
  const { useTrackOnce } = useEventTracking();

  useTrackOnce(condition, event);
};

export const useTrackEventRuntime = (event, condition: boolean) => {
  const trackEvent = useTrackEvent();

  const startLoadingTime = useRef<Dayjs>();
  useEffect(() => {
    if (condition && !startLoadingTime.current) {
      startLoadingTime.current = dayjs();
    }
    if (startLoadingTime.current && !condition) {
      const runtime = dayjs().diff(startLoadingTime.current, "seconds", true);

      trackEvent({
        ...event,
        properties: {
          ...event.properties,
          runtime,
        },
      });

      startLoadingTime.current = undefined;
    }
  }, [condition, event, trackEvent]);
};

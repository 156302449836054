import {
  FieldValues,
  FieldPath,
  Controller,
  ControllerProps,
} from "react-hook-form";

import {
  ControlledFormControlWrapper,
  ControlledFormControlWrapperProps,
} from "@apac/form-ui";

export type UncontrolledFormControlWrapperProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = ControlledFormControlWrapperProps & ControllerProps<TFieldValues, TName>;

export const UncontrolledFormControlWrapper = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  className,
  render: Render,
  ...props
}: UncontrolledFormControlWrapperProps<TFieldValues, TName>): JSX.Element => {
  return (
    <Controller
      {...props}
      render={(context) => {
        return (
          <ControlledFormControlWrapper
            className={className}
            error={context.fieldState.error?.message}
            {...props}
          >
            <Render {...context} />
          </ControlledFormControlWrapper>
        );
      }}
    />
  );
};

import { createSelector } from "@reduxjs/toolkit";
import { AirPriceFreezeMachineStateWithoutValue } from "./types";
import { Product } from "@b2bportal/purchase-api";
import {
  FrozenProductFlight,
  PriceFreezeFinalizeResultV3 as PriceFreezeFinalizeResult,
  PriceFreezeQuoteDataV3,
} from "@b2bportal/price-freeze-api";

export const getContactInformation = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  return state.context.contactInformation;
};

export const getPriceFreezeOffers = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  return state.context.priceFreezeOffer.flightsPriceFreezeOffers;
};

export const getPriceFreezeOfferId = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  return state.context.priceFreezeOffer.selectedOfferId;
};

export const getSelectedPriceFreezeOffer = createSelector(
  getPriceFreezeOffers,
  getPriceFreezeOfferId,
  (offers, selectedOfferId) => {
    return offers.find((o) => o.offerId === selectedOfferId);
  }
);

export const getPriceFreezeTotalPriceBeforeQuote = createSelector(
  getSelectedPriceFreezeOffer,
  (offer) => {
    return offer?.serviceDetails.totalServiceFee;
  }
);

export const getFinalizedItinerary = (
  state: AirPriceFreezeMachineStateWithoutValue
) => {
  const products = state.context.cartFulfill.fulfilledProducts;
  const priceFreezeResult = products?.find(
    (p) => p.type === Product.PriceFreeze || p.type === Product.AirPriceFreeze
  )?.value as PriceFreezeFinalizeResult;
  return priceFreezeResult?.voucher;
};

export const getQuoteFrozenProductFlight = (
  state: AirPriceFreezeMachineStateWithoutValue
): FrozenProductFlight | undefined => {
  const breakdown = state.context.cartQuote.quoteBreakdown;
  if (!breakdown?.products) {
    return undefined;
  }

  const quoteData = breakdown.products.find(
    (p) =>
      p.product.type === Product.PriceFreeze ||
      p.product.type === Product.AirPriceFreeze
  )?.product.value as PriceFreezeQuoteDataV3;

  return (quoteData.offer.frozenProduct as FrozenProductFlight) ?? undefined;
};

export const getFinalizedPassengerNumber = createSelector(
  getFinalizedItinerary,
  (product) => {
    const { passengerCount } = product.frozenProduct as FrozenProductFlight;
    // all passenger counts shouldn't be undefined/null, will be zero if not purchased
    return (
      passengerCount.numOfAdults +
      passengerCount.numOfChildren +
      passengerCount.numOfInfants
    );
  }
);

import type { TripSlice } from "@b2bportal/air-shopping-api";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import {
  CoreFlightsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { FlightRecheckBagsDialog } from "@components/flights";
import {
  Button,
  ButtonTypeEnum,
  InfoBanner,
  InfoBannerSeverity,
} from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import defaultStyles from "./FlightCardRecheckBaggageBanner.module.scss";

export interface FlightCardRecheckBaggageBannerProps
  extends PlatformComponentProps {
  flightSlice?: TripSlice;
}

export const FlightCardRecheckBaggageBanner = ({
  flightSlice,
  className,
}: FlightCardRecheckBaggageBannerProps) => {
  const { t } = useI18nContext();

  const styles = useFlightStyles(
    CoreFlightsComponent.FlightCardRecheckBaggageBanner,
    defaultStyles
  );

  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightCardRecheckBaggageBanner
  );

  return (
    <InfoBanner
      className={clsx(block, className)}
      severity={InfoBannerSeverity.WARNING}
    >
      {t("core-flights.vi.recheckBags.banner.copy")}{" "}
      <FlightRecheckBagsDialog flightSlice={flightSlice}>
        <Button buttonType={ButtonTypeEnum.Link} className={cn("learn-more")}>
          {t("core-flights.vi.recheckBags.banner.learnMore")}
        </Button>
      </FlightRecheckBagsDialog>
    </InfoBanner>
  );
};

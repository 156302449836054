import { ReactNode } from "react";
import {
  FormControlWrapper as CoreFormControlWrapper,
  FormControlWrapperProps as CoreFormControlWrapperProps,
  FormInputContainer,
} from "@apac/form-ui";
import { FieldValues, FieldPath } from "react-hook-form";

export type FormInputWrapperProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  children?: ReactNode;
} & CoreFormControlWrapperProps<TFieldValues, TName>;

export const FormInputWrapper = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  children,
  error,
  ...props
}: FormInputWrapperProps<TFieldValues, TName>): JSX.Element => {
  return "render" in props ? (
    <CoreFormControlWrapper
      {...props}
      render={(context) => (
        <FormInputContainer error={!!error} {...props}>
          {props.render(context)}
        </FormInputContainer>
      )}
    />
  ) : (
    <CoreFormControlWrapper {...props}>
      <FormInputContainer disabled={props.disabled} error={!!error}>
        {children}
      </FormInputContainer>
    </CoreFormControlWrapper>
  );
};

import { hotelsApi } from "@overrides/utilities";
import {
  CategorizedResponse,
  LodgingSelectionEnum,
  Suggestion,
  PlaceIdEnum,
} from "@b2bportal/lodging-api";
export const fetchLodgingLocationAutocomplete = async (
  query: string
): Promise<CategorizedResponse[]> => {
  const res = await hotelsApi.apiV0AutocompleteLodgingPost({
    query: { l: query, LocationQuery: "Label" },
  });
  return res.data.categories;
};

export const fetchPlace = async (
  query: string
): Promise<Suggestion | undefined> => {
  const locations = await fetchLodgingLocationAutocomplete(query);

  // Try to find a geo location
  const geoLocation = locations[0].results.find(
    (location: Suggestion) =>
      location.id.Id === PlaceIdEnum.Lodgings &&
      location.id.lodgingSelection.LodgingSelection ===
        LodgingSelectionEnum.Place &&
      location.id.lodgingSelection.placeTypes.includes("geocode")
  );

  // Return geo location or first location
  return geoLocation ?? locations?.[0].results.shift();
};

import { ScheduleChangeDisplayMode } from "@b2bportal/core-types";
import { Trans } from "@hopper-b2b/i18n";

export interface IFlightAirportDetailsProps {
  airportCode: string;
  airportName: string;
  classNames?: Partial<{
    originalTime: string;
    segmentTime: string;
    updatedTime: string;
  }>;
  currentTime: string;
  displayMode?: ScheduleChangeDisplayMode;
  hasScheduleChange: boolean;
  prevTime?: string | null;
}

/**
 * Renders details for one of the termini of a flight segment
 * @param props
 * @returns
 */
export const FlightAirportDetails = (props: IFlightAirportDetailsProps) => {
  const {
    airportCode,
    airportName,
    classNames,
    currentTime,
    displayMode,
    hasScheduleChange,
    prevTime,
  } = props;

  if (hasScheduleChange && displayMode === ScheduleChangeDisplayMode.both) {
    return (
      <Trans
        i18nKey={"inlineChangeFlightAirportDetails"}
        values={{
          airportCode,
          airportName,
          prevTime,
          newTime: currentTime,
        }}
        components={{
          original: <span className={classNames?.originalTime} />,
          updated: <span className={classNames?.updatedTime} />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey={"flightAirportDetails"}
      values={{
        airportCode,
        airportName,
        time: currentTime,
      }}
      components={{
        segmentTime: <span className={classNames?.segmentTime} />,
      }}
    />
  );
};

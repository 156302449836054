import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import {
  type FlightKey,
  type MessageMethod,
  ResponseEnum,
  type ShopFilter,
} from "@b2bportal/air-price-watch-api";
import type { PredictionCopy } from "@b2bportal/air-shopping-api";
import { createWatch, trackEvent } from "@hopper-b2b/api";
import { Trans, useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap, SwitchButton } from "@hopper-b2b/ui";
import dayjs from "dayjs";
import { ReactComponent as Checkmark } from "../../../assets/client/checkmark-simple.svg";
import { ReactComponent as CloseIcon } from "../../../assets/client/close.svg";
import EyeWithBackground from "../../../assets/client/eye-with-background.svg";
import { ReactComponent as Eye } from "../../../assets/client/eye.svg";
import confirmationIcon from "../../../assets/client/green-confirmation-check.svg";
import { openNubankTermsAndConditions } from "../../../utils/nubankHandoff";
import { PATH_TRIPS } from "../../../utils/urlPaths";
import MobileInfoPopover from "../../MobileInfoPopover";
import PriceWatchPopoverContent from "../../PriceWatchPopoverContent";
import styles from "./styles.module.scss";
import Switch from "../../Switch/component";

enum events {
  VIEWED_PRICE_WATCH_ENTRY = "viewed_price_watch_entry",
  CLOSED_PRICE_WATCH_INFO = "closed_price_watch_info",
  ADD_PRICE_WATCH = "add_price_watch",
  REMOVE_PRICE_WATCH = "remove_price_watch",
}

interface PriceWatchEntryCardProps {
  predictionCopy?: PredictionCopy;
  deleteWatch: () => void;
  watching: boolean;
  onWatchingChange: (isWatching: boolean) => void;
  priceWatchAlertKey?: FlightKey;
  paxCount?: { [key: string]: number };
}

const PriceWatchEntryCard = ({
  watching,
  priceWatchAlertKey,
  paxCount,
  predictionCopy,
  deleteWatch,
  onWatchingChange,
}: PriceWatchEntryCardProps) => {
  const [priceWatchHalfSheetOpen, setPriceWatchHalfSheetOpen] = useState(false);
  const [priceWatchSuccessful, setPriceWatchSuccessful] = useState(false);
  const [priceWatchWatchingHalfSheetOpen, setPriceWatchWatchingHalfSheetOpen] =
    useState(false);
  const { t } = useI18nContext();

  const baseTrackingProperties = useMemo(() => {
    return {
      origin: priceWatchAlertKey.value.origin.code,
      destination: priceWatchAlertKey.value.destination.code,
      days_until_departure: `${dayjs(
        priceWatchAlertKey.value.departureDate
      ).diff(dayjs(), "days")} days`,
      dealness: predictionCopy.dealness,
    };
  }, [
    predictionCopy.dealness,
    priceWatchAlertKey.value.departureDate,
    priceWatchAlertKey.value.destination.code,
    priceWatchAlertKey.value.origin.code,
  ]);

  const openHalfSheetHandler = useCallback(() => {
    if (watching) {
      deleteWatch();
      onWatchingChange(false);
      trackEvent({
        eventName: events.REMOVE_PRICE_WATCH,
        properties: { ...baseTrackingProperties },
      });
    } else {
      setPriceWatchHalfSheetOpen(true);
      trackEvent({
        eventName: events.VIEWED_PRICE_WATCH_ENTRY,
        properties: { ...baseTrackingProperties },
      });
    }
  }, [baseTrackingProperties, deleteWatch, onWatchingChange, watching]);

  const closeHalfSheetHandler = useCallback(() => {
    setPriceWatchHalfSheetOpen(false);
    trackEvent({
      eventName: events.CLOSED_PRICE_WATCH_INFO,
      properties: { ...baseTrackingProperties },
    });
  }, [baseTrackingProperties]);

  const openWatchingHalfSheetHandler = useCallback(() => {
    setPriceWatchWatchingHalfSheetOpen(true);
  }, []);

  const closeWatchingHalfSheetHandler = useCallback(() => {
    setPriceWatchWatchingHalfSheetOpen(false);
  }, []);

  const trackPriceWatchHandler = useCallback(
    (method: { method: MessageMethod }, filter: ShopFilter) => {
      const payload = {
        key: {
          ...priceWatchAlertKey,
          ...{ value: { ...priceWatchAlertKey.value, filter: filter } },
        },
        ...method,
        passengers: paxCount,
      };

      setPriceWatchHalfSheetOpen(false);

      // TODO: Remove once content with price watch
      console.log("payload:", payload);

      createWatch(payload)
        .then((res: { value: any; Response: ResponseEnum }) => {
          setPriceWatchSuccessful(res.Response === ResponseEnum.Success);
          openWatchingHalfSheetHandler();
          onWatchingChange(true);
          trackEvent({
            eventName: events.ADD_PRICE_WATCH,
            properties: {
              ...baseTrackingProperties,
              successful: res.Response === ResponseEnum.Success,
              method: method.method.MessageMethod,
              filter: filter,
            },
          });
        })
        .catch((e) => {
          setPriceWatchSuccessful(false);
          openWatchingHalfSheetHandler();
          trackEvent({
            eventName: events.ADD_PRICE_WATCH,
            properties: {
              ...baseTrackingProperties,
              successful: false,
              method: method.method.MessageMethod,
              filter: filter,
            },
          });
        });
    },
    [
      baseTrackingProperties,
      onWatchingChange,
      openWatchingHalfSheetHandler,
      paxCount,
      priceWatchAlertKey,
    ]
  );

  return (
    <>
      <div className={styles.PriceWatchEntryCard}>
        <p>{t("priceWatch.active")}</p>
        <Switch onClick={openHalfSheetHandler} checked={watching} />
      </div>
      <MobileInfoPopover
        isOpen={priceWatchHalfSheetOpen}
        onClose={closeHalfSheetHandler}
        title={t("priceWatch.titles.header")}
        subtitle={t("priceWatch.titles.subtitle")}
        primaryButtonAction={null}
        primaryButtonText={""}
        icon={EyeWithBackground}
        closeIcon={<CloseIcon className={styles.Close} />}
        className={styles.PriceWatchMobilePopover}
      >
        <PriceWatchPopoverContent onSubmit={trackPriceWatchHandler} />
      </MobileInfoPopover>
      <PriceWatchWatching
        isOpen={priceWatchWatchingHalfSheetOpen}
        onClose={closeWatchingHalfSheetHandler}
        isSuccessful={priceWatchSuccessful}
      />
    </>
  );
};

const PriceWatchWatching = ({
  isOpen,
  onClose,
  isSuccessful,
}: {
  isOpen: boolean;
  onClose: () => void;
  isSuccessful: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useI18nContext();

  const myTripsHandler = useCallback(() => {
    navigate(PATH_TRIPS);
    onClose();
  }, [navigate, onClose]);

  const openTermsHandler = useCallback(() => {
    openNubankTermsAndConditions();
  }, []);

  const content = useMemo(() => {
    return isSuccessful
      ? {
          title: t("priceWatch.watchedHeader"),
          subtitle: (
            <Trans
              i18nKey={"priceWatch.watchedSubtitle"}
              components={[
                <ButtonWrap
                  className="my-trips-link"
                  onClick={myTripsHandler}
                />,
              ]}
            />
          ),
          primaryButtonAction: onClose,
          primaryButtonText: t("priceWatch.continueSearch"),
          children: (
            <p className="instructions">
              <Trans
                i18nKey={"priceWatch.termsAndConditions"}
                components={[
                  <ButtonWrap
                    className="terms-link"
                    onClick={openTermsHandler}
                  />,
                ]}
              />
            </p>
          ),
          icon: confirmationIcon,
        }
      : {
          title: t("priceWatch.failure.title"),
          subtitle: t("priceWatch.failure.createWatchSubtitle"),
          primaryButtonAction: onClose,
          primaryButtonText: t("tryAgain"),
          secondaryButtonAction: onClose,
          secondaryButtonText: t("cancel"),
        };
  }, [isSuccessful, myTripsHandler, onClose, openTermsHandler, t]);

  return (
    <MobileInfoPopover
      isOpen={isOpen}
      onClose={onClose}
      {...content}
      closeIcon={<CloseIcon className={styles.Close} />}
      className={styles.PriceWatchWatching}
    >
      {content.children}
    </MobileInfoPopover>
  );
};
export default PriceWatchEntryCard;

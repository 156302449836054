import { LoadingScreen, NoTripResults, useTripsList } from "@components/trips";
import { trackEvent } from "@hopper-b2b/api";
import React from "react";
import { BaseProductSet, events } from "../../../../types";
import { TripsListProps } from "./types";
import { CoreTripsComponent } from "@b2bportal/core-types";
import { useModuleBEM, useTripsStyles } from "@b2bportal/core-themes";
import defaultStyles from "./TripsList.module.scss";

const COMPONENT_KEY = CoreTripsComponent.TripsList;

export const TripsList = <Products extends BaseProductSet>(
  props: TripsListProps<Products>
) => {
  const { context } = useTripsList(props);

  React.useEffect(() => {
    trackEvent({
      eventName: events.VIEWED_MY_TRIPS,
      properties: {},
    });
  }, []);

  const styles = useTripsStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  return (
    <div className={block}>
      <LoadingScreen isLoading={context.isLoading} />
      {context.trips.length > 0 ? (
        <ul className={cn("trips-list")}>
          {context.trips.map((wrapper) => (
            <li className={cn("trip")} key={wrapper.reservationId}>
              <wrapper.component />
            </li>
          ))}
        </ul>
      ) : (
        <NoTripResults hasError={context.hasError} />
      )}
    </div>
  );
};

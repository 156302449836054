import { combineReducers } from "@reduxjs/toolkit";
import {
  priceFreezeDetailsReducer,
  type IPriceFreezeDetailsState,
} from "../features/details";
import {
  listFlightsPriceFreezeReducer,
  type IListFlightsPriceFreezeState,
} from "../features/list";
import {
  flightsPriceFreezePurchaseReducer,
  type IFlightsPriceFreezeShopState,
} from "../features/shop";

export interface FlightsPriceFreezeState {
  flightsPriceFreeze: {
    purchase: IFlightsPriceFreezeShopState;
    list: IListFlightsPriceFreezeState;
    details: IPriceFreezeDetailsState;
  };
}

export const flightsPriceFreezeRootReducer = combineReducers({
  purchase: flightsPriceFreezePurchaseReducer,
  list: listFlightsPriceFreezeReducer,
  details: priceFreezeDetailsReducer,
});

import { ScheduleChangeSeverity } from "@b2bportal/air-booking-api";
import { useI18nContext } from "@hopper-b2b/i18n";

export const useFlightScheduleChangeBadge = (
  severity: ScheduleChangeSeverity
) => {
  const { t } = useI18nContext();
  const badgeVariantMap: Record<ScheduleChangeSeverity, string> = {
    [ScheduleChangeSeverity.Major]: "major",
    [ScheduleChangeSeverity.Minor]: "minor",
    [ScheduleChangeSeverity.Unknown]: "unknown",
  };
  const badgeVariant = badgeVariantMap[severity] ?? "";

  return {
    context: {
      badgeVariant,
    },
    text: {
      badgeLabel: t("core-flights.postbooking.scheduleChange.badge", {
        context: badgeVariant,
      }),
    },
  };
};

export type IFlightScheduleChangeBadgeData = ReturnType<
  typeof useFlightScheduleChangeBadge
>;

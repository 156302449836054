import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { createWatch, deleteWatch, listWatches } from "@hopper-b2b/api";
import {
  CreateWatchAlertRequest,
  DeleteWatchAlertRequest,
  WatchAlertViews,
} from "@b2bportal/air-price-watch-api";

export const priceWatchApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
  }),
  tagTypes: ["Watches"],
  endpoints: (build) => ({
    getWatches: build.query<WatchAlertViews, void>({
      queryFn: async () => {
        const watches = await listWatches();

        return {
          data: watches,
        };
      },
      providesTags: ["Watches"],
    }),
    createWatch: build.mutation<unknown, CreateWatchAlertRequest>({
      queryFn: async (request) => {
        const watch = await createWatch(request);

        return {
          data: watch,
        };
      },
      invalidatesTags: ["Watches"],
    }),
    deleteWatch: build.mutation<unknown, DeleteWatchAlertRequest>({
      queryFn: async (request) => {
        const response = await deleteWatch(request);

        return {
          data: response,
        };
      },
      invalidatesTags: ["Watches"],
    }),
  }),
});

export const {
  useGetWatchesQuery,
  useCreateWatchMutation,
  useDeleteWatchMutation,
} = priceWatchApi;

export default priceWatchApi;

import { CallState } from "@b2bportal/core-types";
import { PriceFreezeVoucher, TripContext } from "@b2bportal/price-freeze-api";
import { createSlice } from "@reduxjs/toolkit";
import { fetchPriceFreezeVouchers } from "./thunks";

export interface IListFlightsPriceFreezeState {
  listFlightsPriceFreezeCallState: CallState;
  priceFreezeVouchers: PriceFreezeVoucher[];
  tripContext?: TripContext;
  failureReason?: string;
  isLoading: boolean;
  selectedOfferId?: string;
}

const initialState: IListFlightsPriceFreezeState = {
  listFlightsPriceFreezeCallState: CallState.NotCalled,
  priceFreezeVouchers: [],
  isLoading: false,
};

export const listFlightsPriceFreezeSlice = createSlice({
  name: "listFlightsPriceFreeze",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchFrozenPrices thunk
    builder
      .addCase(fetchPriceFreezeVouchers.pending, (state) => {
        state.listFlightsPriceFreezeCallState = CallState.InProcess;
        state.isLoading = true;
      })
      .addCase(fetchPriceFreezeVouchers.fulfilled, (state, action) => {
        if (action.payload.vouchers) {
          state.listFlightsPriceFreezeCallState = CallState.Success;
          state.priceFreezeVouchers = action.payload.vouchers;
        } else {
          state.listFlightsPriceFreezeCallState = CallState.Failed;
        }
        state.isLoading = false;
      })
      .addCase(fetchPriceFreezeVouchers.rejected, (state, action) => {
        state.listFlightsPriceFreezeCallState = CallState.Failed;
        state.isLoading = false;
        state.failureReason = action.error.message;
      });
  },
});

export const ListFlightPriceFreezeActions = listFlightsPriceFreezeSlice.actions;

export const { reducer: listFlightsPriceFreezeReducer } =
  listFlightsPriceFreezeSlice;

import type { AirExchangeEligibilityQuoteSuccess } from "@b2bportal/air-exchange-api";

const policy: AirExchangeEligibilityQuoteSuccess = {
  voidScenario: {
    VoidScenario: "NotVoidable",
  },
  exchangeScenario: {
    ExchangeScenario: "Exchangeable",
    changeFee: {
      amount: 25,
      currency: "USD",
    },
    exchangeBannerCopy: {
      title: "Change Flight",
      body: ["Request a change to your flight itinerary"],
      importantInfo: [],
    },
    agentLocator: {
      provider: "sabre",
      unscopedValue: "ZRTURK",
      value: "ZRTURK",
    },
    exchangeCopy: {
      title: "Request a change to your Round-Trip flight",
      body: [
        "You can make changes to your upcoming trip with Delta without charge except for any fare difference.",
        "Once submitted, we will review your request and reach out within 2-4 hours based on flight availability.",
      ],
      importantInfo: [
        "Change requests will be reviewed within 2-4 hours and an email confirmation will be sent upon approval. Our team will contact you by phone in the event that your requested flight is unavailable.",
        "Fees and any fare difference will be charged to the same payment method as the original booking. Please give us a call at 844-422-6972 if you would like to change your payment method.",
        "Any paid add-ons from your original booking, including baggage, seats and the option to cancel for any reason, are not carried over to your new booking or reimbursed.",
      ],
    },
    confirmationBannerCopy: {
      title: "Submit Request",
      body: ["Review and submit your request"],
      importantInfo: [],
    },
    exchangeConfirmationCopy: {
      title: "Review your request to change your trip.",
      body: [
        "Once you submit a request to change your trip it will take about 2 to 4 hours for your request to process.",
        "If your request is approved, you will receive an email confirmation. If your chosen flights are unavailable, our team will call your phone number on file.",
      ],
      informativeSection: {
        title: "Change Requested",
        body: [
          "We are reviewing your request and will confirm completion within 2-4 hours. If your request is approved you’ll receive an email confirmation. Our team will contact you by phone in the event that your chosen flights are unavailable.",
        ],
      },
      importantInfo: [
        "Change requests will be reviewed within 2-4 hours and an email confirmation will be sent upon approval. Our team will contact you by phone in the event that your requested flight is unavailable.",
        "Fees and any fare difference will be charged to the same payment method as the original booking. Please give us a call at 844-422-6972 if you would like to change your payment method.",
        "Any paid add-ons from your original booking, including baggage, seats and the option to cancel for any reason, are not carried over to your new booking or reimbursed.",
        "As pricing fluctuations may occur, by submitting this request you authorize the purchase of replacement flights within $25 of your selection per passenger.",
      ],
    },
  },
  AirExchangeEligibilityQuoteResponse: "AirExchangeEligibilityQuoteSuccess",
};

export default policy;

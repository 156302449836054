import {
  FlightItinerarySegment,
  FlightItinerarySlice,
  ScheduleChangeSeverity,
} from "@b2bportal/air-booking-api";
import { SliceDirection } from "./book";

export enum ScheduleChangeDisplayMode {
  /**
   * Renders an inline-diff of the original and updated slices
   */
  both = "both",
  /**
   * Renders the original slice with strikethroughed flight times
   */
  original = "original",
  /**
   * Renders the updated slice with bolded flight times
   */
  updated = "updated",
}

export enum ScheduleChangeModalState {
  details = "details",
  processing = "processing",
  success = "success",
  failure = "failure",
}

export enum ScheduleChangeUserActionEnum {
  Accept = "Accept",
  Deny = "Deny",
}

export enum TimeGroupingEnum {
  EARLY_MORNING = "EARLY_MORNING",
  MORNING = "MORNING",
  AFTERNOON = "AFTERNOON",
  EVENING = "EVENING",
}

export interface IChangeFlightEligibilityClasses {
  contentActions: string;
  contentBody: string;
  contentHeader: string;
  contentTitle: string;
  modalContent: string;
}

export interface ITimeGrouping {
  grouping: TimeGroupingEnum;
  label: string;
  sublabel: string;
}

export interface ScheduleChangeDiffModel {
  changes: Record<SliceKey, SliceChangeContext>;
  expiry: string;
  severity: ScheduleChangeSeverity;
}

export interface SliceChangeContext {
  /**
   * The new details from the schedule change object
   */
  changes: Record<SegmentKey, FlightItinerarySegment>;
  direction: SliceDirection;
  severity: ScheduleChangeSeverity;
  updatedSlice: FlightItinerarySlice;
}

/**
 * An FE-only key to quickly identify a slice within an itinerary
 * @example `${firstSegmentKey}::${lastSegmentKey}`
 */
export type SliceKey = `${SegmentKey}::${SegmentKey}`;

/**
 * An FE-only key to quickly identify a segment within its slice
 * @example `${origin}-${destination}`
 */
export type SegmentKey = `${string}-${string}`;

import { MapCoordinates } from "@b2bportal/core-types";
import { Coordinates } from "@b2bportal/lodging-api";

export const toHotelCoordinatesType = (
  coordinates: MapCoordinates
): Coordinates => {
  return {
    lat: coordinates.lat,
    lon: coordinates.lng,
  };
};

export const toMapCoordinatesType = (
  coordinates: Coordinates
): MapCoordinates => {
  return {
    lat: coordinates.lat,
    lng: coordinates.lon,
  };
};

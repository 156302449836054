import {
  IconNameEnum,
  useFlightStyles,
  useModuleBEM,
} from "@b2bportal/core-themes";
import {
  type AlgomerchTag,
  CoreFlightsComponent,
  type PlatformComponentProps,
} from "@b2bportal/core-types";
import { AlgomerchTags, FlightTime } from "@components/flights";
import { AirlineIcon, Icon } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import defaultStyles from "./FlightCardSummary.module.scss";

export interface FlightCardSummaryComponentProps
  extends PlatformComponentProps {
  duration: string;
  currentPriceText: string;
  originCode: string;
  destinationCode: string;
  departureTime: string;
  arrivalTime: string;
  brandName: string;
  primaryCarrier: string;
  airlineName: string;
  additionalAirlines?: number;
  onClickTag?: (label: string) => void;
  tags: AlgomerchTag[];
  tagsLimit?: number;
  rewardText?: string;
  flightStops: number;
}

export const FlightCardSummary = ({
  duration,
  currentPriceText,
  originCode,
  destinationCode,
  departureTime,
  arrivalTime,
  brandName,
  primaryCarrier,
  airlineName,
  additionalAirlines,
  tags,
  tagsLimit,
  flightStops,
  className,
  children,
}: FlightCardSummaryComponentProps) => {
  const { t } = useI18nContext();

  const styles = useFlightStyles(
    CoreFlightsComponent.FlightCardSummary,
    defaultStyles
  );
  const [block, cn] = useModuleBEM(
    styles,
    CoreFlightsComponent.FlightCardSummary
  );

  return (
    <div className={clsx(block, className)}>
      <div className={cn("icon")}>
        <AirlineIcon
          airlineCode={primaryCarrier}
          altText={airlineName}
          className={styles["airline__icon"]}
        />
      </div>
      {tags.length > 0 && (
        <div className={cn("flight-tags")}>
          <AlgomerchTags tags={tags} tagsLimit={tagsLimit} />
        </div>
      )}
      <FlightTime
        departureTime={departureTime}
        arrivalTime={arrivalTime}
        stops={flightStops}
      />
      <div className={cn("airport", { origin: true })}>{originCode}</div>
      <div className={cn("airport", { destination: true })}>
        {destinationCode}
      </div>
      <div className={cn("amount")}>{currentPriceText}</div>
      <div className={cn("divider")} />
      <div className={cn("details")}>
        <div className={cn("icon")}>
          <AirlineIcon
            airlineCode={primaryCarrier}
            altText={airlineName}
            className={styles["airline__icon"]}
          />
        </div>
        <div className={cn("flight-details")}>
          <div className={cn("duration")}>{duration}</div>
          <div className={cn("dot")}>{"•"}</div>
          <div className={cn("stops")}>
            {flightStops === 0
              ? t("stopDetails.nonstop")
              : t("stopDetails.stop", { count: flightStops })}
          </div>
        </div>
        <div className={cn("airline-details")}>
          <div className={cn("airline")}>
            {airlineName}
            {(additionalAirlines ?? 0) > 0 ? (
              <span className={cn("additional-airlines")}>
                {" "}
                {t("core-flights.vi.countOther", { count: additionalAirlines })}
              </span>
            ) : null}
          </div>
          <div className={cn("dot")}>{"•"}</div>
          <div className={cn("brand-name")}>{brandName}</div>
        </div>
        <div className={cn("arrow")}>
          <Icon iconName={IconNameEnum.dropdownArrow} />
        </div>
      </div>
      {children}
    </div>
  );
};

import {
  Flights,
  Prediction,
  ShopSummaryResponseV4,
  TripDetailsV2,
} from "@b2bportal/air-shopping-api";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchFlights, fetchTripDetails } from "./thunks";
import {
  ISetChoseReturningSliceParams,
  ISetChosenOutgoingSliceParams,
  ITripDetailsByTripId,
} from "./types";
import {
  CallState,
  FlightShopStep,
  ISelectedTrip,
  SortOptionEnum,
} from "@b2bportal/core-types";

export interface FlightsShopState {
  flights?: Flights;
  flightsLoading?: boolean;
  flightsCallState: CallState;
  flightsError: boolean;
  tripDetailsById: ITripDetailsByTripId;
  selectedTrip: ISelectedTrip;
  sortOption: SortOptionEnum;
  shopStep: FlightShopStep;
  prediction?: Prediction;
}

export const initialShopState: FlightsShopState = {
  flights: undefined,
  flightsCallState: CallState.NotCalled,
  flightsError: false,
  selectedTrip: {
    tripId: "",
    outgoingSliceId: "",
    outgoingFareId: "",
  },
  prediction: undefined,
  tripDetailsById: {},
  sortOption: SortOptionEnum.FareScore,
  shopStep: FlightShopStep.departure,
};

const setFetchFlightsPending = (state: FlightsShopState) => {
  state.flightsCallState = CallState.InProcess;
  state.flightsLoading = true;
};

const setFetchFlightsSuccess = (
  state: FlightsShopState,
  action: PayloadAction<ShopSummaryResponseV4>
) => {
  state.flightsCallState = CallState.Success;
  state.flightsError = false;
  state.flights = action.payload.flights;
  state.prediction = action.payload.prediction as Prediction;
  state.flightsLoading = false;
};

const setFetchFlightsRejected = (state: FlightsShopState) => {
  state.flightsCallState = CallState.Failed;
  state.flightsError = true;
  state.flightsLoading = false;
};

const setFetchTripDetails = (
  state: FlightsShopState,
  action: PayloadAction<TripDetailsV2>
) => {
  state.tripDetailsById = {
    ...state.tripDetailsById,
    [action.payload.id]: action.payload,
  };
};

export const flightShopSlice = createSlice({
  name: "flightShop",
  initialState: initialShopState,
  reducers: {
    setChosenOutgoingSlice: (
      state,
      action: PayloadAction<ISetChosenOutgoingSliceParams>
    ) => {
      state.selectedTrip = action.payload;
      state.shopStep = FlightShopStep.return;
    },
    setChosenReturnSlice: (
      state,
      action: PayloadAction<ISetChoseReturningSliceParams>
    ) => {
      state.selectedTrip = { ...state.selectedTrip, ...action.payload };
      state.shopStep = FlightShopStep.review;
    },
    setSortOption: (state, action: PayloadAction<SortOptionEnum>) => {
      state.sortOption = action.payload;
    },
    setFetchFlightsPending,
    setFetchFlightsRejected,
    setFetchFlightsSuccess,
    setFetchTripDetails,
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchFlights
      .addCase(fetchFlights.pending, setFetchFlightsPending)
      .addCase(fetchFlights.fulfilled, setFetchFlightsSuccess)
      .addCase(fetchFlights.rejected, setFetchFlightsRejected)
      // Handle fetchTripDetails
      .addCase(fetchTripDetails.fulfilled, setFetchTripDetails);
  },
});
export const { setChosenOutgoingSlice, setChosenReturnSlice, setSortOption } =
  flightShopSlice.actions;

export const FlightShopActions = flightShopSlice.actions;

export default flightShopSlice.reducer;

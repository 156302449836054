import {
  type CategorizedResponse,
  GroundSelectionEnum,
  type Suggestion,
  PlaceIdEnum,
  AutocompleteCategory,
} from "@b2bportal/ground-api";
import { CarNavigationUtility, carsApi } from "../../../../../utilities";
export const fetchGroundLocationAutocomplete = async (
  query: string
): Promise<CategorizedResponse[]> => {
  const res = await carsApi.apiV1AutocompleteGroundPost({
    query: { l: query, LocationQuery: "Label" },
  });
  return res.data.categories;
};

export const fetchPlace = async (
  query: string
): Promise<Suggestion | undefined> => {
  const locations = await fetchGroundLocationAutocomplete(
    CarNavigationUtility.urlToPlaceQuery(query)
  );

  const airports = getSuggestionsByCategory(
    locations,
    AutocompleteCategory.airports
  );
  const places = getSuggestionsByCategory(
    locations,
    AutocompleteCategory.places
  );

  if (airports != null) {
    const airport = airports.find(
      (location: Suggestion) =>
        CarNavigationUtility.placeLabelToUrl(location.label) === query
    );
    if (airport != null) {
      return airport;
    }
  }

  if (places == null) {
    return undefined;
  }

  // Try to find a geo location
  const geoLocation = places.find(
    (location: Suggestion) =>
      location.id.Id === PlaceIdEnum.Grounds &&
      location.id.groundSelection.GroundSelection ===
        GroundSelectionEnum.Place &&
      location.id.groundSelection.placeTypes.includes("geocode")
  );

  // Return geo location or first location
  return geoLocation ?? locations?.[0].results.shift();
};

function getSuggestionsByCategory(
  categories: CategorizedResponse[],
  category: AutocompleteCategory
) {
  return categories.find((suggestion) => suggestion.category === category)
    ?.results;
}

import { combineReducers } from "@reduxjs/toolkit";
import hotelsAvailabilityReducer from "../features/availability/store/slice";
import hotelsShopReducer from "../features/shop/store/slice";
import { HotelsAvailabilityState } from "../features/availability/store";
import { HotelsShopState } from "../features/shop/store/types";
import { hotelsPostBookingApi } from "../features/postBooking/store/";
import { createHotelUrlMiddleware } from "./urlMiddleWare";

export interface HotelsState {
  hotels: {
    hotelsAvailability: HotelsAvailabilityState;
    hotelsShop: HotelsShopState;
  };
}

export const hotelsRootReducer = combineReducers({
  hotelsAvailability: hotelsAvailabilityReducer,
  hotelsShop: hotelsShopReducer,
});

export const hotelReducers = {
  hotels: hotelsRootReducer,
  [hotelsPostBookingApi.reducerPath]: hotelsPostBookingApi.reducer,
};

export const hotelMiddlewares = [
  hotelsPostBookingApi.middleware,
  createHotelUrlMiddleware(),
];

export { hotelsPostBookingApi };
